import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { CommonGroupCode } from "./CommonGroupCode";
import { CommonCodeSecondary } from "./CommonCodeSecondary";
import { LectureCourse } from "./LectureCourse";
import { LectureCategoryConfig } from "./LectureCategoryConfig";

@Index("idx_common_code_1", ["groupCodeId", "codeName"], { unique: true })
@Index("fk_common_code_group_code_id", ["groupCodeId"], {})
@Entity("common_code", { schema: "dentin" })
export class CommonCode {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "code_id",
    comment: "코드 ID",
  })
  codeId: string;

  @Column("bigint", { name: "group_code_id", comment: "그룹 코드 고유 ID" })
  groupCodeId: string;

  @Column("varchar", { name: "code_name", comment: "코드명", length: 128 })
  codeName: string;

  @Column("tinyint", {
    name: "display_order",
    nullable: true,
    comment: "표시 순서",
  })
  displayOrder: number | null;

  @Column("varchar", {
    name: "description",
    nullable: true,
    comment: "코드 설명",
    length: 256,
  })
  description: string | null;

  @ManyToOne(
    () => CommonGroupCode,
    (commonGroupCode) => commonGroupCode.commonCodes,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "group_code_id", referencedColumnName: "groupCodeId" }])
  groupCode: CommonGroupCode;

  @OneToMany(
    () => CommonCodeSecondary,
    (commonCodeSecondary) => commonCodeSecondary.primaryCode
  )
  commonCodeSecondaries: CommonCodeSecondary[];

  @OneToMany(() => LectureCourse, (lectureCourse) => lectureCourse.commonCode)
  lectureCourses: LectureCourse[];

  @OneToMany(
    () => LectureCategoryConfig,
    (lectureCategoryConfig) => lectureCategoryConfig.primaryCode
  )
  lectureCategoryConfigs: LectureCategoryConfig[];
}
