import { EntityService } from "../../services/entity.service";
import NoticeTable from "../../components/communication/NoticeTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Notice } from "../../entities/entities/Notice";

const NoticePage = () => {
  const entityService = new EntityService();
  const [noticePageData, setNoticePageData] = useState<
    [Notice[], number] | null
  >(null);
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchNotcies(page);
      setNoticePageData(res);
    })();
  }, [page]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/communication/notice?page=${selected}`);
  };

  if (!noticePageData) return <></>;

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-tit mr-5">공지사항</div>
        </div>
        <div>
          <Link to="/communication/noticewrite">
            <button className="blue">등록하기</button>
          </Link>
        </div>
      </div>
      <NoticeTable
        state={noticePageData}
        onPageClick={onPageClick}
        page={page}
      />
    </div>
  );
};

export default NoticePage;
