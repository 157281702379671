export const MAIN_CATEGORY = [
  { label: "기본정보", value: "info" },
  { label: "소통관리", value: "communication" },
  { label: "회원관리", value: "member" },
  { label: "카테고리/게시판관리", value: "board" },
  { label: "통계관리", value: "statistics" },
  { label: "배너관리", value: "banner" },
  { label: "실습치과찾기", value: "intern" },
];

export const SUB_CATEGORY = {
  info: [
    { label: "관리자 계정", value: "admin" },
    { label: "개발자 계정", value: "developer" },
    { label: "이용약관", value: "terms" },
    { label: "개인정보처리방침", value: "policy" },
    { label: "개인정보수집및이용동의", value: "agree" },
    // { label: "사업자정보", value: "business" },
    { label: "환경설정", value: "environment" },
  ],
  communication: [
    { label: "FAQ", value: "faq" },
    { label: "쪽지발송", value: "chat" },
    { label: "공지사항", value: "notice" },
    { label: "온라인교육 공지사항", value: "edu-noti" },
    { label: "법정의무교육 공지사항", value: "duty-noti" },
    { label: "Q&A관리", value: "qna" },
  ],
  board: [
    { label: "카테고리", value: "category" },
    { label: "구인공고", value: "recruit" },
    { label: "인재정보", value: "resume" },
    { label: "법정의무교육", value: "duty-class" },
    { label: "치과인강좌", value: "dentin-class" },
    { label: "구강보건교육", value: "oralhealth-class" },
    { label: "기타교육", value: "etc-class" },
    { label: "자료실", value: "data" },
  ],
  statistics: [
    { label: "일간현황", value: "daily" },
    { label: "방문분석", value: "visit" },
    { label: "회원분석", value: "member" },
    { label: "순위", value: "ranking" },
    { label: "콘텐츠분석", value: "contents" },
  ],
  intern: [
    { label: "실습치과공고", value: "posting" },
    { label: "실습치과지원센터", value: "support" },
  ],
};

export const BANNER_TYPES = [
  { title: "메인 홈 배너(1070 x 88 px)", value: 1 },
  { title: "로그인 배너(642 x 352 px)", value: 2 },
  {
    title: "회원가입/아이디, 비밀번호 찾기 배너(1200 x 180 px)",
    value: 3,
  },
  { title: "공지사항(1200 x 100 px)", value: 4 },
  { title: "공지사항 상세(1200 x 100 px)", value: 5 },
  { title: "FAQ(988 x 130 px)", value: 6 },
  { title: "온라인 교육 메인 배너(988 x 130 px)", value: 7 },
  { title: "강좌목록 배너(988 x 130 px)", value: 8 },
  { title: "교육수강 직원리스트 배너(988 x 130 px)", value: 9 },
  { title: "온라인교육 마이페이지(988 x 130 px)", value: 10 },
  { title: "강의듣기(988 x 130 px)", value: 11 },
  { title: "온라인교육 공지사항(988 x 130 px)", value: 12 },
  { title: "인재정보 메인(1070 x 88 px)", value: 13 },
  { title: "채용정보 메인(1070 x 88 px)", value: 14 },
];
