import dayjs from "dayjs";
import { Fragment } from "react";
import {
  AdminChatMessageWithName,
  EntityService,
} from "../../services/entity.service";

const SendChat = ({
  message,
  reload,
}: {
  message: AdminChatMessageWithName;
  reload: () => void;
}) => {
  const entityService = new EntityService();

  return (
    <div>
      <div className="flex justify-end mb-10">
        <div>
          {message.message != "삭제된 메세지입니다" && (
            <i
              className="ii ii-remove"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.confirm("메세지를 삭제하시겠습니까?") &&
                entityService
                  .deleteSingleAdminChatMessage(message.id)
                  .then((res) => {
                    alert("메세지가 삭제되었습니다");
                    // reload();
                  })
              }
            ></i>
          )}
        </div>
        <div className="flex items-end space-x-3">
          <div className="text-[#6C6C6C] text-xs font-medium">
            <div className="text-[10px]">
              {dayjs(message.regDate).format("YY.MM.DD")}
            </div>
            <div className="text-[10px]">
              {dayjs(message.regDate).format("HH:mm")}
            </div>
          </div>
          <div className="bg-[#C5E3FF] max-w-[400px] p-4 chat-right">
            <div className="text-[#1F1F1F]">
              {message.message?.split("\n").map((row, i) => (
                <Fragment key={i}>
                  {row}
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendChat;
