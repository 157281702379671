import { Column, Entity, PrimaryGeneratedColumn } from "typeorm";

@Entity("faq", { schema: "dentin" })
export class Faq {
  @PrimaryGeneratedColumn({ type: "bigint", name: "faq_id", comment: "FAQ ID" })
  faqId: string;

  @Column("text", { name: "question", comment: "질문 정보" })
  question: string;

  @Column("text", { name: "answer", comment: "답변 정보" })
  answer: string;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;
}
