import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Developer } from "../../entities/entities/Developer";
import { EntityService } from "../../services/entity.service";

const DeveloperRegister = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.item;
    const adminId = sessionStorage.getItem("adminId");
    const [name, setName] = useState<string | undefined>(data?.name ?? "");
    const [id, setId] = useState<string>(data?.devId ?? "");

    const [isId, setIsId] = useState<boolean>(data ? true : false);

    // 아이디 유효성 검사
    const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        const idRegex = /^[0-9a-z_]{4,20}$/; // 한글 포함 안 됨
        const idCurrent = e.target.value.replace(/[^0-9a-z_]/g, ""); // 한글 제거
        setId(idCurrent);
        // 글자 수와 정규식 동시 검사
        if (idCurrent.length < 4 || idCurrent.length > 20) {
            setIsId(false);
        } else {
            setIsId(idRegex.test(idCurrent)); // 유효성 검사
        }
    };

    const submit = async () => {
        if (id === "" || !id) {
            alert("아이디를 입력해주세요.");
        } else if (name === "" || !name) {
            alert("이름을 입력해주세요.");
        } else {
            const entityService = new EntityService();
            // 등록/수정에 따라 developer 객체 구성
            const developer = data
                ? { // 수정일 경우
                    name,
                    dev_id: id,
                    upd_admin_id: adminId, // 수정 시 사용
                    updDate: new Date(),
                }
                : { // 등록일 경우
                    name,
                    dev_id: id,
                    reg_admin_id: adminId, // 등록 시 사용
                };

            if (data) {
                try {
                    await entityService.update(Developer, id, developer);
                    navigate("/info/developer");
                } catch (e) {
                    console.log(e);
                }
            } else {
                try {
                    await entityService.create(Developer, developer);
                    navigate("/info/developer");
                } catch (e) {
                    console.log(e);
                }
            }
        }
    };

    return (
        <div className="inner px-4">
            <div className="page-tit">
                <span className="text-[#999]">개발자 계정 목록 〉</span> 개발자 계정
                등록/수정
            </div>
            <div className="border-[1px] border-[#999] rounded-md p-[1px] mt-6">
                <div className="flex items-center space-x-6 border-b border-[#999]">
                    <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
                        아이디
                    </div>
                    <input
                        type="text"
                        placeholder="4~20자의 영문, 숫자와 특수문자(_)만 사용 가능"
                        className={!data ? "text-input" : ""}
                        value={id} // value로 변경
                        name="devId"
                        onChange={onChangeId} // 입력 시 유효성 검사
                        disabled={!!data} // 수정 모드일 때 비활성화
                    />
                    {!isId && id.length > 0 && (
                        <div className="text-red-500 text-sm mt-1">
                            아이디는 4~20자의 영문, 숫자, 특수문자(_)만 입력 가능합니다.
                        </div>
                    )}
                </div>
                <div className="flex items-center space-x-6 border-b border-[#999]">
                    <div className="bg-[#FBFBFB] w-40 h-16 flex items-center justify-center text-lg font-semibold text-[#333]">
                        이름
                    </div>
                    <input
                        type="text"
                        placeholder="이름을 입력하세요"
                        className="text-input"
                        value={name} // value로 변경
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex items-center justify-center mt-9 space-x-2">
                <Link to="/info/developer">
                    <button>취소</button>
                </Link>
                <button
                    className="white disabled:bg-[#ddd] disabled:text-[#EDEDED] disabled:border-[#ddd]"
                    onClick={submit}
                >
                    {data ? "수정" : "등록"}
                </button>
            </div>
        </div>
    );
};

export default DeveloperRegister;
