import {Column, Entity, PrimaryGeneratedColumn} from "typeorm";

@Entity("serviceKey", { schema: "dentin" })
export class ServiceKey {
    @PrimaryGeneratedColumn({
        type: "bigint",
        name: "api_key_id",
        comment: "인증키 ID",
    })
    apiKeyId: string;

    @Column("varchar", {
        name: "dev_id",
        comment: "개발자 ID",
        length: 32,
    })
    devId: string;

    @Column("varchar", {
        name: "api_key",
        comment: "인증키",
        length: 8192,
    })
    apiKey: string;

    @Column("bit", {
        name: "reissue_flag",
        nullable: true,
        comment: "재발급 여부",
        default: () => "'b'0''",
    })
    reissueFlag: boolean | null;

    @Column("varchar", {
        name: "reg_admin_id",
        comment: "등록한 관리자 ID",
        length: 32,
    })
    regAdminId: string;

    @Column("datetime", {
        name: "reg_date",
        comment: "등록 일시",
        default: () => "CURRENT_TIMESTAMP",
    })
    regDate: Date;

}
