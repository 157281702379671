import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "inticons/fonts/inticons.bundle.min.css";
import { RecoilRoot } from "recoil";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import "dayjs/locale/ko";
import { __getDefaultHeader } from './services/auth/auth.service';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const getHost = () => {
  return process.env.REACT_APP_ENDPOINT;
};

(async() => {
  const defaultHeader = await __getDefaultHeader();
  axios.defaults.headers.common['Authorization'] = defaultHeader.Authorization;
  axios.defaults.headers.post['Content-Type'] = defaultHeader['Content-Type'];
})();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000,
      staleTime: 0,
      retry: 0,
    },
  },
});

dayjs.extend(isLeapYear);
dayjs.locale("ko");


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
