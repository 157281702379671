import { Column, Entity } from "typeorm";

@Entity("developer", { schema: "dentin" })
export class Developer {
    @Column("varchar", {
        primary: true,
        name: "dev_id",
        comment: "개발자 ID",
        length: 32,
    })
    devId: string;

    @Column("varchar", {
        name: "name",
        comment: "개발자 명",
        length: 64,
    })
    name: string;

    @Column("varchar", {
        name: "reg_admin_id",
        comment: "등록한 관리자 Id",
        length: 32,
    })
    regAdminId: string;

    @Column("datetime", {
        name: "reg_date",
        comment: "등록 일시",
        default: () => "CURRENT_TIMESTAMP",
    })
    regDate: Date;

    @Column("varchar", {
        name: "upd_admin_id",
        comment: "수정한 관리자 Id",
        length: 32,
    })
    updAdminId: string | null;

    @Column("datetime", {
        name: "upd_date",
        nullable: true,
        comment: "수정 일시",
    })
    updDate: Date | null;

}
