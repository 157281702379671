import React from "react";
import EditorSelectForm from "../../components/form/EditorSelectForm";

const DataWrite = () => {
  return (
    <div>
      <EditorSelectForm title="자료실 등록" />
    </div>
  );
};

export default DataWrite;
