import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EntityService } from "../../services/entity.service";
import { Policy } from "../../entities/entities/Policy";

const entityService = new EntityService();

const EditorForm = ({ title }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [details, setDetails] = useState<string>();
  const [required, setRequired] = useState<boolean>(false);
  const [termsTitle, setTermsTitle] = useState<string>("");
  const [effDate, setEffDate] = useState<string>(new Date().toISOString().slice(0,10));
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const onEditorStateChange = (editorState: EditorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };

  const submit = async () => {
    // categoty:"정책 유형 (1. 개인회원, 2. 병원 회원, 3. 공통)",
    // type:정책 유형 (1. 이용약관, 2. 개인정보동의 3. 처리방침)
    const terms = {
      category: state === "member" ? 1 : 2,
      contents: details,
      requireFlag: required,
      revision: null,
      title: termsTitle,
      type: 1,
      eff_date:effDate,
      regDate: new Date(),
    };
    const policy = {
      category: 3,
      contents: details,
      requireFlag: required,
      revision: null,
      title: termsTitle,
      type: 3,
      eff_date:effDate,
      regDate: new Date(),
    };
    const agree = {
      category: state === "member" ? 1 : 2,
      contents: details,
      requireFlag: required,
      revision: null,
      title: termsTitle,
      type: 2,
      eff_date:effDate,
      regDate: new Date(),
    };
    const query = pathname.includes("termswrite")
      ? terms
      : pathname.includes("policywrite")
      ? policy
      : agree;

      try {

        await entityService.createNewPolicy(query);

        navigate(-1);
      } catch (error) {
        console.error(error);
      }
  };

  return (
    <div className="inner page">
      <div className="flex items-end space-x-8">
        <div className="page-tit mt-20">{title}</div>

        <div className="w-2/12">
          <input
            className="checkbox"
            type="checkbox"
            id="select"
            onChange={(e) => setRequired(e.target.checked)}
          />
          <label htmlFor="select"></label>
          필수약관
        </div>
      </div>

      <div>
        <input
          type="text"
          className="text-input mt-4"
          style={{ width: "100%" }}
          placeholder="제목을 입력하세요."
          onChange={(e) => setTermsTitle(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="" className="mr-3">
          시행일 :
        </label>
        <input
          type="date"
          defaultValue={effDate}
          onChange={(e) => setEffDate(e.target.value)}
          className="text-input mt-4 max-w-[150px]"
        />
      </div>
      <div className="mt-[24px]">
        <Editor
          editorStyle={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.24), 0px 0px 1px rgba(0, 0, 0, 0.06)",
            borderRadius: "3px",
            minHeight: "580px",
            padding: "0 16px",
          }}
          localization={{
            locale: "ko",
          }}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "colorPicker",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "emoji",
            ],
          }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          onBlur={() => {
            const editorToHtml = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setDetails(editorToHtml);
          }}
        />
      </div>
      <div className="flex items-center justify-end mt-10">
        <button className="text-[17px]" onClick={submit}>
          등록하기
        </button>
      </div>
    </div>
  );
};;

export default EditorForm;
