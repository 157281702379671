import axios from "axios";
import {useEffect, useState} from "react";
import { getHost } from "../..";
import DutyClassTable from "../../components/board/DutyClassTable";
import FileInput from "../../components/form/FileInput";
import Popup from "../../components/Popup";
import { Lecture } from "../../entities/entities/Lecture";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import { EntityService } from "../../services/entity.service";
import {useRecoilValue} from "recoil";
import {tokenState} from "../../state";

const entityService = new EntityService();

const DutyClassPage = () => {
  const [writePopup, setWritePopup] = useState<Lecture | boolean>(false);

  return (
    <div className="inner page">
      {writePopup && (
        <WritePopup
          close={() => setWritePopup(false)}
          modify={(writePopup as any)?.course_id ? writePopup : false}
        />
      )}
      <div className="flex items-end justify-between">
        <div className="page-tit">법정의무교육</div>
        <button className="blue" onClick={() => setWritePopup(true)}>
          등록하기
        </button>
      </div>
      <DutyClassTable setWritePopup={setWritePopup} />
    </div>
  );
};

export default DutyClassPage;
interface FileModel {
  fileName: string;
  link: string;
}
function WritePopup({ close, modify }) {
  const [creator, setCreator] = useState<string>(modify?.creator || "");
  const [title, setTitle] = useState<string>(modify?.name || "");
  const [description, setDescription] = useState<string>(
    modify?.description
  );
  const [provisions, setProvisions] = useState<string>(
    modify?.provisions
  );
  const [visibleFlag, setVisibleFlag] = useState<string>(
    modify?.visible_flag
  );
  const [videoId, setVideoId] = useState<string>(modify?.video_id || "");
  const [playTime, setPlayTime] = useState<number>(modify?.play_time);
  const [file, setFile] = useState<FileModel[]>([]);

  useEffect(() => {

    if (modify?.material_download_link) {
      let linkArr = modify?.material_download_link.split(',')
      let nameArr = modify?.material_download_filename.split(',')
      let initialArr: Array<FileModel> = []
      nameArr.forEach((val, i) => {
        initialArr.push({
          fileName: val,
          link: linkArr[i],
        })
      })
      setFile(initialArr)
    }
  }, [modify]);
  const addFile = (e) => {
    if(file.length == 10) {
      alert('첨부파일은 10개를 초과할 수 없습니다.')
      return;
    }
    const targetFile = e.target.files[0];

    if(targetFile.name.includes(',')){
      alert('첨부파일에 ","가 포함되어 있습니다')
      return;
    }
    targetFile &&
    axios
      .post(
        `${getHost()}/admin/file/upload`,
        { file:targetFile },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setFile([{
          fileName: res.data.data.upload_file_name,
          link: res.data.data.file_name,
        },...file]);

      })
      .catch((err) => alert("파일을 다시 첨부해주세요"));
  };
  const submit = async () => {
    let name = file.map(x => x.fileName).join(',');
    let link = file.map(x => x.link).join(',');
    try {
      if (modify) {
        // @ts-ignore
        let base64Payload =  sessionStorage.getItem("token").split('.')[1];
        let payload = Buffer.from(base64Payload, 'base64');
        let result = JSON.parse(payload.toString())

        await entityService.callProcedure(modify.lecture_id, {
          creator,
          description,
          name: title,
          videoId,
          playTime: playTime || 0,
          courseId: modify.course_id.toString(),
          imageFilename: null,
          materialDownloadLink: link,
          materialDownloadFilename: name,
          seq: modify.seq || 1,
          provisions,
          visibleFlag,
          userId:JSON.parse(result.sub).userId
        });
      } else {
        const courseId = await entityService.create(LectureCourse, {
          creator,
          description,
          title,
          lectureType: 1,
          score: 0,
          recommendFlag: false,
          startDate: new Date(),
          regDate: new Date(),
        });
        await entityService.create(Lecture, {
          creator,
          description,
          name: title,
          videoId,
          playTime: playTime || 0,
          courseId: courseId.toString(),
          imageFilename: null,
          materialDownloadLink: link,
          materialDownloadFilename: name,
          seq: 1,
          provisions,
          visibleFlag,
        });
      }

      if (!title) {
        alert("제목을 입력하세요.");
        return false;
      }
      if (!title) {
        alert("제목을 입력하세요.");
        return false;
      }
      close();
      alert("등록되었습니다");
      window.location.reload();
    } catch {
      alert("에러가 발생하였습니다");
    }
  };

  const deleteAction = async () => {
    if(window.confirm('삭제하시겠습니까? 삭제 시 모든 데이터가 삭제되고 복구되지 않습니다.')){
      entityService.deleteLectureCourse(
        modify.course_id.toString()
      ).then(()=>{
        alert('삭제되었습니다.')
        window.location.reload()
      });
    }

  }
  const changeRadio = (value: string) => {
    console.log(value)
    setVisibleFlag(value);
  }

  return (
    <Popup
      title="법정의무교육 등록"
      submit={submit}
      close={close}
      confirmTxt={modify ? "수정" : "등록"}
    >
      <div className="space-y-5">
        <div className="flex justify-between items-center">
          <div className="w-[15%]">제목</div>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="text-input"
            type="text"
            placeholder="텍스트를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-top">
          <div className="w-[15%]">교육 내용</div>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow-custom w-[85%] h-36 rounded-sm px-4 py-2"
            placeholder="텍스트를 입력하세요"
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">영상 ID</div>
          <input
            value={videoId}
            onChange={(e) => setVideoId(e.target.value)}
            className="text-input"
            type="text"
            placeholder="영상 링크의 ID를 입력하세요"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">교육시간</div>
          <input
            value={playTime}
            onChange={(e) => setPlayTime(parseInt(e.target.value))}
            className="text-input"
            type="number"
            placeholder="숫자를 입력하세요. (초 단위)"
            style={{ width: "85%" }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="w-[15%]">첨부파일</div>
          <div className="w-[85%]">
            {
              file.map((v,i)=>{
                return(
                  <>
                    <div className="mt-1 flex items-center space-x-4 text-[#666]">
                      <i className="ii-document-with-lines-outline text-xl"></i>
                      <div className="text-base font-semibold w-[320px] truncate">
                        {v.fileName ??
                        "파일을 선택해주세요.(.pdf, .hwp, .doc, .docx)"}
                      </div>
                      <div
                        className="text-sm text-[#666] font-medium bg-[#F5F5F5] rounded-full px-3 py-[1px] cursor-pointer"
                        onClick={() => setFile(file.filter(item => item.fileName !== v.fileName))}
                      >
                        삭제
                      </div>
                    </div>
                  </>
                )
              })
            }
            <label className="mt-4 bg-[#A9A9A9] text-white rounded-md flex items-center justify-between px-3 w-[160px] h-12 cursor-pointer">
              <input
                type="file"
                className="hidden"
                accept=".pdf, .hwp, .doc, .docx"
                onChange={addFile}
              />
              <div className="text-base text-white">{file.length==0?'첨부파일':'첨부파일 추가하기'}</div>
              <i className="ii-export"></i>
            </label>
            <span className="text-[#f00]">첨부파일에 ","가 포함되지 않도록 주의해주세요.</span>
          </div>
        </div>
        <div className="flex justify-between items-top">
          <div className="w-[15%]">근거 조항</div>
          <textarea
            value={provisions}
            onChange={(e) => setProvisions(e.target.value)}
            className="shadow-custom w-[85%] h-36 rounded-sm px-4 py-2"
            placeholder="텍스트를 입력하세요"
          />
        </div>
        <div className="flex items-center">
          <div className="w-[15%]">노출 설정</div>
          <div className="">
            <input type="radio" id="radio1" name="flag" value='y' checked={visibleFlag === 'y'} onChange={(e)=>{
              changeRadio(e.target.value)
            }}/>
            <label htmlFor="radio1">
              노출
            </label>
            <span className="mr-3"></span>
            <input type="radio"id="radio2"  name="flag" value='n' checked={visibleFlag === 'n'} onChange={(e)=>{
              changeRadio(e.target.value)
            }}/>
            <label htmlFor="radio2">
              비노출
            </label>
          </div>

        </div>
        <div className="flex items-center">
          <div className="w-[15%]">수정 이력</div>
          <button
            onClick={async()=>{
              const list = await entityService.fetchChageLectureList(modify.lecture_id);
              console.log(list)
              await entityService.downloadChangeLogJsonArrayToCSV(modify.name, list);
            }}
          >
            다운로드
          </button>
        </div>
        <div className="flex items-center">
          <div className="w-[15%]">강의 삭제</div>
          <button
            className="text-[#fff] bg-[#f00]"
            onClick={deleteAction}
          >
            삭제
          </button>
        </div>
      </div>
    </Popup>
  );
}
