import { useState, useEffect } from "react";
import { EntityService } from "../../services/entity.service";

const CheckboxInput = ({
  title,
  state,
  setState,
  setFile,
}: {
  title: string;
  state: string[];
  setState: (data: string[]) => void;
  setFile: (data: any) => void;
}) => {
  const [input, setInput] = useState<any>();
  const entityService = new EntityService();

  useEffect(() => {
    (async () => {
      const res = await entityService._fetchOccupationList();
      setInput(res);
    })();
  }, []);

  return (
    <div className="inner">
      <div className="text-[#333] font-semibold text-2xl mb-3">{title}</div>
      <div className="px-2 space-x-2 flex flex-wrap items-center mt-8">
        <div className="mb-8">
          <input
            type="checkbox"
            className="hidden peer"
            value="hospital-member"
            id="hospital-member"
            onChange={(e) =>
              e.target.checked ? setState(["병원회원"]) : setState([])
            }
            checked={state.includes("병원회원")}
          />
          <label
            htmlFor="hospital-member"
            className="border-[1px] border-[#ddd] p-2 rounded-md text-[#666] text-base cursor-pointer peer-checked:border-main peer-checked:text-main"
          >
            병원회원
          </label>
        </div>
        <div className="mb-8">
          <input
            type="checkbox"
            className="hidden peer"
            value="select-member"
            id="select-member"
            onChange={(e) =>
              e.target.checked ? setState(["개인회원"]) : setState([])
            }
            checked={state.includes("개인회원")}
          />
          <label
            htmlFor="select-member"
            className="border-[1px] border-[#ddd] p-2 rounded-md text-[#666] text-base cursor-pointer peer-checked:border-main peer-checked:text-main"
          >
            직업별 선택
          </label>
        </div>
        <div className="mb-8">
          <input
            type="checkbox"
            className="hidden peer"
            value="excel"
            id="excel-read"
            onChange={(e) =>
              e.target.checked ? setState(["excel"]) : setState([])
            }
            checked={state.includes("excel")}
          />
          <label
            htmlFor="excel-read"
            className="border-[1px] border-[#ddd] p-2 rounded-md text-[#666] text-base cursor-pointer peer-checked:border-main peer-checked:text-main"
          >
            excel
          </label>
        </div>
      </div>
      {state.includes("개인회원") && (
        <div className="px-2 space-x-2 flex flex-wrap items-center">
          {input?.map((item, idx) => {
            return (
              <div className="mb-4" key={idx}>
                <input
                  type="checkbox"
                  className="hidden peer"
                  value={item}
                  id={idx}
                  onChange={(e) =>
                    e.target.checked
                      ? setState([...state, item])
                      : setState(state.filter((o) => o !== item))
                  }
                  checked={state.includes(item)}
                />
                <label
                  htmlFor={idx}
                  className="border-[1px] border-[#ddd] p-2 rounded-md text-[#666] text-base cursor-pointer peer-checked:border-main peer-checked:text-main"
                >
                  {item}
                </label>
              </div>
            );
          })}
        </div>
      )}
      {state.includes("excel") && (
        <div className="px-2 space-x-2 flex flex-wrap items-center">
          <input
            type="file"
            onChange={(e) => {
              // @ts-ignore
              let file = e.target.files[0];

              if (
                !file.type.includes("sheet") &&
                !file.type.includes("excel") &&
                !file.type.includes("xlsx")
              ) {
                alert(
                  "Excel 파일만 업로드가 가능합니다.\n" +
                    file.type +
                    "은 업로드가 불가능합니다."
                );
                // @ts-ignore
                e.target.value = null;
                return;
              }
              setFile(file);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxInput;
