import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";

const entityService = new EntityService();

const EtcClassTable = ({ setWritePopup }) => {
  const [etcClassList, setEtcClassList] = useState<
    [LectureCourse[], number] | null
  >(null);
  const [deletePopup, setDeletePopup] = useState<LectureCourse | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/board/etc-class?page=${selected}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      const res = await entityService.fecthLectureCourses(4, page);
      setEtcClassList(res);
    })();
  }, [page]);

  if (!etcClassList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-1/12 justify-center">노출순서</div>
        <div className="w-1/12 justify-center">제작자</div>
        <div className="w-6/12">제목</div>
        <div className="w-1/12 justify-center">이수자 수</div>
        <div className="w-1/12 justify-center">수강자 목록</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {etcClassList[0].map((item, idx) => (
        <div className="row" key={idx}>
          <div className="w-1/12 justify-center">{item.priority}</div>
          <div className="w-1/12 justify-center">{item.creator}</div>
          <div
            className="w-6/12 cursor-pointer hover:text-main"
            onClick={() =>
              navigate(`/board/etc-class/${(item as any).course_id}`, {
                state: (item as any).title,
              })
            }
          >
            {item.title}
          </div>
          <div className="w-1/12 justify-center">
            {(item as any).finish_count}명
          </div>
          <div className="w-1/12 justify-center">
            <i
              className="ii ii-import text-main text-lg cursor-pointer"
              onClick={async () => {
                const list = await entityService.fetchLectureCourseStudentList(
                  parseInt((item as any).course_id)
                );

                await entityService.downloadJsonArrayToCSV(item.title, list);
              }}
            ></i>
          </div>
          <div className="w-1/12 justify-center">
            <button className="dark" onClick={() => setWritePopup(item)}>
              수정
            </button>
          </div>
          <div className="w-1/12 justify-center">
            <button onClick={() => setDeletePopup(item)}>삭제</button>
          </div>
        </div>
      ))}
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={etcClassList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            await entityService.deleteLectureCourse(
              (deletePopup as any).course_id
            );
            setDeletePopup(null);
            window.location.reload();
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default EtcClassTable;
