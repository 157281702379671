export default function Popup({
  title = "",
  submit,
  close,
  confirmTxt = "확인",
  children,
}) {
  return (
    <div className="fixed left-0 top-0 w-full h-full">
      <div className="z-10 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 border-[2px] w-1/2 max-h-[80vh] overflow-scroll rounded-md border-main bg-white">
        {title ? (
          <div className="border-b-[1px] border-[#CFCFCF] flex justify-between items-center py-4 px-8 bg-white sticky top-0">
            <div className="text-xl font-semibold">{title}</div>
            <i className="ii-2x ii-remove cursor-pointer" onClick={close}></i>
          </div>
        ) : (
          <div className="flex justify-end mt-3 mr-3">
            <i className="ii-2x ii-remove cursor-pointer" onClick={close}></i>
          </div>
        )}
        <div className="py-8 px-8">{children}</div>
        <div className="flex justify-center mb-10">
          <button onClick={close}>취소</button>
          <button className="dark" onClick={submit}>
            {confirmTxt}
          </button>
        </div>
      </div>
    </div>
  );
}
