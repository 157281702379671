import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { Developer } from "../../entities/entities/Developer";

const DeveloperTable = ({
                        onPageClick,
                        developerPageData,
                        page,
                        checkItems,
                        setCheckItems,
                    }: {
    onPageClick: (data: { selected: number }) => void;
    developerPageData: [Developer[], number];
    page: number;
    checkItems: string[];
    setCheckItems: (any) => void;
}) => {
    const navigate = useNavigate();
    const data = developerPageData && developerPageData[0];

    const handleSingleCheck = (checked: boolean, id: string) => {
        if (checked) {
            setCheckItems((prev) => [...prev, id]);
        } else {
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    const handleAllCheck = (checked: boolean) => {
        if (checked) {
            const idArray: string[] = [];
            data.forEach((el) => idArray.push(el.devId));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }
    };

    return (
        <>
            <div className="table">
                <div className="row header">
                    <div className="w-4/12">
                        <input
                            className="checkbox"
                            type="checkbox"
                            id="select-all"
                            name="select-all"
                            onChange={(e) => handleAllCheck(e.target.checked)}
                            checked={checkItems.length === data.length ? true : false}
                        />
                        <label htmlFor="select-all"></label>
                        아이디
                    </div>
                    <div className="w-4/12">이름</div>
                    <div className="w-4/12 justify-center">관리</div>
                </div>

                {data?.map((item, idx) => (
                    <div className="row" key={idx}>
                        <div className="w-4/12">
                            <input
                                type="checkbox"
                                className="checkbox"
                                id={item.devId}
                                name={`select-${item.devId}`}
                                onChange={(e) => {
                                    handleSingleCheck(e.target.checked, item.devId);
                                }}
                                checked={checkItems.includes(item.devId)}
                            />
                            <label htmlFor={item.devId}></label>
                            {item.devId}
                        </div>
                        <div className="w-4/12">{item.name}</div>
                        <div className="w-4/12 justify-center">
                            <button
                                onClick={() =>
                                    navigate("/info/developerwrite", {state: {item}})
                                }
                            >
                                수정하기
                            </button>
                            <button className="blue"
                                onClick={() =>
                                    navigate("/info/serviceKey", {state: {item}})
                                }
                            >
                                인증키 발급하기
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="paging">
                <ReactPaginate
                    previousLabel="←"
                    previousLinkClassName="before"
                    nextLabel="→"
                    nextLinkClassName="next"
                    breakLabel="..."
                    pageCount={developerPageData && developerPageData[1]}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    pageLinkClassName="num"
                    activeClassName=""
                    activeLinkClassName="current"
                    onPageChange={onPageClick}
                    forcePage={page - 1}
                />
            </div>
        </>
    );
};

export default DeveloperTable;
