import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { ClinicMember } from "./ClinicMember";
import { Member } from "./Member";

@Index("fk_clinic_employee_member_id", ["memberId"], {})
@Index("fk_clinic_employee_employee_id", ["employeeId"], {})
@Entity("clinic_employee", { schema: "dentin" })
export class ClinicEmployee {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("varchar", {
    primary: true,
    name: "employee_id",
    comment: "직원 회원 ID",
    length: 32,
  })
  employeeId: string;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @ManyToOne(
    () => ClinicMember,
    (clinicMember) => clinicMember.clinicEmployees,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: ClinicMember;

  @ManyToOne(() => Member, (member) => member.clinicEmployees, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "employee_id", referencedColumnName: "memberId" }])
  employee: Member;
}
