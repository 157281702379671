
const Ranking = ({
  title,
  data,
  viewField,
}: {
  title: string;
  data: any;
  viewField: string;
}) => {

  if (title === "신규 수강 순위")
 {
  return (
    <div className="w-[480px]">
      <div className="flex items-end justify-between mb-3">
        <div className="text-[#333] text-[20px] font-bold">{title}</div>
      </div>
      <div className="bg-[#F8F8F8] border-t-[1.5px]  border-[#333] h-64">
        <div className="flex items-center justify-evenly border-b-[1.5px] border-[#CFCFCF] py-2">
          <div className="w-[10%] flex items-center justify-center">순위</div>
          <div className="w-[60%]">강의명</div>
          <div className="w-[15%] flex items-center justify-center">
            {viewField}
          </div>
        </div>
        {data?.slice(0, 5).map((i, idx) => {
          return (
            <div key={idx} className="flex items-center justify-evenly py-2">
              <div className="w-[10%] flex items-center justify-center">
                {idx + 1}
              </div>
              <div className="w-[60%] truncate">{i.title}</div>

              <div className="w-[15%] flex items-center justify-center">
                {i["sUM(lt.viewTime)"]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
 }
  if (viewField === "재생수" || viewField === "재생시간") {
    return (
      <div className="w-[480px]">
        <div className="flex items-end justify-between mb-3">
          <div className="text-[#333] text-[20px] font-bold">{title}</div>
        </div>
        <div className="bg-[#F8F8F8] border-t-[1.5px]  border-[#333] h-64">
          <div className="flex items-center justify-evenly border-b-[1.5px] border-[#CFCFCF] py-2">
            <div className="w-[10%] flex items-center justify-center">순위</div>
            <div className="w-[60%]">강의명</div>
            <div className="w-[15%] flex items-center justify-center">
              {viewField}
            </div>
          </div>
          {data?.slice(0, 5).map((i, idx) => {
            return (
              <div key={idx} className="flex items-center justify-evenly py-2">
                <div className="w-[10%] flex items-center justify-center">
                  {idx + 1}
                </div>
                  <div className="w-[60%] truncate">{i.ranker}</div>
                
                <div className="w-[15%] flex items-center justify-center">
                  {i.viewCount}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="w-[480px]">
      <div className="flex items-end justify-between mb-3">
        <div className="text-[#333] text-[20px] font-bold">{title}</div>
      </div>
      <div className="bg-[#F8F8F8] border-t-[1.5px]  border-[#333] h-64">
        <div className="flex items-center justify-evenly border-b-[1.5px] border-[#CFCFCF] py-2">
          <div className="w-[10%] flex items-center justify-center">순위</div>
          <div className="w-[15%]">이름</div>
          <div className="w-[45%]">제목</div>
          <div className="w-[15%] flex items-center justify-center">
            {viewField}
          </div>
        </div>
        {data?.slice(0, 5).map((i, idx) => {
          return (
            <div key={idx} className="flex items-center justify-evenly py-2">
              <div className="w-[10%] flex items-center justify-center">
                {idx + 1}
              </div>
              {i?.ranker && <div className="w-[15%] truncate">{i.ranker}</div>}
              <div className="w-[45%] truncate">{i.rankerDetail}</div>
              <div className="w-[15%] flex items-center justify-center">
                {i.viewCount}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ranking;
