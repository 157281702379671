import { Column, Entity } from "typeorm";

@Entity("admin", { schema: "dentin" })
export class Admin {
  @Column("varchar", {
    primary: true,
    name: "admin_id",
    comment: "관리자 ID",
    length: 32,
  })
  adminId: string;

  @Column("varchar", {
    name: "password",
    comment: "관리자 비밀번호",
    length: 128,
  })
  password: string;

  @Column("varchar", { name: "email", comment: "관리자 email", length: 64 })
  email: string;

  @Column("varchar", { name: "name", comment: "관리자명", length: 64 })
  name: string;

  @Column("varchar", {
    name: "phone_number",
    comment: "관리자 전화번호",
    length: 32,
  })
  phoneNumber: string;

  @Column("varchar", {
    name: "reg_admin_id",
    comment: "등록한 관리자 Id",
    length: 32,
  })
  regAdminId: string;

  @Column("datetime", {
    name: "last_login_date",
    nullable: true,
    comment: "관리자 마지막 로그인 일시",
  })
  lastLoginDate: Date | null;

  @Column("datetime", {
    name: "last_logout_date",
    nullable: true,
    comment: "관리자 마지막 로그아웃 일시",
  })
  lastLogoutDate: Date | null;

  @Column("varchar", {
    name: "refresh_token",
    nullable: true,
    comment: "refresh token 정보",
    length: 8192,
  })
  refreshToken: string | null;

  @Column("datetime", {
    name: "reg_date",
    comment: "등록 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("string", {
    name: "role",
    nullable: true,
    length: 32,
  })
  role: string | null;
}
