import RecruitTable from "../../components/board/RecruitTable";

const RecruitPage = () => {
  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">구인공고 게시판</div>
      </div>
      <RecruitTable />
    </div>
  );
};

export default RecruitPage;
