import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FaqTable from "../../components/communication/FaqTable";
import { EducationFaq } from "../../entities/entities/EducationFaq";
import { EntityService } from "../../services/entity.service";

const FaqPage = () => {
  const entityService = new EntityService();
  const [faqPageData, setFaqPageData] = useState<
    [EducationFaq[], number] | null
  >(null);
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const type = param.get("type") || "recruit";

  useEffect(() => {
    if (type === "recruit")
      (async () => {
        const res = await entityService.fetchFaq(page);
        setFaqPageData(res);
      })();

    if (type === "education")
      (async () => {
        const res = await entityService.fetchEducationFaq(page);
        setFaqPageData(res);
      })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/communication/faq?type=${type}&page=${selected}`);
  };

  if (!faqPageData) return <></>;

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <div className="page-tit mr-5">FAQ 관리</div>
          <select
            className="w-[125px] mr-5"
            onChange={(e) =>
              navigate(`/communication/faq?type=${e.target.value}`)
            }
          >
            <option value="recruit" selected={type === "recruit"}>
              구인구직
            </option>
            <option value="education" selected={type === "education"}>
              온라인교육
            </option>
          </select>
        </div>
        <div>
          <Link to="/communication/faqwrite">
            <button className="blue">등록하기</button>
          </Link>
        </div>
      </div>
      <FaqTable state={faqPageData} onPageClick={onPageClick} page={page} />
    </div>
  );
};

export default FaqPage;
