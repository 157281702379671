import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ServiceKey } from "../../entities/entities/ServiceKey";
import {toYYYYMMDD} from "../../services/utils/repository-util.service";

const ServiceKeyTable = ({
                            onPageClick,
                             serviceKeyPageData,
                            page,
                            checkItems,
                            setCheckItems,
                        }: {
    onPageClick: (data: { selected: number }) => void;
    serviceKeyPageData: [ServiceKey[], number];
    page: number;
    checkItems: string[];
    setCheckItems: (any) => void;
}) => {
    const navigate = useNavigate();
    const data = serviceKeyPageData && serviceKeyPageData[0];

    return (
        <>
            <div className="table">
                <div className="row header">
                    <div className="w-4/12">발급일자</div>
                    <div className="w-4/12">재발급여부</div>
                    <div className="w-4/12 justify-center">인증키</div>
                </div>

                {data?.map((item, idx) => (
                    <div className="row" key={idx}>
                        <div className="w-4/12">{toYYYYMMDD(new Date(item.regDate))}</div>
                        <div className="w-4/12">{item.reissueFlag ? "재발급" : "신규발급"}</div>
                        <div className="w-4/12">{item.apiKey}</div>
                    </div>
                ))}
            </div>
            <div className="paging">
                <ReactPaginate
                    previousLabel="←"
                    previousLinkClassName="before"
                    nextLabel="→"
                    nextLinkClassName="next"
                    breakLabel="..."
                    pageCount={serviceKeyPageData && serviceKeyPageData[1]}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    pageLinkClassName="num"
                    activeClassName=""
                    activeLinkClassName="current"
                    onPageChange={onPageClick}
                    forcePage={page - 1}
                />
            </div>
        </>
    );
};

export default ServiceKeyTable;
