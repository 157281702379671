import { Column, Entity, Index, OneToMany } from "typeorm";
import { MemberWorkArea } from "./MemberWorkArea";
import { ClinicEmployee } from "./ClinicEmployee";
import { ResumeGroup } from "./ResumeGroup";
import { AdminChatMessage } from "./AdminChatMessage";
import { MemberPolicyAgree } from "./MemberPolicyAgree";
import { MemberWish } from "./MemberWish";

@Index("idx_member_1", ["ci"], { unique: true })
@Index("idx_member_composite_1", ["name", "phoneNumber"], {})
@Index("idx_member_occupation", ["occupation"], {})
@Index("idx_member_reg_date", ["regDate"], {})
@Index("idx_member_last_login_date", ["lastLoginDate"], {})
@Entity("member", { schema: "dentin" })
export class Member {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("varchar", {
    name: "password",
    comment: "회원 비밀번호",
    length: 128,
  })
  password: string;

  @Column("varchar", { name: "name", comment: "회원명", length: 64 })
  name: string;

  @Column("varchar", {
    name: "gender",
    comment: "회원 성별 (M: 남자, F: 여자)",
    length: 8,
  })
  gender: string;

  @Column("date", { name: "birth_date", comment: "생년월일" })
  birthDate: string;

  @Column("varchar", {
    name: "ci",
    unique: true,
    comment: "회원 식별 번호 (Connecting Information)",
    length: 256,
  })
  ci: string;

  @Column("varchar", {
    name: "phone_number",
    comment: "회원 전화 번호",
    length: 32,
  })
  phoneNumber: string;

  @Column("varchar", { name: "email", comment: "회원 email", length: 64 })
  email: string;

  @Column("varchar", {
    name: "image_filename",
    nullable: true,
    comment: "이미지 파일명",
    length: 256,
  })
  imageFilename: string | null;

  @Column("varchar", { name: "occupation", comment: "회원 직종", length: 64 })
  occupation: string;

  @Column("varchar", {
    name: "licence_number",
    nullable: true,
    comment: "회원 면허 번호",
    length: 32,
  })
  licenceNumber: string | null;

  @Column("bit", {
    name: "active_flag",
    comment: "활성 여부",
    default: () => "'b'1''",
  })
  activeFlag: boolean;

  @Column("varchar", {
    name: "fcm_token",
    nullable: true,
    comment: "FCM token",
    length: 256,
  })
  fcmToken: string | null;

  @Column("datetime", {
    name: "last_login_date",
    nullable: true,
    comment: "회원 마지막 로그인 일시",
  })
  lastLoginDate: Date | null;

  @Column("datetime", {
    name: "last_logout_date",
    nullable: true,
    comment: "회원 마지막 로그아웃 일시",
  })
  lastLogoutDate: Date | null;

  @Column("tinyint", {
    name: "login_fail_cnt",
    comment: "로그인 실패 횟수",
    default: () => "'0'",
  })
  loginFailCnt: number;

  @Column("datetime", {
    name: "login_block_date",
    nullable: true,
    comment: "로그인 블록 일시",
  })
  loginBlockDate: Date | null;

  @Column("varchar", {
    name: "refresh_token",
    nullable: true,
    comment: "refresh token 정보",
    length: 8192,
  })
  refreshToken: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("int", {
    name: "privacy_dormancy_year",
    nullable: true,
    comment: "휴면 기간",
    default: () => "'3'",
  })
  privacyDormancyYear: number | null;

  @Column("bit", {
    name: "is_dormancy",
    nullable: true,
    comment: "휴면 유무",
    default: () => "'b'0''",
  })
  isDormancy: boolean | null;

  @Column("datetime", {
    name: "created_at",
    nullable: true,
    comment: "가입일",
    default: () => "CURRENT_TIMESTAMP",
  })
  createdAt: Date | null;

  @Column("varchar", {
    name: "event_follow_id",
    nullable: true,
    comment: "추천인 ID",
    length: 32,
  })
  eventFollowId: string | null;

  @Column("char", {
    name: "last_login_device",
    nullable: true,
    comment: "login device",
    length: 1,
  })
  lastLoginDevice: string | null;

  @OneToMany(() => MemberWorkArea, (memberWorkArea) => memberWorkArea.member)
  memberWorkAreas: MemberWorkArea[];

  @OneToMany(() => ClinicEmployee, (clinicEmployee) => clinicEmployee.employee)
  clinicEmployees: ClinicEmployee[];

  @OneToMany(() => ResumeGroup, (resumeGroup) => resumeGroup.member)
  resumeGroups: ResumeGroup[];

  @OneToMany(
    () => AdminChatMessage,
    (adminChatMessage) => adminChatMessage.member
  )
  adminChatMessages: AdminChatMessage[];

  @OneToMany(
    () => MemberPolicyAgree,
    (memberPolicyAgree) => memberPolicyAgree.member
  )
  memberPolicyAgrees: MemberPolicyAgree[];

  @OneToMany(() => MemberWish, (memberWish) => memberWish.member)
  memberWishes: MemberWish[];
}
