import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { Member } from "./Member";
import { Policy } from "./Policy";

@Index("fk_member_policy_agree_member_id", ["memberId"], {})
@Index("fk_member_policy_agree_policy_id", ["policyId"], {})
@Entity("member_policy_agree", { schema: "dentin" })
export class MemberPolicyAgree {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", {
    primary: true,
    name: "policy_id",
    comment: "정책 고유 번호",
  })
  policyId: string;

  @Column("bit", { name: "agree", nullable: true, comment: "동의 여부" })
  agree: boolean | null;

  @ManyToOne(() => Member, (member) => member.memberPolicyAgrees, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: Member;

  @ManyToOne(() => Policy, (policy) => policy.memberPolicyAgrees, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "policy_id", referencedColumnName: "policyId" }])
  policy: Policy;
}
