import DeveloperTable from "../../components/info/DeveloperTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { EntityService } from "../../services/entity.service";
import {Developer} from "../../entities/entities/Developer";

const DeveloperPage = () => {
    const navigate = useNavigate();
    const [developerPageData, setDeveloperPageData] = useState<[Developer[], number] | null>(
        null
    );
    const [checkItems, setCheckItems] = useState<string[]>([]);
    const entityService = new EntityService();
    const location = useLocation();
    const param = new URLSearchParams(location.search);
    const page = Number(param.get("page")) || 1;

    useEffect(() => {
        (async () => {
            const res = await entityService.fetchDeveloperUsers(page, 20);
            setDeveloperPageData(res);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, navigate]);

    const onPageClick = (data) => {
        const selected = data.selected + 1;
        navigate(`/info/developer?page=${selected}`);
    };

    const deleteList = async () => {
        if (window.confirm("삭제하시겠습니까?")) {
            try {
                await entityService.deleteMultiples(Developer, "devId", checkItems);
                alert("삭제가 완료되었습니다.");
                window.location.reload();
            } catch (e) {
                console.log(e);
                alert('에러가 발생했습니다.');
            }
        }
    };

    return (
        <div className="inner page">
            <div className="flex items-end justify-between">
                <div className="page-tit">개발자 계정 목록</div>
                <div className="space-x-2">
                    <Link to="/info/developerwrite">
                        <button className="blue">계정등록</button>
                    </Link>
                    <button onClick={deleteList}>선택삭제</button>
                </div>
            </div>
            {developerPageData && (
                <DeveloperTable
                    developerPageData={developerPageData}
                    onPageClick={onPageClick}
                    page={page}
                    checkItems={checkItems}
                    setCheckItems={setCheckItems}
                />
            )}
        </div>
    );
};

export default DeveloperPage;
