import {useEffect, useRef, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditorSelectForm from "../../components/form/EditorSelectForm";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
// Toast UI Editor
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import axios from "axios";
import { EntityService } from "../../services/entity.service";
import { EducationNotice } from "../../entities/entities/EducationNotice";
import { getHost } from "../..";

const EducationNoticeWrite = () => {
  const { state } = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [file, setFile] = useState<any>();
  const [content, setContent] = useState<string>("");
  const [noticeDataTitle, setNoticeDataTitle] = useState<string>("");
  const [notice, setNotice] = useState<number>(0);
  const navigate = useNavigate();

  const editorRef = useRef<any>();

  const onChange = () => {
    const data = editorRef.current.getInstance().getHTML();
    setContent(data)
    console.log(data);
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(state?.contents || content);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [state, content]);
  //require flag,title 추가
  //upd_date:null 이면 게시됨
  const addFile = (e) => {
    const file = e.target.files[0];

    file &&
      axios
        .post(
          `${getHost()}/admin/file/upload`,
          { file },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setFile({
            fileName: res.data.data.file_name,
            uploadFileName: res.data.data.upload_file_name,
          });
        })
        .catch((err) => alert("파일을 다시 첨부해주세요"));
  };
  const submit = async () => {
    const entityService = new EntityService();
    const type = 1; // normal

    const noticeData = {
      noticeType: type,
      title: noticeDataTitle,
      viewCount: state?.viewCount ? state.viewCount : 0,
      startDate: new Date(),
      regDate: new Date(),
      contents: content,
      focus: notice,
      fileDownloadFilename: file?.uploadFileName,
      fileDownloadLink: file?.fileName,
    };

    if (state) {
      const ensuredTitle =
        noticeData.title === "" ? state.title : noticeData.title;
      const ensuredContents =
        noticeData.contents === "" ? state.contents : noticeData.contents;
      entityService
        .update(EducationNotice, state.noticeId, {
          ...state,
          title: ensuredTitle,
          contents: ensuredContents,
          noticeType: type,
        })
        .then(() => navigate(-1))
        .catch((e) => console.log(e));
    } else {
      entityService
        .create(EducationNotice, noticeData)
        .then(() => navigate(-1))
        .catch((e) => console.log(e));
    }
  };

  return (
    <div>
      <div className="inner page px-4">
        <div className="page-tit mb-10">
          온라인교육 공지사항 〉 <span className="text-[#999]">등록</span>
        </div>
        <div className="space-y-4">
          <div className="flex items-cend space-x-6">
            <div className="w-4/12 flex items-center">
              <input
                type="checkbox"
                className="checkbox"
                id="check"
                onChange={(e) => setNotice(e.target.checked ? 1 : 0)}
              />
              <label htmlFor="check"></label>
              공지
            </div>
          </div>

          <div>
            <input
              type="text"
              className="text-input"
              style={{ width: "100%" }}
              placeholder="제목을 입력하세요."
              onChange={(e) => setNoticeDataTitle(e.target.value)}
              defaultValue={state?.title ?? ""}
            />
          </div>
          <Editor
            ref={editorRef}
            hooks={
              {
                async addImageBlobHook(blob, callback) { // 이미지 업로드 로직 커스텀
                  try {
                    axios
                      .post(
                        `${getHost()}/admin/file/upload`,
                        { file:blob },
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then((res) => {
                        const imageUrl = `${getHost()}/` +res.data.data.file_name;
                        callback(imageUrl, '');
                      });

                  } catch (error) {
                    console.error('업로드 실패 : ', error);
                  }
                }
              }
            }
            language="ko-KR"
            height="600px"
            initialEditType="wysiwyg"
            initialValue={state?.contents}
            onChange={onChange}
          />
        </div>
        <div className="mt-8 mb-10">
          <label className="bg-[#A9A9A9] text-white rounded-md flex items-center justify-between px-3 w-28 h-9 cursor-pointer">
            <input
              type="file"
              className="hidden"
              accept=".pdf, .hwp, .doc, .docx"
              onChange={addFile}
            />
            <div className="text-base text-white">첨부파일</div>
            <i className="ii-export"></i>
          </label>
        </div>
        <div className="flex items-center space-x-4 text-[#666]">
          <i className="ii-document-with-lines-outline text-xl"></i>
          <div className="text-base font-semibold w-[320px] truncate">
            {file?.uploadFileName ??
              "파일을 선택해주세요.(.pdf, .hwp, .doc, .docx)"}
          </div>
          <div
            className="text-sm text-[#666] font-medium bg-[#F5F5F5] rounded-full px-3 py-[1px] cursor-pointer"
            onClick={() => setFile(null)}
          >
            삭제
          </div>
        </div>
        <div className="flex items-center justify-end space-x-4 mt-4">
          <button onClick={() => navigate(-1)}>취소</button>
          <button className="white" onClick={submit}>
            {state ? "수정" : "등록"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EducationNoticeWrite;
