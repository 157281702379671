import dayjs from "dayjs";

const RecieveChat = ({ message }) => {
  return (
    <div>
      <div className="mb-10">
        <div className="flex items-end space-x-3">
          <div className="bg-[#F8F8F8] max-w-[400px] p-4 chat-left">
            <div className="text-[#1F1F1F]">{message.message}</div>
          </div>
          <div className="text-[#6C6C6C] text-xs font-medium">
            <div className="text-[10px]">
              {dayjs(message.regDate).format("YY.MM.DD")}
            </div>
            <div className="text-[10px]">
              {dayjs(message.regDate).format("HH:mm")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecieveChat;
