import { toYYYYMMDD } from "../../services/utils/repository-util.service";
import ReactPaginate from "react-paginate";
import { Policy } from "../../entities/entities/Policy";

const TermsTable = ({
  state,
  onPageClick,
  page,
  checkItems,
  setCheckItems,
}: {
  state: any;
  onPageClick: (data: { selected: number }) => void;
  page: number;
  checkItems: string[];
  setCheckItems: (any) => void;
}) => {
  const data = state && state[0];

  const handleSingleCheck = (checked: boolean, id: string) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray: string[] = [];
      data.forEach((el) => idArray.push(el.policyId));
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };
return (
  <>
    <div className="table">
      <div className="row header">
        <div className="w-[5%]">
          <input
            className="checkbox"
            type="checkbox"
            id="all_select"
            onChange={(e) => handleAllCheck(e.target.checked)}
            checked={checkItems.length === data.length ? true : false}
          />
          <label htmlFor="all_select"></label>
        </div>
        <div className="w-1/12">필수여부</div>
        <div className="w-2/12">제목</div>
        <div className="w-5/12">내용</div>
        <div className="w-1/12 justify-center">등록일</div>
        <div className="w-1/12 justify-center">시행일</div>
        <div className="w-1/12 justify-center">버전</div>
      </div>
      {data?.map((item: Policy & { name: string; clinicName: string }, idx) => (
        <div className="row" key={idx}>
          <div className="w-[5%]">
            <input
              type="checkbox"
              className="checkbox"
              id={item.policyId}
              name={`select-${item.policyId}`}
              onChange={(e) =>
                handleSingleCheck(e.target.checked, item.policyId)
              }
              checked={checkItems.includes(item.policyId)}
            />
            <label htmlFor={item.policyId}></label>
          </div>
          <div className="w-1/12">{item.requireFlag ? '필수' : '선택'}</div>
          <div className="w-2/12">{item.title}</div>
          <div className="w-5/12">
            {(item.name || item.clinicName) && (
              <div className="content">
                {item.name ? item.name : item.clinicName}
              </div>
            )}
            {item.contents && (
              <div className="content">
                {item.contents.split("<p>").join("").split("</p>").join("")}
              </div>
            )}
          </div>
          <div className="w-1/12 justify-center">
            {toYYYYMMDD(new Date(item.regDate))}
          </div>
          <div className="w-1/12 justify-center">
            {item.effDate?toYYYYMMDD(new Date(item.effDate)):''}
          </div>
          <div className="w-1/12 justify-center">{item.revision}</div>
        </div>
      ))}
    </div>
    <div className="paging">
      <ReactPaginate
        previousLabel="←"
        previousLinkClassName="before"
        nextLabel="→"
        nextLinkClassName="next"
        breakLabel="..."
        pageCount={state && state[1]}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        pageLinkClassName="num"
        activeClassName=""
        activeLinkClassName="current"
        onPageChange={onPageClick}
        forcePage={page - 1}
      />
    </div>
  </>
);
};

export default TermsTable;
