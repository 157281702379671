import React from "react";
import TextAreaForm from "../../components/form/TextAreaForm";

const ChatWrite = () => {
  return (
    <div>
      <TextAreaForm title="쪽지발송" message />
    </div>
  );
};

export default ChatWrite;
