import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { ClinicMember } from "./ClinicMember";
import { Resume } from "./Resume";

@Index("fk_clinic_member_resume_wish_member_id", ["memberId"], {})
@Index("fk_clinic_member_resume_wish_resume_id", ["resumeId"], {})
@Entity("clinic_member_wish", { schema: "dentin" })
export class ClinicMemberWish {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", { primary: true, name: "resume_id", comment: "이력서 ID" })
  resumeId: string;

  @Column("text", { name: "memo", nullable: true, comment: "메모" })
  memo: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @ManyToOne(
    () => ClinicMember,
    (clinicMember) => clinicMember.clinicMemberWishes,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: ClinicMember;

  @ManyToOne(() => Resume, (resume) => resume.clinicMemberWishes, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "resume_id", referencedColumnName: "resumeId" }])
  resume: Resume;
}
