import ChatTable from "../../components/communication/ChatTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EntityService } from "../../services/entity.service";
import { useEffect, useState } from "react";
import { AdminChatMessage } from "../../entities/entities/AdminChatMessage";

const ChatPage = () => {
  const entityService = new EntityService();
  const [chatData, setChatData] = useState<[AdminChatMessage[], number] | null>(
    null
  );
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchSentMessageList(page);
      setChatData(res);
    })();
  }, [page]);

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/communication/chat?page=${selected}`);
  };

  if (!chatData) return <></>;

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">쪽지발송 목록</div>
        <div>
          <Link to="/communication/chatwrite">
            <button className="blue">등록하기</button>
          </Link>
        </div>
      </div>
      <ChatTable state={chatData} onPageClick={onPageClick} page={page} />
    </div>
  );
};

export default ChatPage;
