import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { Admin } from "../../entities/entities/Admin";

const AdminTable = ({
  onPageClick,
  adminPageData,
  page,
  checkItems,
  setCheckItems,
}: {
  onPageClick: (data: { selected: number }) => void;
  adminPageData: [Admin[], number];
  page: number;
  checkItems: string[];
  setCheckItems: (any) => void;
}) => {
  const navigate = useNavigate();
  const data = adminPageData && adminPageData[0];

  const handleSingleCheck = (checked: boolean, id: string) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      const idArray: string[] = [];
      data.forEach((el) => idArray.push(el.adminId));
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  return (
    <>
      <div className="table">
        <div className="row header">
          <div className="w-2/12">
            <input
              className="checkbox"
              type="checkbox"
              id="select-all"
              name="select-all"
              onChange={(e) => handleAllCheck(e.target.checked)}
              checked={checkItems.length === data.length ? true : false}
            />
            <label htmlFor="select-all"></label>
            아이디
          </div>
          <div className="w-2/12">이름</div>
          <div className="w-7/12">이메일</div>
          <div className="w-1/12 text-center">관리</div>
        </div>

        {data?.map((item, idx) => (
          <div className="row" key={idx}>
            <div className="w-2/12">
              <input
                type="checkbox"
                className="checkbox"
                id={item.adminId}
                name={`select-${item.adminId}`}
                onChange={(e) => {
                  handleSingleCheck(e.target.checked, item.adminId);
                }}
                checked={checkItems.includes(item.adminId)}
              />
              <label htmlFor={item.adminId}></label>
              {item.adminId}
            </div>
            <div className="w-2/12">{item.name}</div>
            <div className="w-7/12">{item.email}</div>
            <div className="w-1/12 text-center">
              <button
                onClick={() =>
                  navigate("/info/adminwrite", { state: { item } })
                }
              >
                수정하기
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={adminPageData && adminPageData[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </>
  );
};

export default AdminTable;
