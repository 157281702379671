import { Navigate } from "react-router-dom";
import {useRecoilValue} from "recoil";
import {tokenState} from "../state";

export type ProtectedRouteProps = {
  role: Array<string>;
  isLogin:string;
  redirectPath: string;
  outlet: JSX.Element;
};

let permission;

// @ts-ignore
export default function ProtectedRoute({role, isLogin, redirectPath, outlet}: ProtectedRouteProps) {

  const checkPermission = () => {
    if(isLogin) {
      let base64Payload = isLogin.split('.')[1];
      let payload = Buffer.from(base64Payload, 'base64');
      let result = JSON.parse(payload.toString())
      permission = result.roles_list[0]?result.roles_list[0].split(','):null;
    }
  }

  checkPermission();

  if(permission && role.filter(r=> permission.includes(r)).length > 0) {
    return outlet;
  } else {
    alert('권한이 없습니다. 관리자에게 문의해주세요.')
    return Navigate({to:redirectPath});
  }
};