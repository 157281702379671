import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { Resume } from "./Resume";
import { Member } from "./Member";

@Index("fk_resume_group_member_id", ["memberId"], {})
@Entity("resume_group", { schema: "dentin" })
export class ResumeGroup {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "group_id",
    comment: "이력서 그룹 ID",
  })
  groupId: string;

  @Column("varchar", { name: "member_id", comment: "회원 ID", length: 32 })
  memberId: string;

  @Column("varchar", {
    name: "group_name",
    comment: "이력서 그룹명",
    length: 64,
  })
  groupName: string;

  @Column("bit", {
    name: "default_flag",
    comment: "기본 그룹 여부",
    default: () => "'b'0''",
  })
  defaultFlag: boolean;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @OneToMany(() => Resume, (resume) => resume.group)
  resumes: Resume[];

  @ManyToOne(() => Member, (member) => member.resumeGroups, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: Member;
}
