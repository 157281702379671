import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {
  EntityService,
  VisitorStatistics,
} from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";
import { DateRangePicker } from "react-date-range";
import ko from "date-fns/locale/ko";
import {log} from "util";

const VisitPage = () => {
  const entityService = new EntityService();
  const [postingStats, setPostingStats] = useState<any>(null);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [date, setDate] = useState<any>([
    {
      startDate: dayjs(new Date()).subtract(7, "day").toDate(),
      endDate: dayjs(new Date()).subtract(1, "day").toDate(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState(1);
  const dataInfo = [
    { name: "공고현황", unit: "명", value: "" },
    { name: "이력서 통계", unit: "%", value: "" },
  ];
  // const data = visitorStats?.reverse().map((v) => ({
  //   name: toYYYYMMDD(v.createdAt),
  //   uv: v.uniqueVisitors,
  //   pv: v.revisitRatio,
  //   amt: Math.floor(v.uniqueVisitors / v.memberCountTotal),
  // }));

  const COLORS = ["#fe00e0", "#0003c4"];

  useEffect(() => {

    (async () => {
      const res = await entityService.fetchPostingStats(date[0].endDate);
      console.log(res)
      setPostingStats(res);
    })();
  }, [date]);

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">콘텐츠분석</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowCalendar(true);
          }}
        >
          <div className="flex items-center space-x-4 text-[#333] font-medium text-[25px]">
            <div className="w-[350px] text-center">
              {dayjs(date[0].startDate).format("YYYY-MM-DD")} ~{" "}
              {dayjs(date[0].endDate).format("YYYY-MM-DD")}
            </div>
            <i className="ii-calendar-event-outline"></i>
          </div>
        </div>
      </div>
      {showCalendar && (
        <>
          <div className="overlay" onClick={() => setShowCalendar(false)}></div>
          <div className="calendar">
            <DateRangePicker
              className={"calendar"}
              locale={ko}
              months={1}
              ranges={date}
              onChange={(item) => setDate([item.selection])}
              maxDate={dayjs(new Date()).subtract(1, "day").toDate()}
              dateDisplayFormat={"yyyy-MM-dd"}
            />
          </div>
        </>
      )}

      <div className="flex justify-between bg-[#EFEFEF] border-t-4 border-[#333333] mt-4">
        {dataInfo.map((item, idx) => {
          return (
            <div
              className="w-[600px] text-center p-3 border-r-2 cursor-pointer"
              onClick={() => setType(idx)}
              style={type === idx ? { background: "#fff" } : {}}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <div className="p-6 color-[#333333] text-2xl font-medium"></div>
      {type === 0 && (
        <>
          <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
            공고 현황
          </div>
          <BarChart
            width={1200}
            height={250}
            data={postingStats?.jbOpneningStats.map((m) => ({
              '전체 공고수': m.openCnt+m.hideCnt,
              '활성화 공고수': m.openCnt,
              'date':dayjs(m.createdAt).format("YYYY-MM-DD")
            }))}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="전체 공고수" stackId="a" fill="#8884d8" />
            <Bar dataKey="활성화 공고수" stackId="a" fill="#cccccc" />
          </BarChart>
        </>
      )}
      {type === 1 && (
        <>
          <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
            이력서 현황
          </div>
          <BarChart
            width={1200}
            height={250}
            data={postingStats?.rcOpneningStats.map((m) => ({
              '전체 이력서': m.openCnt+m.hideCnt,
              '활성화 된 이력서': m.openCnt,
              'date':dayjs(m.createdAt).format("YYYY-MM-DD")
            }))}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="전체 이력서" stackId="a" fill="#8884d8" />
            <Bar dataKey="활성화 된 이력서" stackId="a" fill="#cccccc" />
          </BarChart>
        </>
      )}
    </div>
  );
};

export default VisitPage;
