// 정책 유형 (1. 개인회원, 2. 병원 회원, 3. 공통)
export enum PolicyCategoryType {

    /**
     * 개인 회원
     */
    NORMAL = 1,

    /**
     * 병원 회원
     */
    CLNIIC = 2,

    /**
     * 공통
     */
    SHARE = 3
}