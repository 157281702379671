import { Column, Entity, Index, PrimaryGeneratedColumn } from "typeorm";

@Index("idx_member_withdraw_hist_member_id", ["memberId"], {})
@Index("idx_member_withdraw_hist_reg_date", ["regDate"], {})
@Entity("member_withdraw_hist", { schema: "dentin" })
export class MemberWithdrawHist {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "hist_id",
    comment: "이력 고유 ID",
  })
  histId: string;

  @Column("varchar", { name: "member_id", comment: "회원 ID", length: 32 })
  memberId: string;

  @Column("datetime", { name: "reg_date", comment: "탈퇴 일시" })
  regDate: Date;
}
