import HomeNow from "../components/HomeNow";
import Message from "../components/home/Message";
import Daily from "../components/home/Daily";
import { useState, useEffect } from "react";
import { LandingPageData } from "../interfaces/landing-page-data.interface";
import { EntityService } from "../services/entity.service";
import NewVideo from "../components/NewVideo";

const Home = () => {
  const entityService = new EntityService();

  const [landingPageData, setLandingPageData] = useState<LandingPageData>();
  const [dates, setDates] = useState(new Date());

  // 각 채용 공고, 인재 정보, 실습 치과, 동영상
  useEffect(() => {
    (async () => {
      const res = await entityService.fetchLandingPageDataByDate(dates);
      setLandingPageData(res);
    })();
  }, [dates]);

  return (
    <div className="max-w-[1050px] m-auto pb-28">
      <Daily
        landingPageData={landingPageData}
        dates={dates}
        setDates={setDates}
      />
      <div className="px-5 w-[1050px]">
        <div className="flex items-start justify-between mb-7">
          <HomeNow title="실시간 채용공고" data={landingPageData?.recruits} />
          <HomeNow
            title="실시간 인재정보"
            data={landingPageData?.humanResources}
          />
        </div>
        <div className="flex items-start justify-between">
          <HomeNow
            title="실시간 실습치과공고"
            data={landingPageData?.internRecruits}
          />
          <NewVideo data={landingPageData?.latestVideoList} />
        </div>
      </div>
      <div>
        <Message chatData={landingPageData?.chatMessages} />
      </div>
    </div>
  );
};

export default Home;
