import {useEffect, useRef, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditorSelectForm from "../../components/form/EditorSelectForm";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
// Toast UI Editor
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import axios from "axios";
import { EntityService } from "../../services/entity.service";
import { DutyNotice } from "../../entities/entities/DutyNotice";
import { getHost } from "../..";

const DutyNoticeWrite = () => {
  const { state } = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState<string>("");
  const [noticeDataTitle, setNoticeDataTitle] = useState<string>("");
  const [notice, setNotice] = useState<number>(0);
  const navigate = useNavigate();

  const editorRef = useRef<any>();

  const onChange = () => {
    const data = editorRef.current.getInstance().getHTML();
    setContent(data)
    console.log(data);
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(state?.contents || content);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [state, content]);
  //require flag,title 추가
  //upd_date:null 이면 게시됨

  const submit = async () => {
    const entityService = new EntityService();
    const noticeData = {
      title: noticeDataTitle,
      regDate: new Date(),
      contents: content,
    };

    if (state) {
      const ensuredTitle =
        noticeData.title === "" ? state.title : noticeData.title;
      const ensuredContents =
        noticeData.contents === "" ? state.contents : noticeData.contents;
      entityService
        .update(DutyNotice, state.noticeId, {
          ...state,
          title: ensuredTitle,
          contents: ensuredContents,
        })
        .then(() => navigate(-1))
        .catch((e) => console.log(e));
    } else {
      entityService
        .create(DutyNotice, noticeData)
        .then(() => navigate(-1))
        .catch((e) => console.log(e));
    }
  };

  return (
    <div>
      <div className="inner page px-4">
        <div className="page-tit mb-10">
          법정의무교육 공지사항 〉 <span className="text-[#999]">등록</span>
        </div>
        <div className="space-y-4">
          <div>
            <input
              type="text"
              className="text-input"
              style={{ width: "100%" }}
              placeholder="제목을 입력하세요."
              onChange={(e) => setNoticeDataTitle(e.target.value)}
              defaultValue={state?.title ?? ""}
            />
          </div>
          <Editor
            ref={editorRef}
            hooks={
              {
                async addImageBlobHook(blob, callback) { // 이미지 업로드 로직 커스텀
                  try {
                    axios
                      .post(
                        `${getHost()}/admin/file/upload`,
                        { file:blob },
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then((res) => {
                        const imageUrl = `${getHost()}/` +res.data.data.file_name;
                        callback(imageUrl, '');
                      });

                  } catch (error) {
                    console.error('업로드 실패 : ', error);
                  }
                }
              }
            }
            language="ko-KR"
            height="600px"
            initialEditType="wysiwyg"
            initialValue={state?.contents}
            onChange={onChange}
          />
        </div>
        <div className="flex items-center justify-end space-x-4 mt-4">
          <button onClick={() => navigate(-1)}>취소</button>
          <button className="white" onClick={submit}>
            {state ? "수정" : "등록"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DutyNoticeWrite;
