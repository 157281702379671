import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getHost } from "..";
import FileInput from "../components/form/FileInput";
import { BANNER_TYPES } from "../constants";
import { Banner } from "../entities/entities/Banner";
import { EntityService } from "../services/entity.service";

const entityService = new EntityService();

const BannerWrite = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [url, setUrl] = useState<string>(state?.bannerUrl || "");
  const [location, setLocation] = useState<number>(state?.bannerType || 0);
  const [image, setImage] = useState(state?.imageFilename);

  const submit = async () => {
    if (+location === 0 || isNaN(+location)) {
      alert("배너 타입을 지정해주세요");
      return;
    }

    try {
      state?.bannerUrl
        ? await entityService.update(Banner, state.bannerId, {
            ...state,
            bannerUrl: url,
            bannerType: location,
            imageFilename: image,
            regDate: new Date(),
          })
        : await entityService.create(Banner, {
            bannerUrl: url,
            bannerType: location,
            imageFilename: image,
            startDate: new Date(),
            regDate: new Date(),
          });

      alert("등록되었습니다");
      navigate("/banner");
    } catch {
      alert("에러가 발생하였습니다.");
    }
  };

  return (
    <div className="inner px-4">
      <div className="page-tit mb-16">배너등록</div>
      <div className="px-6">
        <div className="shadow-custom rounded-lg py-6 space-y-4">
          <div className="flex items-center">
            <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
              URL
            </div>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="text-input"
              placeholder="URL 주소를 입력하세요."
            />
          </div>
          <div className="flex items-center">
            <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
              배너위치
            </div>
            <select
              className="w-[380px]"
              value={location}
              onChange={(e) => {
                if (+e.target.value === 0 || isNaN(+e.target.value)) {
                  alert("배너 타입을 지정해주세요");
                } else {
                  return setLocation(parseInt(e.target.value));
                }
              }}
            >
              {BANNER_TYPES.map((item, idx) => (
                <option key={idx} value={(item as any).value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center">
            <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
              이미지 첨부
            </div>
            <div className="w-[380px]">
              <FileInput
                selectFile={async (f) => {
                  const res = await axios.post(
                    `${getHost()}/admin/file/upload`,
                    { file: f[0] },
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  setImage(res.data.data.file_name);
                }}
                file={image}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2 mt-8">
          <button onClick={() => navigate(-1)}>취소</button>
          <button className="bg-main" onClick={submit}>
            {state?.bannerUrl ? "수정" : "등록"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerWrite;
