import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { LectureCourse } from "./LectureCourse";

@Index("fk_lecture_course_bookmark_course_id", ["courseId"], {})
@Entity("lecture_course_bookmark", { schema: "dentin" })
export class LectureCourseBookmark {
  @Column("tinyint", {
    primary: true,
    name: "member_type",
    comment: "회원 유형 (1: 개인회원, 2: 병원 회원, 3: 관리자 )",
  })
  memberType: number;

  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", { primary: true, name: "course_id", comment: "강좌 ID" })
  courseId: string;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @ManyToOne(
    () => LectureCourse,
    (lectureCourse) => lectureCourse.lectureCourseBookmarks,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "course_id", referencedColumnName: "courseId" }])
  course: LectureCourse;
}
