const EnvironmentPage = () => {
  return (
    <div className="inner page"
    style={{ display: "flex", flexDirection: 'column' }}>
      <div className="page-tit">개발 및 관리정보</div>
      <div className="page-tit"></div>
      <div className="page-tit"><p style={{fontSize: "30px"}}>서버 아이피: 1.201.163.74</p></div>
      <div className="page-tit"><p style={{fontSize: "30px"}}>도메인: dent-in.co.kr</p></div>
    </div>
  );
};

export default EnvironmentPage;
