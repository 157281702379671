import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { EntityService, VisitorStatistics } from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";
import { DateRangePicker } from "react-date-range";
import ko from "date-fns/locale/ko";
import { mapQueryStatusFilter } from "react-query/types/core/utils";

const VisitPage = () => {
  const entityService = new EntityService();
  const [visitorStats, setVisitorStats] = useState<VisitorStatistics[] | null>(null);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [date, setDate] = useState<any>([
    {
      startDate: dayjs(new Date()).subtract(7, "day").toDate(),
      endDate: dayjs(new Date()).subtract(1, "day").toDate(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState(0);
  const dataInfo = [
    { name: "순 방문자수", unit: "명", value: "" },
    { name: "재방문율", unit: "%", value: "" },
    { name: "평균 방문횟수", unit: "회", value: "" },
  ];
  const data = visitorStats?.reverse().map((v) => ({
    name: toYYYYMMDD(v.createdAt),
    uv: v.uniqueVisitors,
    pv: v.revisitRatio,
    amt: Math.floor(v.uniqueVisitors / v.memberCountTotal),
  }));


  useEffect(() => {
    (async () => {
      const result = await entityService._fetchVisitorsByDate(
        date[0].startDate,
        date[0].endDate
      );
      setVisitorStats(result);

    })();

  } ,[date])

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">방문분석</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowCalendar(true);
          }}
        >
          <div className="flex items-center space-x-4 text-[#333] font-medium text-[25px]">
            <div className="w-[350px] text-center">
              {dayjs(date[0].startDate).format("YYYY-MM-DD")} ~{" "}
              {dayjs(date[0].endDate).format("YYYY-MM-DD")}
            </div>
            <i className="ii-calendar-event-outline"></i>
          </div>
        </div>
      </div>
      {showCalendar && (
        <>
          <div className="overlay" onClick={() => setShowCalendar(false)}></div>
          <div className="calendar">
            <DateRangePicker
              className={"calendar"}
              locale={ko}
              months={1}
              ranges={date}
              onChange={(item) => setDate([item.selection])}
              maxDate={dayjs(new Date()).subtract(1, "day").toDate()}
              dateDisplayFormat={"yyyy-MM-dd"}
            />
          </div>
        </>
      )}

      <div className="flex justify-between bg-[#EFEFEF] border-t-4 border-[#333333] mt-4">
        {dataInfo.map((item, idx) => {
          return (
            <div
              className="w-[400px] text-center p-3 border-r-2 cursor-pointer"
              onClick={() => setType(idx)}
              style={type === idx ? { background: "#fff" } : {}}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <div className="p-6 color-[#333333] text-2xl font-medium">
        {dataInfo[type].name}
        <div className="mt-2 color-[#333333] text-3xl font-medium">
          {dataInfo[type].value}
          <span className="text-lg ml-2">{dataInfo[type].unit}</span>
        </div>
      </div>
      <LineChart
        width={1200}
        height={250}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {type === 0 ? (
          <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        ) : type === 1 ? (
          <Line type="monotone" dataKey="pv" stroke="#8884d8" />
        ) : (
          <Line type="monotone" dataKey="amt" stroke="#8884d8" />
        )}
      </LineChart>
    </div>
  );
};

export default VisitPage;
