/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from "axios";
import { getHost } from "../..";
import { Admin } from "../../entities/entities/Admin";
import { Banner } from "../../entities/entities/Banner";
import { AdminChatMessage } from "../../entities/entities/AdminChatMessage";
import { ClinicEmployee } from "../../entities/entities/ClinicEmployee";
import { ClinicMember } from "../../entities/entities/ClinicMember";
import { ClinicMemberPolicyAgree } from "../../entities/entities/ClinicMemberPolicyAgree";
import { ClinicMemberWish } from "../../entities/entities/ClinicMemberWish";
import { ClinicMemberWithdrawHist } from "../../entities/entities/ClinicMemberWithdrawHist";
import { CommonCode } from "../../entities/entities/CommonCode";
import { CommonCodeSecondary } from "../../entities/entities/CommonCodeSecondary";
import { CommonGroupCode } from "../../entities/entities/CommonGroupCode";
import { EducationBanner } from "../../entities/entities/EducationBanner";
import { EducationFaq } from "../../entities/entities/EducationFaq";
import { EducationNotice } from "../../entities/entities/EducationNotice";
import { Faq } from "../../entities/entities/Faq";
import { JobAction } from "../../entities/entities/JobAction";
import { JobOpening } from "../../entities/entities/JobOpening";
import { JobOpeningViewCount } from "../../entities/entities/JobOpeningViewCount";
import { Lecture } from "../../entities/entities/Lecture";
import { LectureCategoryConfig } from "../../entities/entities/LectureCategoryConfig";
import { LectureCourse } from "../../entities/entities/LectureCourse";
import { LectureCourseBookmark } from "../../entities/entities/LectureCourseBookmark";
import { LectureTake } from "../../entities/entities/LectureTake";
import { Member } from "../../entities/entities/Member";
import { MemberLoginCount } from "../../entities/entities/MemberLoginCount";
import { MemberPolicyAgree } from "../../entities/entities/MemberPolicyAgree";
import { MemberWish } from "../../entities/entities/MemberWish";
import { MemberWithdrawHist } from "../../entities/entities/MemberWithdrawHist";
import { MemberWorkArea } from "../../entities/entities/MemberWorkArea";
import { Notice } from "../../entities/entities/Notice";
import { Occupation } from "../../entities/entities/Occupation";
import { Policy } from "../../entities/entities/Policy";
import { RecommendKeyword } from "../../entities/entities/RecommendKeyword";
import { Reference } from "../../entities/entities/Reference";
import { Resume } from "../../entities/entities/Resume";
import { ResumeGroup } from "../../entities/entities/ResumeGroup";
import { TempLocation } from "../../entities/entities/TempLocation";
import { UploadFileInfo } from "../../entities/entities/UploadFileInfo";
import { WorkArea } from "../../entities/entities/WorkArea";
import { LectureStudent } from "../entity.service";
import { DutyNotice } from "../../entities/entities/DutyNotice";
import { Developer } from "../../entities/entities/Developer";
import { ServiceKey } from "../../entities/entities/ServiceKey";

export type StringPropertyKeyOf<T> = keyof T extends string ? keyof T : never;

export const fetchDetailEntity = (async <T>(EntityClass: new () => T, conditionEntity: Partial<T>) => {

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const entrySet = Object.entries(conditionEntity).map(([_key, _value]) => {
        return `${camelCaseToSnakeCase(_key)} = ${ parseValueToSqlFormat(_value)}`;
    }).join(' AND ');

    const sql = `SELECT * FROM ${tableName} WHERE ${entrySet} LIMIT 1`;

    const result = await queryObject<T>(sql);

    return result[0];
});

export const createEntity = (async <T>(EntityClass: new () => T, newEntity: Partial<T>) => {

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const keySet = Object.keys(newEntity).map(key => `${camelCaseToSnakeCase(key)}`).join(',');
    const valueSet = Object.values(newEntity).map(value => {
        const parsedValue = parseValueToSqlFormat(value);
        return `${parsedValue}`;
    }).join(',');

    const createSql = `INSERT INTO ${tableName} (${keySet}) VALUES (${valueSet}) `;

    try {

        const latestId = await getNextAutoIncrementId(tableName);
        const createResult = await query(createSql);

        if (createResult) {
            const [{ AUTO_INCREMENT: id }] = latestId;
            return id;

        } else {
            return null;
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
});

export const getNextAutoIncrementId = (async (tableName) => {

    const sql = `SELECT AUTO_INCREMENT
    FROM INFORMATION_SCHEMA.TABLES
    WHERE TABLE_NAME = '${tableName}'
    AND TABLE_SCHEMA = DATABASE();`
    const latestId = await query(sql, 0, true);

    return latestId;
});

export const createEntityMultiple = (async <T>(EntityClass: new () => T, newEntities: Array<Partial<T>>) => {

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);
    const keySet = Object.keys(newEntities[0]).map(key => `${camelCaseToSnakeCase(key)}`).join(',');

    const multipleValueSqlString = newEntities.map(newEntity => {
        const valueSet = Object.values(newEntity).map(value => {
            const parsedValue = parseValueToSqlFormat(value);
            return `${parsedValue}`;
        }).join(',');

        return `(${valueSet})`;
    }).join(',');

    const createSql = `INSERT INTO ${tableName} (${keySet}) VALUES ${multipleValueSqlString};`;

    try {
        await query(createSql);
        return true;
    } catch (error: any) {
        throw new Error(error.message);
    }
});

export const deleteEntity = (async <T>(EntityClass: new () => T, entityId: string | number) => {

    const entityIdProperty = getEntityIdByClass(EntityClass);

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const entityIdValueString = typeof entityId === typeof '' ? `'${entityId}'` : entityId;

    const defaultUpdateSql = `DELETE FROM ${tableName} WHERE ${entityIdProperty} = ${entityIdValueString}`;

    const result = await query(defaultUpdateSql);

    return result;
}) as <T>(EntityClass: new () => T, entityId: string | number) => Promise<boolean>;

export const deleteEntityList = (async <T>(EntityClass: new () => T, targetProperty: keyof T, values: (string | number)[]) => {

    const inOperatorString = values.map(value => {
        if (typeof value === typeof '') {
            return `'${value}'`;
        } else {
            return value;
        }
    }).join(',');

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);
    const sqlTargetProperty = camelCaseToSnakeCase(targetProperty as string);

    const deleteSql = `DELETE FROM ${tableName} WHERE ${sqlTargetProperty} IN (${inOperatorString})`;

    await query(deleteSql);

    return true;

}) as <T>(EntityClass: new () => T, targetProperty: keyof T, values: (string | number)[]) => Promise<boolean>;

export const updateEntity = (async <T>(EntityClass: new () => T, entityId: string | number, partial: Partial<T>) => {

    if (EntityClass === ClinicMember) {
        const { location, ...rest } = (partial as unknown as ClinicMember);
        partial = rest as any;
    }

    const entityIdProperty = getEntityIdByClass(EntityClass);

    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const defaultUpdateSql = `UPDATE ${tableName} SET ${
        Object.entries(partial).map(([key, value]) => {
            const parsedValue = parseValueToSqlFormat(value);
            return `${camelCaseToSnakeCase(key)} = ${ parsedValue }`;
        }).join(',')
    } WHERE ${tableName}.${entityIdProperty} = '${entityId as string | number}'`;
    // result 가 emptry array 라 result
    await query(defaultUpdateSql);

    return true;
}) as <T>(EntityClass: new () => T, entityId: string | number, partial: Partial<T>) => Promise<boolean>

export const getEntityIdByClass = <T>(EntityClass: new () => T): StringPropertyKeyOf<T> => {

    let entityId: string | null = null;

    switch (EntityClass) {
        case Admin:
            entityId = 'admin_id';
            break;
        case Developer:
            entityId = 'dev_id';
            break;
        case ServiceKey:
            entityId = 'api_key_id';
            break;
        case Banner:
        case EducationBanner:
            entityId = 'banner_id';
            break;
        case AdminChatMessage:
            entityId = 'id';
            break;
        case CommonCode:
            entityId = 'code_id';
            break;
        case CommonCodeSecondary:
            entityId = 'secondary_code_id';
            break;
        case CommonGroupCode:
            entityId = 'group_code_id';
            break;
        case EducationFaq:
            entityId = 'faq_id';
            break;
        case EducationNotice:
            entityId = 'notice_id';
            break;
        case JobOpening:
            entityId = 'job_id';
            break;
        case Lecture:
            entityId = 'lecture_id';
            break;
        case LectureCategoryConfig:
            entityId = 'id';
            break;
        case LectureCourse:
            entityId = 'course_id';
            break;
        case MemberWithdrawHist:
            entityId = 'hist_id';
            break;
        case Occupation:
            entityId = 'occupation_id';
            break;
        case Notice:
            entityId = 'notice_id';
            break;
        case DutyNotice:
            entityId = 'notice_id';
            break;
        case Policy:
            entityId = 'policy_id';
            break;
        case Resume:
            entityId = 'resume_id';
            break;
        case ResumeGroup:
            entityId = 'group_id';
            break;
        case UploadFileInfo:
            entityId = 'file_id';
            break;
        case Member:
        case ClinicMember:
            entityId = 'member_id';
            break;
        case Reference:
            entityId = 'reference_id';
            break;
        default:
            entityId = _inferSqlId(EntityClass);
    }

    return entityId as unknown as StringPropertyKeyOf<T>;
}

const _inferSqlId = <T>(EntityClass: new () => T) => {

    const snakeCaseClassName = camelCaseToSnakeCaseForClassName<T>(EntityClass);
    const camelCaseStartWithLower = EntityClass.name[0].toLowerCase() + EntityClass.name.slice(1);

    const inferId = `${camelCaseStartWithLower}Id`;
    const inferIdOnSql = `${snakeCaseClassName}_id`;

    const instance = new EntityClass();

    const keys = Object.keys(instance as any);

    const hasKey = !!keys.find(key => key === inferId);

    if (hasKey) {
        return inferIdOnSql as unknown as StringPropertyKeyOf<T>;
    } else {
        throw new Error('확인되지 않은 Entity 입니다.');
    }
}

export const queryForDetail = async <T>(EntityClass: new () => T, idColumnName: string, id: number) => {
    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const result = await _queryDetail(tableName, idColumnName, id);

    return result as T;
}

export const queryAndCountWithClass = async <T>(EntityClass: new () => T, page: number, take: number, getWhereClauseCallback?: (alias: string) => string) => {
    const tableName = camelCaseToSnakeCaseForClassName(EntityClass);

    const result = queryAndPages<T>(tableName, page - 1, take, getWhereClauseCallback);

    return result;
};


export const _queryDetail = async <T>(tableName: string, idColumnName: string, id: number) => {

    const sql = `SELECT * FROM ${tableName}.${idColumnName} = ${id}`;
    const result = await query(sql);

    return result as T;
}

export const queryAndPages = async <T>(tableName: string, page: number, take: number, getWhereClauseCallback?: (alias: string) => string) => {

    const skip = page * take;

    const _whereClause = getWhereClauseCallback ? getWhereClauseCallback(tableName) : null;
    const whereClauseString = _whereClause ? _whereClause : '';

    const sql = `SELECT * FROM ${tableName} ${ whereClauseString } LIMIT ${take} OFFSET ${skip}`;
    const countSql = `SELECT COUNT(*) AS count FROM ${tableName} ${ whereClauseString } LIMIT 1`;

    const result = await queryObject(sql) as T[];
    const countResult = await query(countSql);

    const count = countResult[0]?.count ?? 0;

    return [result, getPages(take, count) ] as [T[], number];
}


export const queryObject = async <T>(sql: string, retryCount = 0, ignoreRestrict = false) => {

    const data = await query(sql, retryCount, ignoreRestrict);

    return Array.isArray(data) ?
        data.map(element => snakeToCamelCaseForObject(element)) as T[]:
        snakeToCamelCaseForObject(data) as T;
}

export const query = async (sql: string, retryCount = 0, ignoreRestrict = false) => {

    const select = sql.includes('SELECT');
    const updateOrDelete = sql.includes('UPDATE') || sql.includes('DELETE');

    if (ignoreRestrict === false && updateOrDelete && sql.includes('WHERE') === false) {

        throw new Error('Update / Delete 문은 반드시 where 절을 지정해야합니다 :' + sql);
    } else if (ignoreRestrict === false && select && sql.includes('LIMIT') === false) {

        throw new Error('SELECT 문은 반드시 limit 절을 지정해야합니다 :' + sql);
    }

    try {

        const maaaaaaaagicSQLUrl = getHost() + '/admin/sql';

        const queryResult = await axios.post(maaaaaaaagicSQLUrl, sql);

        const data = queryResult.data.data;

        if (data.length >= 0) {
            return data;
        } else {
            return [];
        }

   } catch (error) {
        console.error('fail:', sql);
        console.error('retry...:');
        console.error('error :', error);

        throw new Error((error as any).message);
   }
};

export const snakeToCamelCaseForObject = (rawEntity: any) => {

    if (!rawEntity) {
        return null;
    }

    const newObj = {};

    for (let [key, value] of Object.entries(rawEntity)) {

        const camelCaseKey = key.split('_').map(keyToken => keyToken[0].toUpperCase() + keyToken.slice(1)).join('');
        const camelCaseWithLowerCaseStart = camelCaseKey[0].toLowerCase() + camelCaseKey.slice(1);
        newObj[camelCaseWithLowerCaseStart] = value;
    }

    return newObj;
}

export const camelCaseToSnakeCaseForClassName = <T> (EntityClass: new () => T) => {

    const className = getEntityClassName(EntityClass);

    return className.replace(/([A-Z]+)/g, token => `_${token.toLowerCase()}`).slice(1);
}

export const getEntityClassName = <T> (EntityClass: new () => T): string => {
    let entityClassName: string;
    switch (EntityClass) {
        case Admin:
            entityClassName = 'Admin';
            break;
        case Developer:
            entityClassName = 'Developer';
            break;
        case ServiceKey:
            entityClassName = 'ServiceKey';
            break;
        case Banner:
            entityClassName = 'Banner';
            break;
        case AdminChatMessage:
            entityClassName = 'AdminChatMessage';
            break;
        case ClinicEmployee:
            entityClassName = 'ClinicEmployee';
            break;
        case ClinicMember:
            entityClassName = 'ClinicMember';
            break;
        case ClinicMemberPolicyAgree:
            entityClassName = 'ClinicMemberPolicyAgree';
            break;
        case ClinicMemberWish:
            entityClassName = 'ClinicMemberWish';
            break;
        case ClinicMemberWithdrawHist:
            entityClassName = 'ClinicMemberWithdrawHist';
            break;
        case CommonCode:
            entityClassName = 'CommonCode';
            break;
        case CommonCodeSecondary:
            entityClassName = 'CommonCodeSecondary';
            break;
        case CommonGroupCode:
            entityClassName = 'CommonGroupCode';
            break;
        case EducationBanner:
            entityClassName = 'EducationBanner';
            break;
        case EducationFaq:
            entityClassName = 'EducationFaq';
            break;
        case EducationNotice:
            entityClassName = 'EducationNotice';
            break;
        case Faq:
            entityClassName = 'Faq';
            break;
        case JobAction:
            entityClassName = 'JobAction';
            break;
        case JobOpening:
            entityClassName = 'JobOpening';
            break;
        case JobOpeningViewCount:
            entityClassName = 'JobOpeningViewCount';
            break;
        case Lecture:
            entityClassName = 'Lecture';
            break;
        case LectureCourse:
            entityClassName = 'LectureCourse';
            break;
        case LectureCategoryConfig:
            entityClassName = 'LectureCategoryConfig';
            break;
        case LectureCourseBookmark:
            entityClassName = 'LectureCourseBookmark';
            break;
        case LectureTake:
            entityClassName = 'LectureTake';
            break;
        case Member:
            entityClassName = 'Member';
            break;
        case MemberLoginCount:
            entityClassName = 'MemberLoginCount';
            break;
        case MemberPolicyAgree:
            entityClassName = 'MemberPolicyAgree';
            break;
        case MemberWish:
            entityClassName = 'MemberWish';
            break;
        case MemberWithdrawHist:
            entityClassName = 'MemberWithdrawHist';
            break;
        case MemberWorkArea:
            entityClassName = 'MemberWorkArea';
            break;
        case Notice:
            entityClassName = 'Notice';
            break;
        case Occupation:
            entityClassName = 'Occupation';
            break;
        case Policy:
            entityClassName = 'Policy';
            break;
        case RecommendKeyword:
            entityClassName = 'RecommendKeyword';
            break;
        case Resume:
            entityClassName = 'Resume';
            break;
        case ResumeGroup:
            entityClassName = 'ResumeGroup';
            break;
        case TempLocation:
            entityClassName = 'TempLocation';
            break;
        case UploadFileInfo:
            entityClassName = 'UploadFileInfo';
            break;
        case WorkArea:
            entityClassName = 'WorkArea';
            break;
        case Reference:
            entityClassName = 'Reference';
            break;
        case DutyNotice:
            entityClassName = 'DutyNotice';
            break;
        default:
            throw new Error(`No such EntityClass: ${EntityClass.name}`);
    }
    return entityClassName;
}

export const camelCaseToSnakeCase = (_string: string) => {
    const result = _string.replace(/([A-Z]+)/g, token => `_${token.toLowerCase()}`)

    return result[0].startsWith('_') ? result.slice(1) : result;
}

/**
 * @property {number} take 요청 갯수
 * @property {number} count 요청한 데이터들의 총 갯수
 */
export const getPages = (take: number, count: number) => {
    return Math.ceil(count / take);
}

export const toYYYYMMDD = (target: Date, joiner = '-') => {
    const YYYY = target.getFullYear();

    const _MM = target.getMonth() + 1;
    const MM = _MM < 10 ? `0${_MM}` : _MM;

    const _DD = target.getDate();
    const DD = _DD < 10 ? `0${_DD}` : _DD;

    return [YYYY, MM, DD].join(joiner);
}

export const YYYYMMDDHHmm = (targetDateOrString: string | Date, joiner = '-') => {

    const target = new Date(targetDateOrString);

    const YYYY = target.getFullYear();

    const _MM = target.getMonth() + 1;
    const MM = _MM < 10 ? `0${_MM}` : _MM;

    const _DD = target.getDate();
    const DD = _DD < 10 ? `0${_DD}` : _DD;

    const _HH = target.getHours();
    const HH = _HH < 10 ? `0${_HH}` : _HH;

    const _mm = target.getMinutes()
    const mm = _mm < 10 ? `0${_mm}` : _mm;

    const yyyymmdd = [YYYY, MM, DD].join(joiner);
    return yyyymmdd + ' ' + [ HH, mm ].join(':')
}

export const parseValueToSqlFormat = (value: any) => {
    if (value === undefined || value === null) {
        return null;
    } else if (typeof value === typeof true) {
        return value ? 1 : 0;
    } else if (value instanceof Date || (typeof value === typeof '' && value.match(/\d{4}-\d{2}-\d{2}T/g))) {
        return `'${ sqlDateFormat(new Date(value)) }'`;
    } else if (typeof value === typeof '') {
        return `'${ value.replaceAll(/'/g, '\\\'') }'`;
    } else {
        return `'${ value }'`;
    }
}

export const sqlDateFormat = (target: Date) => {
    const YYYY = target.getFullYear();

    const _MM = target.getMonth() + 1;
    const MM = _MM < 10 ? `0${_MM}` : _MM;

    const _DD = target.getDate();
    const DD = _DD < 10 ? `0${_DD}` : _DD;

    const _HH = target.getHours();
    const HH = _HH < 10 ? `0${_HH}` : _HH;

    const _mm = target.getMinutes();
    const mm = _mm < 10 ? `0${_mm}` : _mm;

    const _ss = target.getSeconds();
    const ss = _ss < 10 ? `0${_ss}` : _ss;

    return [YYYY, MM, DD].join('-') + ' ' + [ HH, mm, ss].join(':');
}

export const convertEnglishToKoreanForExcelExport = (englishProperty: keyof LectureStudent) => {

    let korean = '';

    switch (englishProperty) {
        case 'name':
            korean = '수강자명';
            break;
        case 'startDate':
            korean = '수강 시작일';
            break;
        case 'completeDate':
            korean = '수강 완료일';
            break;
        case 'phoneNumber':
            korean = '연락처';
            break;
        case 'title':
            korean = '제목';
            break;
        case 'lectureName':
            korean = '강의명';
            break;
        case 'clinicName':
            korean = '교육기관명';
            break;
        default:
            throw new Error('알려지지 않는 필드입니다 :' + englishProperty);
    }

    return korean;
}


globalThis.fetchDetailEntity = fetchDetailEntity;
globalThis.query = query;
globalThis.queryAndCount = queryAndPages;
globalThis.queryAndCountWithClass = queryAndCountWithClass;
globalThis.queryObject = queryObject;
globalThis.getPages = getPages;
globalThis.camelCaseToSnakeCaseForClassName = camelCaseToSnakeCaseForClassName;
