import {
  Column,
  Entity,
  Index,
  ManyToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { Resume } from "./Resume";
import { JobOpening } from "./JobOpening";

@Index("idx_occupation_occupation", ["occupation"], { unique: true })
@Entity("occupation", { schema: "dentin" })
export class Occupation {
  @PrimaryGeneratedColumn({
    type: "int",
    name: "occupation_id",
    comment: "직종 ID",
  })
  occupationId: number;

  @Column("varchar", {
    name: "occupation",
    unique: true,
    comment: "직종",
    length: 64,
  })
  occupation: string;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @ManyToMany(() => Resume, (resume) => resume.occupations)
  resumes: Resume[];

  @ManyToMany(() => JobOpening, (jobOpening) => jobOpening.occupations)
  jobOpenings: JobOpening[];
}
