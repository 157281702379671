import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Lecture } from "../../entities/entities/Lecture";
import { EntityService } from "../../services/entity.service";
import Popup from "../Popup";

const entityService = new EntityService();
const dentinUrl = {
  "dentin-class": "dental-in",
  "oralhealth-class": "oral-health",
  "etc-class": "etc",
};

const DentinClassDetailTable = ({ setWritePopup }) => {
  const [dentInClassList, setDentInClassList] = useState<any>(null);
  const [deletePopup, setDeletePopup] = useState<
    (Lecture & { students_count: number }) | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();
  const courseId = Number(useParams().id) || 1;
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const lectureUrl = Object.keys(dentinUrl).find((key) =>
    location.pathname.includes(key)
  );
  const url = lectureUrl && dentinUrl[lectureUrl];

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    param.set("page", selected);
    navigate(`/board/dentin-class/${courseId}?${param}`);
    window.scrollTo(0, 0);
  };

  const lectureSecondsToStr = (sec) => {
    const hour = Math.trunc(sec / 3600) % 60;
    const minute = Math.trunc(sec / 60) % 60;
    const seconds = sec % 60;

    if (hour >= 1) {
      return `${hour}시간 ${minute}분`;
    }

    return `${minute}분 ${seconds}초`;
  };

  useEffect(() => {
    (async () => {
      const res = await entityService.lecturesInCourse(courseId, page, 20);
      setDentInClassList(res);
    })();
  }, [courseId, page]);

  if (!dentInClassList) return <></>;
  return (
    <div className="table">
      <div className="row header">
        <div className="w-1/12 justify-center">노출순서</div>
        <div className="w-2/12 justify-center">제작자</div>
        <div className="w-4/12">제목</div>
        <div className="w-1/12 justify-center">재생시간</div>
        <div className="w-1/12 justify-center">수강자 수</div>
        <div className="w-2/12 justify-center">수강자 목록</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {dentInClassList.map((item) => (
        <div className="row">
          <div className="w-1/12 justify-center">{(item as any).seq}</div>
          <div className="w-2/12 justify-center">{(item as any).creator}</div>
          <div className="w-4/12">
            <a
              href={`https://dent-in.co.kr/lecture/${url}/${item.course_id}/${item.lecture_id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer hover:text-main"
            >
              {(item as any).name}
            </a>
          </div>
          <div className="w-1/12 justify-center">
            {lectureSecondsToStr((item as any).play_time)}
          </div>
          <div className="w-1/12 justify-center">
            {(item as any).students_count}명
          </div>
          <div className="w-2/12 justify-center">
            <i
              className="ii ii-import text-main text-lg cursor-pointer"
              onClick={async () => {
                const list = await entityService.fetchLecturesStudentList(
                  parseInt((item as any).lecture_id)
                );

                await entityService.downloadJsonArrayToCSV(item.title, list);
              }}
            ></i>
          </div>
          <div className="w-1/12 justify-center">
            <button className="dark" onClick={() => setWritePopup(item)}>
              수정
            </button>
          </div>
          <div className="w-1/12 justify-center">
            {" "}
            <button
              onClick={() => {
                setDeletePopup(item);
              }}
            >
              삭제
            </button>
          </div>
        </div>
      ))}

      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={dentInClassList[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
      {deletePopup && (
        <Popup
          submit={async () => {
            try {
              await entityService.deleteLecture(
                (deletePopup as any).lecture_id
              );
              alert("삭제되었습니다");
              setDeletePopup(null);
            } catch {
              alert("에러가 발생하였습니다");
            }
          }}
          close={() => setDeletePopup(null)}
        >
          <div className="flex justify-center font-semibold">
            삭제하시겠습니까?
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DentinClassDetailTable;
