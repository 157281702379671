import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  EntityService, MemberStatsByOccupation,
  VisitorStatistics,
} from "../../services/entity.service";
import { toYYYYMMDD } from "../../services/utils/repository-util.service";
import { DateRangePicker } from "react-date-range";
import ko from "date-fns/locale/ko";
interface IMember {
  count:number;
  occupation:string;
}
const VisitPage = () => {
  const entityService = new EntityService();
  const [memberStats, setMemberStats] = useState<any>(null);
  const [analysisStats, setAnalysisStats] = useState<any>(null);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [date, setDate] = useState<any>([
    {
      startDate: dayjs(new Date()).subtract(7, "day").toDate(),
      endDate: dayjs(new Date()).subtract(1, "day").toDate(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState(1);
  const dataInfo = [
    { name: "회원현황", unit: "명", value: "" },
    { name: "회원정보 분석", unit: "%", value: "" },
    { name: "법정의무교육 이수율", unit: "회", value: "" },
  ];
  // const data = visitorStats?.reverse().map((v) => ({
  //   name: toYYYYMMDD(v.createdAt),
  //   uv: v.uniqueVisitors,
  //   pv: v.revisitRatio,
  //   amt: Math.floor(v.uniqueVisitors / v.memberCountTotal),
  // }));

  const COLORS = ["#fe00e0", "#0003c4"];

  useEffect(() => {
    (async () => {
      let res = await entityService.fetchMemberStats(date[0].endDate);
      let memberArr:any = res?.memberStatsByOccupation
      let sliceArr = memberArr.slice(0,8)
      let commonMember = 0
      let clinicMember = res.clinicMemberCount
      sliceArr.forEach((obj:any)=>{
        commonMember += obj.count
      })

      sliceArr.push({'id':0,'occupation':'개인회원','count':commonMember})
      sliceArr.push({'id':0,'occupation':'병원회원','count':clinicMember})

      res.memberStatsByOccupation = sliceArr

      setMemberStats(res);
      console.log(res.memberStatsByOccupation);

    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchAnalysisForMembers(
        date[0].startDate,
        date[0].endDate,
      );
      setAnalysisStats(res);
    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchDutyEducationFinishRatioByCourse(
        date[0].startDate,
        date[0].endDate
      );
      // setMemberStats(res);
    })();
  }, []);

  return (
    <div className="inner page">
      <div className="flex items-end justify-between">
        <div className="page-tit">방문분석</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowCalendar(true);
          }}
        >
          <div className="flex items-center space-x-4 text-[#333] font-medium text-[25px]">
            <div className="w-[350px] text-center">
              {dayjs(date[0].startDate).format("YYYY-MM-DD")} ~{" "}
              {dayjs(date[0].endDate).format("YYYY-MM-DD")}
            </div>
            <i className="ii-calendar-event-outline"></i>
          </div>
        </div>
      </div>
      {showCalendar && (
        <>
          <div className="overlay" onClick={() => setShowCalendar(false)}></div>
          <div className="calendar">
            <DateRangePicker
              className={"calendar"}
              locale={ko}
              months={1}
              ranges={date}
              onChange={(item) => setDate([item.selection])}
              maxDate={dayjs(new Date()).subtract(1, "day").toDate()}
              dateDisplayFormat={"yyyy-MM-dd"}
            />
          </div>
        </>
      )}

      <div className="flex justify-between bg-[#EFEFEF] border-t-4 border-[#333333] mt-4">
        {dataInfo.map((item, idx) => {
          return (
            <div
              className="w-[400px] text-center p-3 border-r-2 cursor-pointer"
              onClick={() => setType(idx)}
              style={type === idx ? { background: "#fff" } : {}}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <div className="p-6 color-[#333333] text-2xl font-medium"></div>
      {type === 0 && (
        <>
          <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
            신규 가입자
          </div>
          <LineChart
            width={1200}
            height={250}
            data={analysisStats.reverse()}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="newbieNumbers" stroke="#8884d8" />
          </LineChart>
        </>
      )}
      {type === 1 && (
        <>
          <div className="color-[#333333] text-2xl font-medium">
            {dayjs(date[0].endDate).format("YYYY-MM-DD")} 기준
          </div>
          <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
            등급별 회원 수
          </div>
          <BarChart
            width={1200}
            height={250}
            data={memberStats?.memberStatsByOccupation.map((m) => ({
              name: m.occupation,
              count: m.count,
            }))}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
          <div className="flex">
            <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
              연령대별
            </div>
            <BarChart
              width={600}
              height={250}
              data={memberStats?.memberStatsByAge.slice(0,5).map((m) => ({
                name: `${m.age}대`,
                count: m.count,
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
            <div className="p-6 mt-2 color-[#333333] text-xl font-medium">
              성별
            </div>
            <PieChart width={600} height={250}>
              <Pie
                data={memberStats?.memberStatsByGender.slice(0, 2).map((m) => ({
                  name: m.gender,
                  count: m.count,
                }))}
                dataKey="count"
                cx={200}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                label
              >
                <Cell key={`cell-0`} fill={COLORS[0 % COLORS.length]} />
                <Cell key={`cell-1`} fill={COLORS[1 % COLORS.length]} />
              </Pie>
            </PieChart>
          </div>
        </>
      )}
    </div>
  );
};

export default VisitPage;
