import { Column, Entity, Index, OneToMany } from "typeorm";
import { ClinicEmployee } from "./ClinicEmployee";
import { ClinicMemberWish } from "./ClinicMemberWish";
import { AdminChatMessage } from "./AdminChatMessage";
import { ClinicMemberPolicyAgree } from "./ClinicMemberPolicyAgree";
import { JobOpening } from "./JobOpening";

@Index("idx_clinic_member_1", ["bizRegNumber"], { unique: true })
@Index("idx_clinic_member_email", ["email"], { unique: true })
@Index("idx_clinic_member_composite_1", ["city", "district"], {})
@Index("idx_clinic_member_region", ["region"], {})
@Index("idx_clinic_member_location", ["location"], {})
@Index("idx_clinic_member_clinic_type", ["clinicType"], {})
@Index("idx_clinic_member_reg_date", ["regDate"], {})
@Index("idx_clinic_member_last_login_date", ["lastLoginDate"], {})
@Entity("clinic_member", { schema: "dentin" })
export class ClinicMember {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("varchar", {
    name: "password",
    comment: "병원 회원 비밀번호",
    length: 128,
  })
  password: string;

  @Column("varchar", {
    name: "biz_reg_number",
    unique: true,
    comment: "사업자 등록 번호",
    length: 64,
  })
  bizRegNumber: string;

  @Column("varchar", {
    name: "clinic_type",
    comment: "병원 유형 (치과의원, 치과병원, 종합병원, 치과/기공소, 기타)",
    length: 32,
  })
  clinicType: string;

  @Column("varchar", { name: "clinic_name", comment: "병원명", length: 64 })
  clinicName: string;

  @Column("varchar", { name: "rep_name", comment: "대표자명", length: 64 })
  repName: string;

  @Column("varchar", {
    name: "rep_phone_number",
    nullable: true,
    comment: "대표자 연락번호",
    length: 32,
  })
  repPhoneNumber: string | null;

  @Column("varchar", {
    name: "rep_licence_number",
    nullable: true,
    comment: "대표자 면허 번호",
    length: 32,
  })
  repLicenceNumber: string | null;

  @Column("varchar", {
    name: "rep_gender",
    nullable: true,
    comment: "대표자 성별",
    length: 8,
    default: () => "'male'",
  })
  repGender: string | null;

  @Column("varchar", {
    name: "email",
    unique: true,
    comment: "병원 회원 email",
    length: 64,
  })
  email: string;

  @Column("varchar", { name: "city", comment: "도시명", length: 64 })
  city: string;

  @Column("varchar", { name: "district", comment: "지역(구)명", length: 64 })
  district: string;

  @Column("varchar", {
    name: "region",
    comment: "지역(도시+지역구) - 검색시 사용",
    length: 128,
  })
  region: string;

  @Column("varchar", { name: "address", comment: "병원 주소", length: 256 })
  address: string;

  @Column("varchar", {
    name: "extra_address",
    nullable: true,
    comment: "추가 주소",
    length: 256,
  })
  extraAddress: string | null;

  @Column("varchar", {
    name: "phone_number",
    nullable: true,
    comment: "병원 연락처",
    length: 32,
  })
  phoneNumber: string | null;

  @Column("varchar", {
    name: "homepage_url",
    nullable: true,
    comment: "병원 홈페이지 URL",
    length: 256,
  })
  homepageUrl: string | null;

  @Column("point", { name: "location", comment: "병원 위치 정보" })
  location: string;

  @Column("bit", {
    name: "active_flag",
    comment: "활성 여부",
    default: () => "'b'1''",
  })
  activeFlag: boolean;

  @Column("varchar", {
    name: "auth_code",
    nullable: true,
    comment: "인증코드",
    length: 32,
  })
  authCode: string | null;

  @Column("datetime", {
    name: "auth_expire_date",
    nullable: true,
    comment: "인증 코드 만료 시간",
  })
  authExpireDate: Date | null;

  @Column("varchar", { name: "fcm_token", nullable: true, length: 256 })
  fcmToken: string | null;

  @Column("datetime", {
    name: "last_login_date",
    nullable: true,
    comment: "병원 회원 마지막 로그인 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  lastLoginDate: Date | null;

  @Column("datetime", {
    name: "last_logout_date",
    nullable: true,
    comment: "병원 회원 마지막 로그아웃 일시",
    default: () => "CURRENT_TIMESTAMP",
  })
  lastLogoutDate: Date | null;

  @Column("tinyint", {
    name: "login_fail_cnt",
    comment: "로그인 실패 횟수",
    default: () => "'0'",
  })
  loginFailCnt: number;

  @Column("datetime", {
    name: "login_block_date",
    nullable: true,
    comment: "로그인 블록 일시",
  })
  loginBlockDate: Date | null;

  @Column("varchar", {
    name: "refresh_token",
    nullable: true,
    comment: "refresh token 정보",
    length: 8192,
  })
  refreshToken: string | null;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", {
    name: "created_at",
    nullable: true,
    comment: "생성일",
    default: () => "CURRENT_TIMESTAMP",
  })
  createdAt: Date | null;

  @Column("char", {
    name: "last_login_device",
    nullable: true,
    comment: "login device",
    length: 1,
  })
  lastLoginDevice: string | null;

  @Column("datetime", {
    name: "upd_date",
    nullable: true,
    comment: "수정 일시",
  })
  updDate: Date | null;

  @Column("int", {
    name: "privacy_dormancy_year",
    comment: "휴면 기간",
    default: () => "'00000000003'",
  })
  privacyDormancyYear: number;

  @Column("bit", {
    name: "is_dormancy",
    comment: "휴면 유무",
    default: () => "'b'0''",
  })
  isDormancy: boolean;

  @Column("varchar", {
    name: "event_follow_id",
    nullable: true,
    comment: "추천인 ID",
    length: 32,
  })
  eventFollowId: string | null;

  @OneToMany(() => ClinicEmployee, (clinicEmployee) => clinicEmployee.member)
  clinicEmployees: ClinicEmployee[];

  @OneToMany(
    () => ClinicMemberWish,
    (clinicMemberWish) => clinicMemberWish.member
  )
  clinicMemberWishes: ClinicMemberWish[];

  @OneToMany(
    () => AdminChatMessage,
    (adminChatMessage) => adminChatMessage.clinicMember
  )
  adminChatMessages: AdminChatMessage[];

  @OneToMany(
    () => ClinicMemberPolicyAgree,
    (clinicMemberPolicyAgree) => clinicMemberPolicyAgree.member
  )
  clinicMemberPolicyAgrees: ClinicMemberPolicyAgree[];

  @OneToMany(() => JobOpening, (jobOpening) => jobOpening.member)
  jobOpenings: JobOpening[];
}
