import axios from "axios";
import { Admin } from "../entities/entities/Admin";
import { AdminChatMessage } from "../entities/entities/AdminChatMessage";
import { Banner } from "../entities/entities/Banner";
import { ClinicMember } from "../entities/entities/ClinicMember";
import { CommonCode } from "../entities/entities/CommonCode";
import { CommonCodeSecondary } from "../entities/entities/CommonCodeSecondary";
import { EducationFaq } from "../entities/entities/EducationFaq";
import { Faq } from "../entities/entities/Faq";
import { JobOpening } from "../entities/entities/JobOpening";
import { Lecture } from "../entities/entities/Lecture";
import { LectureCourse } from "../entities/entities/LectureCourse";
import { LectureTake } from "../entities/entities/LectureTake";
import { Member } from "../entities/entities/Member";
import { Notice } from "../entities/entities/Notice";
import { Occupation } from "../entities/entities/Occupation";
import { Policy } from "../entities/entities/Policy";
import { Reference } from "../entities/entities/Reference";
import { Resume } from "../entities/entities/Resume";
import { LandingPageData } from "../interfaces/landing-page-data.interface";
import { Rank, Rankers } from "../interfaces/rankers.interfaces";
import {
  convertEnglishToKoreanForExcelExport,
  createEntity,
  deleteEntity,
  deleteEntityList,
  queryAndCountWithClass,
  toYYYYMMDD,
  updateEntity,
  parseValueToSqlFormat,
  YYYYMMDDHHmm
} from "./utils/repository-util.service";
import { Parser } from 'json2csv';
import { PolicyCategoryType } from "./interfaces/policy-category-type.enum";
import { EducationNotice } from "../entities/entities/EducationNotice";
import { DutyNotice } from "../entities/entities/DutyNotice";
import { getHost } from "..";
import {start} from "repl";
import {Developer} from "../entities/entities/Developer";
import {ServiceKey} from "../entities/entities/ServiceKey";

export enum PolicyType {
  /**
   * 1 이용약관
   */
  USAGE_AGREEMENT = 1,

  /**
   * 2 개인정보 수집 및 이용 동의
   */
  PRIVACY_USAGE_AGREEMENT = 2,

  /**
   * 3 개인정보처리방침
   */
  PRIVACY_INFO_COLLECTION_AGREEMENT = 3
}

export interface TodayVisitor {
  total: number;
  revisitRatio: number;
}

export interface MemberCountings {
  newbieNumbers: number;
  date: Date;
}

export interface LectureFinishRatio {
  lectureId: number;
  lectureName: string;
  ratio: number;
}


export interface MemberStatsByOccupation {
  id: number;
  occupation: string;
  count: number
}
export interface MemberStatsByAging {
  id: number;
  age: string;
  count: number
}
export interface MemberStatsByRegion {
  id: number;
  region: string;
  ratio: number;
  count: number
}

export interface MemberStatsByGender {
  id: number;
  gender: string;
  ratio: number;
  count: number
}

export interface VisitorStatistics {
  id: number;
  humanResources: number;
  interns: number;
  memberCountTotal: number;
  newbieHospitalCount: number;
  newbiewCount: number;
  recruits: number;
  revisitRatio: number;
  suspended: number;
  uniqueVisitors: number;
  videos: number;
  withdrawCount: number;
  createdAt: Date;
}

export interface JobOpeningStatics {
  id: number;
  openCnt: number;
  hideCnt: number;
  createdAt: number
}

export interface RecruitStatics {
  id: number;
  openCnt: number;
  hideCnt: number;
  createdAt: number
}
export type AdminChatMessageWithName = AdminChatMessage & { name: string };


/**
 * Member.name 수강자명
 * Member.phoneNumber 연락처
 * LectureCourse.title / LectureCourse.name 교육명
 * ClinicMember.clinicName 교육기관명
 * LectureTake.completeDate 수강일시 (완료날짜)
 */
export type LectureStudent = Pick<Member, "name" | "phoneNumber"> &
  Pick<LectureCourse, "title"> & { lectureName: Lecture["name"] } & Pick<
    ClinicMember,
    "clinicName"
  > &
  Pick<LectureTake, "startDate" | "completeDate">;

export class EntityService {
  instance: EntityService;

  constructor() {
    if (!this.instance) {
      this.instance = this;
    }

    return this.instance;
  }

  async test(): Promise<any> {
    const result = await this.fetchRanks()
    console.log(result);
  }

  async create<T>(EntityClass: new () => T, newEntity: Partial<T>): Promise<number> {
    return createEntity(EntityClass, newEntity);
  }

  fetchDetail<T>(
    EntityClass: new () => T,
    conditionEntity: Partial<T>
  ): Promise<T> {
    return fetchDetailEntity<T>(EntityClass, conditionEntity);
  }

  async update<T>(
    EntityClass: new () => T,
    entityId: string | number,
    partial: Partial<T>
  ): Promise<boolean> {
    partial = this.removeAdditionalFields(partial);

    const result = await updateEntity<T>(EntityClass, entityId, partial);
    return result;
  }
  async callProcedure(id, partial): Promise<boolean> {
    let statement = `
    CALL UpdateLecture(
      ${id},
      ${partial.courseId},
      ${partial.seq},
      '${partial.name}',
      '${partial.creator}',
      '${partial.description}',
      '${partial.videoId}',
      ${partial.playTime},
      '${partial.materialDownloadLink}',
      '${partial.materialDownloadFilename}',
      '${partial.imageFilename || ''}',
      '${partial.provisions}',
      '${partial.visibleFlag}',
      '${partial.userId}'
    );`
    return await query(statement);
  }
  removeAdditionalFields<T>(partial: Partial<T>): Partial<T> {
    delete (partial as any).finish_count;
    delete (partial as any).finishCount;
    delete (partial as any).applicationCount;
    delete (partial as any).application_count;
    delete (partial as any).offerCount;
    delete (partial as any).offer_count;
    delete (partial as any).studentsCount;
    delete (partial as any).students_count;

    return partial;
  }

  async updatePassword(
    adminId: string,
    newPlainPassword: string
  ): Promise<boolean> {
    await axios.put(
      `${getHost()}/admin/password`,
      {
        member_id: adminId,
        new_password: newPlainPassword,
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return true;
  }

  // usage: await this.deleteMultiples(ChatMessage, 'chatId', [ 330, 331 ])
  // usage: await this.deleteMultiples(Member, 'member_id', [ 'memberid1', 'memberid2' ])
  async deleteMultiples<T>(
    EntityClass: new () => T,
    targetProperty: keyof T,
    values: (string | number)[]
  ): Promise<boolean> {
    const result = await deleteEntityList<T>(
      EntityClass,
      targetProperty,
      values
    );
    return result;
  }
  async delete<T>(
    EntityClass: new () => T,
    entityId: string | number
  ): Promise<boolean> {
    const result = await deleteEntity<T>(EntityClass, entityId);
    return result;
  }

  async deleteJobOpening(jobId: string): Promise<boolean> {
    await query("DELETE FROM job_opening_occupation WHERE job_id = " + jobId);
    await query("DELETE FROM member_wish WHERE job_id = " + jobId);
    await query("DELETE FROM job_action WHERE job_id = " + jobId);
    await query("DELETE FROM job_opening WHERE job_id = " + jobId);

    return true;
  }

  async deleteResume(resumeId: number): Promise<boolean> {
    await query("DELETE FROM resume_occupation WHERE resume_id = " + resumeId);
    await query("DELETE FROM clinic_member_wish WHERE resume_id = " + resumeId);
    await query("DELETE FROM job_action WHERE resume_id = " + resumeId);
    await query("DELETE FROM resume WHERE resume_id = " + resumeId);

    return true;
  }

  /**
   * 홈
   */
  async fetchLandingPageDataByDate(date = new Date()): Promise<LandingPageData> {

    const oneDayAgo = new Date(date);
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);

    const sqlString = `SELECT * FROM visitor_statistics WHERE created_at BETWEEN "${toYYYYMMDD(date)}" AND "${toYYYYMMDD(date)}" + INTERVAL 1 DAY ORDER BY created_at DESC LIMIT 1;`;
    const [stats] = await queryObject(sqlString, 0, true) as VisitorStatistics[];

    const todayVisitor: TodayVisitor = await this._fetchVisitors(date);

    const allWithdrewMemberCount = stats?.withdrawCount
    const allSuspendedMemberCount = stats?.suspended;
    const totalBoardCount = stats ? (stats.recruits + stats.interns + stats.humanResources + stats.videos) : 0;

    const [jobOpenings] = await this.fetchRecruits(1, 5);
    const [resumes] = await this.fetchResumeList(1, 5);
    const [internRecruits] = await queryAndCountWithClass(
      JobOpening,
      1,
      5,
      (tableName) =>
        `WHERE ${tableName}.intern_flag = 1 ORDER BY ${tableName}.job_id DESC`
    );

    const latestCourses = await this.fecthLatestLectureCourses(1, 5);

    const [qnaChatMessages] = await this._fetchMessageGroup();

    return {
      todayVisitor,
      todayBoardCount: {
        total: totalBoardCount,
        recruits: stats?.recruits || 0,
        humanResources: stats?.humanResources || 0,
        videos: stats?.videos || 0,
        interns: stats?.interns || 0,
      },
      todayMemberCount: {
        total: stats?.memberCountTotal || 0,
        newbieHospitalCount: stats?.newbieHospitalCount || 0,
        newbiewCount: stats?.newbiewCount || 0,
        withdrawCount: allWithdrewMemberCount,
        suspended: allSuspendedMemberCount,
      },
      // 조회수는 view_count 사용하시면 됩니다.
      recruits: jobOpenings,
      humanResources: resumes,
      internRecruits: internRecruits,
      latestVideoList: latestCourses,
      chatMessages: qnaChatMessages,
    } as LandingPageData;
  }

  /**
   * 홈 > 오늘 순 방문자 수
   * 통계관리 > 일간현황 > 오늘 순 방문자 수
   * 통계관리 > 방문분석 > 순 방문자수
   * 통계관리 > 방문분석 > 재방문율
   */
  async _fetchVisitors(targetDate = new Date()): Promise<TodayVisitor> {

    const oneDayAgo = new Date(targetDate);
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);

    const sqlString = `SELECT * FROM visitor_statistics WHERE created_at BETWEEN "${toYYYYMMDD(targetDate)}" AND "${toYYYYMMDD(targetDate)}" + INTERVAL 1 DAY ORDER BY created_at DESC LIMIT 1;`;
    const [stats] = await queryObject(sqlString, 0, true) as VisitorStatistics[];

    return {
      revisitRatio: stats?.revisitRatio,
      total: stats?.uniqueVisitors
    }
  }

  /**
   * 통계관리 > 회원분석 > 개인 회원 분석
   */
  async fetchMemberStats(targetDate = new Date()): Promise<{
    memberStatsByOccupation: MemberStatsByOccupation;
    memberStatsByAge: MemberStatsByAging;
    memberStatsByRegion: MemberStatsByRegion;
    memberStatsByGender: MemberStatsByGender;
    clinicMemberCount: number;
  }> {

    const _defaultSuffix = `WHERE created_at BETWEEN "${toYYYYMMDD(targetDate)}" AND "${toYYYYMMDD(targetDate)}" + INTERVAL 1 DAY`;
    const suffixCondition = `${_defaultSuffix} ORDER BY created_at DESC;`;
    const memberStatsByOccupationQuery = `SELECT * FROM stats_member_by_occupations ${suffixCondition}`;
    const memberStatsByOccupation = await queryObject(memberStatsByOccupationQuery, 0, true) as MemberStatsByOccupation

    const memberStatsByAgeQuery = `SELECT * FROM stats_member_by_aging ${suffixCondition}`;
    const memberStatsByAge = await queryObject(memberStatsByAgeQuery, 0, true) as MemberStatsByAging

    const memberStatsByRegionQuery = `SELECT * FROM stats_member_by_region ${suffixCondition}`;
    const memberStatsByRegion = await queryObject(memberStatsByRegionQuery, 0, true) as MemberStatsByRegion

    const memberStatsByGenderQuery = `SELECT * FROM stats_member_by_gender ${_defaultSuffix} GROUP BY created_at, gender ORDER BY created_at DESC;`;
    const memberStatsByGender = await queryObject(memberStatsByGenderQuery, 0, true) as MemberStatsByGender

    const clinicMeberStatsQuery = `SELECT count(*) as count FROM clinic_member`;
    const [clinicMemberCount] = await queryObject(clinicMeberStatsQuery, 0, true) as { count: number }[];

    return {
      memberStatsByOccupation,
      memberStatsByAge,
      memberStatsByRegion,
      memberStatsByGender,
      clinicMemberCount: clinicMemberCount.count
    };
  }
  async fetchPostingStats(targetDate = new Date()): Promise<{
    jbOpneningStats: JobOpeningStatics;
    rcOpneningStats: RecruitStatics;
  }> {
    const _defaultSuffix = `WHERE created_at BETWEEN "${toYYYYMMDD(targetDate)}" AND "${toYYYYMMDD(targetDate)}" + INTERVAL 1 DAY`;
    const suffixCondition = `${_defaultSuffix} ORDER BY created_at DESC;`;
    const jbOpneningQuery = `SELECT * FROM stats_jobopening_by_state ${suffixCondition}`;
    const rcOpneningQuery = `SELECT * FROM stats_resume_by_state ${suffixCondition}`;

    const jbOpneningStats = await queryObject(jbOpneningQuery, 0, true) as JobOpeningStatics
    const rcOpneningStats = await queryObject(rcOpneningQuery, 0, true) as RecruitStatics

    return {
      jbOpneningStats,
      rcOpneningStats
    };
  }
  async _fetchVisitorsByDate(startDate: Date, endDate: Date): Promise<VisitorStatistics[]> {

    const sqlString = `SELECT * FROM visitor_statistics WHERE created_at BETWEEN "${toYYYYMMDD(startDate)}" AND "${toYYYYMMDD(endDate)}" + INTERVAL 1 DAY ORDER BY created_at DESC;`;
    const stats = await queryObject(sqlString, 0, true) as VisitorStatistics[];

    return stats.map(stat => ({
      ...stat,
      createdAt: new Date(stat.createdAt)
    }));
  }

  /**
   * 홈 > 최신 동영상
   */
  async fecthLatestLectureCourses(
    page = 1,
    take = 5
  ): Promise<Array<LectureCourse & { finishCount: number }>> {
    const _page = page - 1;
    const skip = _page * take;

    const courses =
      (await queryObject(`SELECT lc.*, COUNT(m.member_id) AS 'finish_count'
      FROM lecture_course lc
      LEFT JOIN lecture l ON l.course_id = lc.course_id
      LEFT JOIN lecture_take lt ON lt.lecture_id = l.lecture_id
      LEFT JOIN member m ON m.member_id = lt.member_id
      GROUP BY lc.course_id
      LIMIT ${take}
      OFFSET ${skip};`)) as Array<LectureCourse & { finishCount: number }>;

    return courses;
  }

  /**
   * 기본정보 > 관리자 계정 > 관리자 계정 목록
   */
  async fetchAdminUsers(page = 1, take = 20): Promise<[Admin[], number]> {
    const result = await queryAndCountWithClass(Admin, page, take);

    return result;
  }

  /**
   * 기본정보 > 개발자 계정 > 개발자 계정 목록
   */
  async fetchDeveloperUsers(page = 1, take = 20): Promise<[Developer[], number]> {
    const result = await queryAndCountWithClass(Developer, page, take);

    return result;
  }

  /**
   * 기본정보 > 개발자 계정 > 개발자 계정 목록 > 인증키 발급 현황
   */
  async fetchServiceKeyUsers(
      page = 1,
      take = 20,
      devId: string
  ): Promise<[ServiceKey[], number]> {
    const result = await queryAndCountWithClass(
        ServiceKey,
        page,
        take,
        (tableName) => `WHERE ${tableName}.dev_id = '${devId}'`
    );
    return result;
  }

  /**
   * 기본정보 > 이용약관
   * policy.type = 정책 유형
   * 값이 1 이면 이용약관, 2 면 개인정보 수집 및 이용 동의, 3 이면 개인정보처리방침
   */
  async fetchPolicies(
    page = 1,
    take = 20,
    type: PolicyType = PolicyType.USAGE_AGREEMENT,
    policyCategory: PolicyCategoryType = PolicyCategoryType.NORMAL
  ): Promise<[Policy[], number]> {
    const policies = await queryAndCountWithClass(
      Policy,
      page,
      take,
      (tableName) => `WHERE ${tableName}.type = ${type} AND ${tableName}.category = ${policyCategory}`
    );
    return policies;
  }

  async createNewPolicy(newPolicyBody: Partial<Policy>) {
    const { title, type, category } = newPolicyBody;

    const assignedPolicyId = await this.create(Policy, newPolicyBody);

    // 이전 revision 들에 대해 require flag 를 모두 false 로 설정한다. 기준은 title.
    // const requireFlagFalseSql = `UPDATE policy SET policy.require_flag = 0 WHERE policy.type = ${type} AND policy.category = ${category} AND policy.title = '${title}'`;
    // await query(requireFlagFalseSql, 0, true);

    // update revision
    const policyQuery = `UPDATE policy SET policy.require_flag = ${parseValueToSqlFormat(newPolicyBody.requireFlag)}, policy.revision = (SELECT COUNT(*) FROM policy WHERE policy.title = '${title}') WHERE policy.type = ${type} AND policy.category = ${category} AND policy.policy_id = ${assignedPolicyId}`;
    await query(policyQuery, 0, true);
  }

  async enableRequireFlagForLastPolicy(title: string, type: number, category: number): Promise<void> {

    const requireFlagFalseSql = `UPDATE policy SET policy.require_flag = 0 WHERE policy.type = ${type} AND policy.category = ${category} AND policy.title = '${title}'`;
    await query(requireFlagFalseSql, 0, true);

    const lastPolicyQuery = `SELECT *
    FROM policy p
    WHERE p.title = '${title}'
    ORDER BY p.policy_id DESC
    LIMIT 1`;
    const [lastOne] = await queryObject<Policy>(lastPolicyQuery, 0, true) as Policy[];
    lastOne.requireFlag = true;
    await updateEntity(Policy, lastOne.policyId, lastOne);
  }

  /**
   * @deprecated fetchPolicies 로 대체해서 쓰세요.
   */
  async fetchPrivateInfo(
    page = 1,
    take = 20,
    type: PolicyType = PolicyType.PRIVACY_USAGE_AGREEMENT,
    policyCategoryType = PolicyCategoryType.NORMAL
  ): Promise<[Policy[], number]> {
    return await this.fetchPolicies(page, take, type, policyCategoryType);
  }

  /**
   * 기본정보 > 개인정보수집동의 병원 회원목록
   */
  async fetchHospitalMemberListOfPolicyAgree(
    page = 1,
    take = 20
  ): Promise<[ClinicMember[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const privacyPolicyListQuery = (await queryObject(
      `SELECT * FROM policy p WHERE p.title LIKE '%개인정보 수집 및 이용 동의%' LIMIT ${take} OFFSET ${skip}`,
      1,
      true
    )) as Policy[];
    const policyIds = privacyPolicyListQuery.map((p) => p.policyId);

    const queryString = `SELECT * FROM clinic_member cm LEFT JOIN clinic_member_policy_agree cmp ON cmp.member_id = cm.member_id WHERE cmp.policy_id IN (${policyIds.join(
      ","
    )}) LIMIT ${take} OFFSET ${skip}`;
    const countSql = `SELECT COUNT(*) AS count FROM clinic_member cm LEFT JOIN clinic_member_policy_agree cmp ON cmp.member_id = cm.member_id WHERE cmp.policy_id IN (${policyIds.join(
      ","
    )}) LIMIT ${take} OFFSET ${skip}`;
    const list = (await queryObject(queryString, 1, true)) as ClinicMember[];
    const [{ count }] = await query(countSql);

    const lastPage = getPages(take, count);

    return [list, lastPage];
  }

  /**
   * 기본정보 > 개인정보수집동의 개인 회원목록
   */
  async fetchMemberListOfPolicyAgree(
    page = 1,
    take = 20
  ): Promise<[Member[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const privacyPolicyListQuery = (await queryObject(
      `SELECT * FROM policy p WHERE p.title LIKE '%개인정보 수집 및 이용 동의%' LIMIT ${take} OFFSET ${skip}`
    )) as Policy[];

    const policyIds = privacyPolicyListQuery.map((p) => p.policyId);

    const queryString = `SELECT * FROM member m LEFT JOIN member_policy_agree mpa ON mpa.member_id = m.member_id WHERE mpa.policy_id IN (${policyIds.join(
      ","
    )}) LIMIT ${take} OFFSET ${skip}`;
    const list = (await queryObject(queryString, 1, true)) as Member[];

    const countSql = `SELECT COUNT(*) AS count FROM member m LEFT JOIN member_policy_agree mpa ON mpa.member_id = m.member_id WHERE mpa.policy_id IN (${policyIds.join(
      ","
    )}) LIMIT ${take} OFFSET ${skip}`;
    const [{ count }] = await query(countSql);

    const lastPage = getPages(take, count);

    return [list, lastPage];
  }

  /**
   * 기본정보 > 환경설정
   * 프론트앤드에서 하드코딩하세요.
   */
  fetchEnvironment(): any {}

  /**
  * 소통관리 > Q&A 관리 > 실시간 Q&A
  *
  * isQna - QNA 인지 쪽지인지 여부
  * unread - 안 읽음
  * 실습치과찾기 > 실습치과지원센터 > 메세지 조회
  *
  * @example fetchInternChatMessages(1, 20}) // 1 페이지에서 20개 메시지 그룹 가져오기
  * @example fetchInternChatMessages(1, 20, { searchToken: '검색어' }) // 1 페이지에서 20개 메시지 그룹 가져오기
  * @example fetchInternChatMessages(1, 20, { unread: true }) // 1 페이지에서 20개의 안 읽은 메시지 그룹 가져오기
  * @example fetchInternChatMessages(1, 20, { unread: true, searchToken: '검색어' })
  * @example fetchInternChatMessages(1, 20, null, { isDetail: true, memberId: 'test02' }) // test02 회원에 대한 admin message 1 페이지에 / 20개 메시지를 가져온다.
  * @example fetchInternChatMessages(1, 20, { isIntern: true }, { isDetail: true, memberId: 'test02' }) // [실습치과 메시지] test02 회원에 대한 admin message 1 페이지에 / 20개 메시지를 가져온다.
  */
  async fetchQnaMessages(
    page = 1,
    take = 20,
    options: {
      unread?: boolean;
      searchToken?: string | null;
      isIntern?: boolean;
    } = {
      unread: false,
      searchToken: null,
      isIntern: false,
    },
    chatDetailOption: {
      isDetail?: boolean
      memberId?: string | null,
    } = {
      isDetail: false,
      memberId: null
    }
  ): Promise<[AdminChatMessageWithName[], number]> {

    const {
      unread,
      searchToken,
      isIntern
    } = options;
    const {
      isDetail,
      memberId
    } = chatDetailOption;

    const _page = page - 1;
    const skip = _page * take;

    const chatQuery = `SELECT cm.*, IFNULL(clm.clinic_name, m.name) AS name
    FROM admin_chat_message cm
    LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
    LEFT JOIN member m ON m.member_id = cm.member_id
    ${ isIntern ? 'LEFT JOIN job_opening jo ON jo.member_id = cm.member_id' : '' }
    WHERE
      cm.is_qna = 1
      ${ isIntern ? `AND jo.intern_flag = ${ parseValueToSqlFormat(isIntern) }` : ''}
      ${ unread ? `AND cm.read_flag = ${ parseValueToSqlFormat(unread) }` : '' }
      ${ isDetail ? `AND IFNULL(clm.member_id, m.member_id) = "${memberId}"` : '' }
      ${ searchToken ? `AND (IFNULL(m.name, clm.clinic_name) LIKE "%${searchToken}%" OR cm.message LIKE "%${searchToken}%")` : '' }
    ORDER BY cm.id ASC
    ${ isDetail ? '' : `LIMIT ${take} OFFSET ${skip}` }`;

    const countSql = `SELECT FOUND_ROWS() AS count
    FROM admin_chat_message cm
    LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
    LEFT JOIN member m ON m.member_id = cm.member_id
    ${ isIntern ? 'LEFT JOIN job_opening jo ON jo.member_id = cm.member_id' : '' }
    WHERE
      cm.is_qna = 1
      ${ isIntern ? `AND jo.intern_flag = ${ parseValueToSqlFormat(isIntern) }` : ''}
      ${ unread ? `AND cm.read_flag = ${ parseValueToSqlFormat(unread) }` : '' }
      ${ isDetail ? `AND IFNULL(clm.member_id, m.member_id) = "${memberId}"` : '' }
      ${ searchToken ? `AND (IFNULL(m.name, clm.clinic_name) LIKE "%${searchToken}%" OR cm.message LIKE "%${searchToken}%")` : '' }
    ORDER BY cm.id ASC
    ${ isDetail ? '' : 'LIMIT 100000000' }`;

    const chattings = await queryObject(chatQuery, 0, true) as AdminChatMessageWithName[];
    const [foundRows] = await query(countSql, 0, true);

    const { count } = foundRows ?? { count: 0 };
    const lastPage = getPages(take, count);

    return [chattings, lastPage];
  }

  async _fetchMessageGroup(
    page = 1,
    take = 20,
    options: {
      unread?: boolean;
      searchToken?: string | null;
      isIntern?: boolean;
    } = {
      unread: false,
      searchToken: null,
      isIntern: false
    },
    chatDetailOption: {
      isDetail?: boolean
      memberId?: string | null,
    } = {
      isDetail: false,
      memberId: null
    }
    ): Promise<[AdminChatMessageWithName[], number]> {

    const {
      unread,
      searchToken,
      isIntern,
    } = options;
    const {
      isDetail,
      memberId
    } = chatDetailOption;
    const chatQuery = `
    WITH latest_messages AS (
      SELECT
      cm.id,
      IFNULL(clm.clinic_name, m.name) AS name,
      IFNULL(cm.member_id, cm.clinic_member_id) AS member_id,
      cm.message,
      cm.reg_date, ROW_NUMBER() OVER (PARTITION BY member_id ORDER BY cm.id DESC) AS rn
      FROM admin_chat_message cm
      LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
        LEFT JOIN member m ON m.member_id = cm.member_id
      WHERE
        cm.is_qna = 1
      ${ isIntern ? `AND jo.intern_flag = ${ parseValueToSqlFormat(isIntern) }` : ''}
      ${ unread ? `AND cm.read_flag = ${ parseValueToSqlFormat(unread) }` : '' }
      ${ isDetail ? `AND IFNULL(clm.member_id, m.member_id) = "${memberId}"` : '' }
      ${ searchToken ? `AND (IFNULL(m.name, clm.clinic_name) LIKE "%${searchToken}%" OR cm.message LIKE "%${searchToken}%")` : '' }
      ORDER BY
        cm.id DESC

    )
    SELECT * FROM latest_messages WHERE rn = 1;`

    const countChatQuery = `
    WITH latest_messages AS (
      SELECT
      cm.id,
      IFNULL(clm.clinic_name, m.name) AS name,
      IFNULL(cm.member_id, cm.clinic_member_id) AS member_id,
      cm.message,
      cm.reg_date, ROW_NUMBER() OVER (PARTITION BY member_id ORDER BY cm.id DESC) AS rn
      FROM admin_chat_message cm
      LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
        LEFT JOIN member m ON m.member_id = cm.member_id
      WHERE
        cm.is_qna = 1
      ${ isIntern ? `AND jo.intern_flag = ${ parseValueToSqlFormat(isIntern) }` : ''}
      ${ unread ? `AND cm.read_flag = ${ parseValueToSqlFormat(unread) }` : '' }
      ${ isDetail ? `AND IFNULL(clm.member_id, m.member_id) = "${memberId}"` : '' }
      ${ searchToken ? `AND (IFNULL(m.name, clm.clinic_name) LIKE "%${searchToken}%" OR cm.message LIKE "%${searchToken}%")` : '' }
      ORDER BY
        cm.id DESC
    )
    SELECT COUNT(*) FROM latest_messages WHERE rn = 1;`
    const chattings = await queryObject(chatQuery, 0, true) as AdminChatMessageWithName[];
    const [{ count }] = await query(countChatQuery, 0, true);

    return [chattings, count];
  }

  fetchQnaMessagesDetail(page = 1, take = 20, memberId: string, unread = false): Promise<[AdminChatMessageWithName[], number]> {
    return this.fetchQnaMessages(page, take, {
      unread
    }, {
      isDetail: true,
      memberId,
    });
  }

  /**
   * 소통관리 > 쪽지발송 > 쪽지발송 목록
   */
  async fetchSentMessageList(
    page = 1,
    take = 20
  ): Promise<[AdminChatMessage[], number]> {

    const _page = page - 1;
    const skip = _page * take;

    const chatQuery = `SELECT cm.*, IFNULL(clm.clinic_name, m.name) AS name
    FROM admin_chat_message cm
    LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
    LEFT JOIN member m ON m.member_id = cm.member_id
    WHERE cm.is_qna = 0
    GROUP BY cm.reg_date
    ORDER BY cm.reg_date DESC
      LIMIT ${take}
      OFFSET ${skip}`;
    const countSql = `SELECT count(*) as 'count' from (
    SELECT count(*)
    FROM admin_chat_message cm
    LEFT JOIN clinic_member clm ON cm.clinic_member_id = clm.member_id
    LEFT JOIN member m ON m.member_id = cm.member_id
    WHERE cm.is_qna = 0
    GROUP BY cm.reg_date) a`;

    const chattings = await queryObject(chatQuery) as AdminChatMessageWithName[];
    const [{ count }] = await query(countSql, 0, true);

    const lastPage = getPages(take, count);

    return [chattings, lastPage];
  }

  /**
   * 소통관리 > 쪽지발송 > 등록 > 직종 리스트
   */
  async _fetchOccupationList(): Promise<string[]> {
    const [occupations] = await queryAndCountWithClass(Occupation, 1, 100000);

    return occupations.map(o => o.occupation);
  }

  async sendAdminChatMessagesForAll(message: string): Promise<boolean> {
    const allOcc = await this._fetchOccupationList();

    await this.sendAdminChatMessagesForClinicMember(message);
    await this.sendAdminChatMessagesForMember(message, allOcc);

    return true;
  }

  /**
   * 소통관리 > 쪽지발송 > 등록
   */
  async sendAdminChatMessagesForClinicMember(message: string): Promise<boolean> {

    const queryString = `INSERT INTO admin_chat_message (from_admin, message, member_type, clinic_member_id, is_qna)
    ( SELECT 1 AS from_admin, "${message}" AS message, 2 AS member_type, cm.member_id AS clinic_member_id, 0 AS is_qna FROM clinic_member cm )`

    await query(queryString, 0, true);

    return true;
  }

  async sendAdminChatMessagesForMember(message: string, occupations: Occupation['occupation'][]): Promise<boolean> {

    const queryString = `INSERT INTO admin_chat_message (from_admin, message, member_type, member_id, is_qna)
      (
        SELECT
          1 AS from_admin,
          "${message}" AS message,
          1 AS member_type,
          m.member_id AS member_id,
          0 AS is_qna
        FROM member m
        WHERE m.occupation IN (${occupations.map(occ => `"${occ}"`).join(',')})
      );`;

    await query(queryString, 0, true);

    return true;
  }

  async sendAdminChatMessagesForExcel(message: string, list:Array<any>): Promise<boolean> {
    let queryString = `INSERT INTO admin_chat_message (from_admin, message, member_type, member_id, clinic_member_id, is_qna)
        SELECT
          1 AS from_admin,
          "${message}" AS message,
          if(m.member_occ!='doctor',1,2) AS member_type,
          if(m.member_occ!='doctor',member_id,null) AS member_id,
          if(m.member_occ='doctor',member_id,null) AS clinic_member_id,
          0 AS is_qna
        FROM  (
              SELECT occupation AS 'member_occ', member_id FROM member
              UNION all
              SELECT 'doctor' AS 'member_occ', member_id FROM clinic_member
        ) AS m
        WHERE m.member_id IN (${list.map(id => `"${id}"`).join(',')});`;

    await query(queryString, 0, true);

    return true;
  }

  async deleteSingleAdminChatMessage(id: number): Promise<boolean> {
    const queryString = `UPDATE admin_chat_message set message = '삭제된 메세지입니다' where id = ${id} limit 1`;

    await query(queryString, 0, true);
    return true;
  }

  /**
   * 소통관리 > FAQ > 구인구직
   */
  async fetchFaq(page = 1, take = 20): Promise<[Faq[], number]> {
    const faqs = await queryAndCountWithClass(Faq, page, take);
    return faqs;
  }

  /**
   * 소통관리 > FAQ > 온라인교육
   */
  async fetchEducationFaq(
    page = 1,
    take = 20
  ): Promise<[EducationFaq[], number]> {
    const educationFaq = await queryAndCountWithClass(EducationFaq, page, take);
    return educationFaq;
  }

  /**
   * 소통관리 > 공지사항
   */
  async fetchNotcies(page = 1, take = 20, isEducationNotice = false): Promise<[Notice[], number]> {

    const EntityClass = isEducationNotice ? EducationNotice : Notice;
    const notices = await queryAndCountWithClass(EntityClass, page, take, (tableName) => ` ORDER BY notice_id DESC`);
    return notices;
  }

  /**
   * 소통관리 > 법정의무교육 공지
   */
  async fetchDutyNotcies(page = 1, take = 20, ): Promise<[DutyNotice[], number]> {

    const notices = await queryAndCountWithClass(DutyNotice, page, take, (tableName) => ` ORDER BY notice_id DESC`);
    return notices;
  }

  /**
   * 소통관리 > 가입쪽지
   * 치협과 이야기한 결과
   */
  fetchIntroductionMessage(): string {
    return "";
  }

  /**
   * 회원관리 > 회원정보 관리 > 병원회원
   * 회원관리 > 회원정보 관리 > 병원회원 > 검색하기
   */
  async fetchHospitalUsers(
    page = 1,
    take = 20,
    searchToken: string | null = null
  ): Promise<[ClinicMember[], number]> {
    const whereClauseCallback = (tableName: string) =>
      searchToken
        ? `WHERE
    ${tableName}.clinic_name LIKE '%${searchToken}%' OR
    ${tableName}.rep_name LIKE '%${searchToken}%' OR
    ${tableName}.rep_phone_number LIKE '%${searchToken}%' OR
    ${tableName}.email LIKE '%${searchToken}%' OR
    ${tableName}.member_id LIKE '%${searchToken}%' OR
    ${tableName}.address LIKE '%${searchToken}%' OR
    ${tableName}.biz_reg_number LIKE '%${searchToken}%'
    ORDER BY ${tableName}.reg_date DESC`
        : "";
    const cMembers = await queryAndCountWithClass(
      ClinicMember,
      page,
      take,
      whereClauseCallback
    );
    return cMembers;
  }

  /**
   * 회원관리 > 회원정보 관리 > 병원회원 > 상세
   */
  async fetchHospitalUserDetail(memberId: string): Promise<ClinicMember> {
    const clinicMembers =
      await queryObject<ClinicMember>(`SELECT * FROM clinic_member cm
        LEFT JOIN job_opening jo ON jo.member_id = cm.member_id
        WHERE cm.member_id = '${memberId}' LIMIT 1
        ;`);

    return clinicMembers[0];
  }

  async fetchUserLocation(memberId: string) {
    const clinicMembers =
      await queryObject<ClinicMember>(`SELECT X(location) as latitude, 
                                            Y(location) as longitude FROM clinic_member 
                                            WHERE member_id = '${memberId}' LIMIT 1;`);

    return clinicMembers[0];
  }

  /**
   * 회원관리 > 회원정보 관리 > 병원회원 > 위도/경도 업데이트
   */
  async updateHospitalUserLocation(memberId: string, data:any): Promise<ClinicMember> {
    const clinicMembers =
      await queryObject<ClinicMember>(`UPDATE clinic_member 
        set location = GeomFromText('POINT(${data.latitude} ${data.longitude})')
        WHERE member_id = '${memberId}';`);

    return clinicMembers[0];
  }

  /**
   * 회원관리 > 회원정보 관리 > 개인회원
   * 회원관리 > 회원정보 관리 > 개인회원 > 검색하기
   */
  async fetchUsers(
    page = 1,
    take = 20,
    searchToken: string | null = null,
    occupation: string | null = null
  ): Promise<[Member[], number]> {
    const whereClauseCallback = (tableName: string) =>
      searchToken
        ? `LEFT JOIN resume_group rg ON rg.member_id = ${tableName}.member_id
    LEFT JOIN resume r ON r.group_id = rg.group_id
    WHERE
    (
    ${tableName}.member_id LIKE '%${searchToken}%' OR
    ${tableName}.name LIKE '%${searchToken}%' OR
    ${tableName}.phone_number LIKE '%${searchToken}%' OR
    ${tableName}.email LIKE '%${searchToken}%' OR
    r.address LIKE '%${searchToken}%'
    )
    ${ occupation ? `AND ${tableName}.occupation = '${occupation}'` : '' }
    GROUP BY ${tableName}.member_id
    ORDER BY ${tableName}.reg_date DESC`
        : "";

    const members = await queryAndCountWithClass(
      Member,
      page,
      take,
      whereClauseCallback
    );
    return members;
  }

  /**
   * 회원관리 > 회원정보 관리 > 개인회원 > 상세
   */
  async fetchMemberUserDetail(memberId: string): Promise<Member> {
    const memberUsers = await queryObject<Member>(`
        SELECT *
        FROM member m
        LEFT JOIN resume_group rg ON rg.member_id = m.member_id
        LEFT JOIN resume r ON r.group_id = rg.group_id
        WHERE m.member_id = '${memberId}' LIMIT 1
        `);

    return memberUsers[0];
  }

  /**
   * 회원관리 > 회원정보 관리 > 개인회원 > 상세
   */
  async fetchBoardMember(memberId: string): Promise<Member> {
    const members = await query(`SELECT *
    FROM resume r
    LEFT JOIN resume_group rg ON r.group_id = rg.group_id
    LEFT JOIN member m ON m.member_id = rg.member_id
    WHERE m.member_id = '${memberId}' LIMIT 1`);

    return members[0];
  }

  async deleteMember(memberId: string, isClinic = false): Promise<boolean> {

    const agreeTableName = isClinic ? 'clinic_member_policy_agree' : 'member_policy_agree';
    const agreeDeleteQuery = `DELETE FROM ${agreeTableName} WHERE member_id = "${memberId}";`
    const tableName = isClinic ? 'clinic_member' : 'member';
    const deleteQuery = `DELETE FROM ${tableName} WHERE member_id = "${memberId}"`

    try {

      const targetField = isClinic === false ? 'employee_id' : 'member_id'

      const employeeDeleteQuery = `DELETE FROM clinic_employee WHERE ${targetField} = "${memberId}"`;
      await query(employeeDeleteQuery);

      await query(agreeDeleteQuery);
      await query(deleteQuery);

      return true;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * 회원관리 > 상세 > 이용 기록 > 이수 강의 수
   */
  async fetchUserLectureCount(
    memberType: "member" | "clinic_member",
    memberId: string
  ): Promise<any> {
    const type = memberType === "member" ? 1 : 2;
    const clinicMembers = await query(
      `SELECT COUNT(*) AS lectureCount
    FROM lecture_take lt
    WHERE lt.member_id = '${memberId}' AND lt.member_type = ${type} AND lt.complete_date IS NOT NULL LIMIT 1`,
      3,
      true
    );

    return clinicMembers[0]?.lectureCount || 0;
  }

  /**
   * 병원 회원관리 > 상세 > 이용 기록 > 진행 중인 법정의무교육
   */
  async updateUserLecture(
    memberId: string,
    lectureId: number,
  ): Promise<any> {
    const result =
      await queryObject<any>(
      `UPDATE lecture_take
              SET complete_date = (SELECT DATE_ADD(lt.start_date, INTERVAL 60 MINUTE)
                                   FROM lecture_take lt
                                   WHERE lt.member_id = lecture_take.member_id
                                     AND lt.lecture_id = lecture_take.lecture_id
                                     LIMIT 1)
              WHERE 
                member_id = '${memberId}'
              AND 
                lecture_id = ${lectureId};`
    );

    return result;
  }
  /**
   * 병원 회원관리 > 상세 > 이용 기록 > 진행 중인 법정의무교육
   */
  async fetchUserTakeLectureList(
    memberId: string
  ): Promise<number> {
    const list = await query(
      `SELECT 
              lc.lecture_type, 
              l.lecture_id, 
              l.name AS lecture_name, 
              lt.member_id, 
              lt.start_date, 
              lt.complete_date
          FROM lecture_take lt
            INNER JOIN lecture l ON lt.lecture_id = l.lecture_id
            INNER JOIN lecture_course lc ON l.course_id = lc.course_id
          WHERE lc.lecture_type = 1
          AND lt.member_id = '${memberId}'
          AND lt.complete_date IS NULL;`,
      3,
      true
    );

    return list;
  }
  /**
   * 카테고리/게시판관리 > 카테고리
   */
  async fetchCategories(): Promise<CommonCode[]> {
    const categoryList: CommonCode[] = [];

    const categoriesQuerySql = `
    SELECT *
    FROM common_code cc
    LEFT JOIN lecture_category_config lcc ON lcc.primary_code_id = cc.code_id
    WHERE
      lcc.is_secondary = 0
      AND cc.group_code_id = 15
    ORDER BY cc.display_order`;

    const allCategories = (await queryObject(
      categoriesQuerySql,
      1,
      true
    )) as CommonCode[];

    for (const category of allCategories) {
      const subCategorySql = `
      SELECT *
      FROM common_code_secondary ccs
      LEFT JOIN lecture_category_config lcc ON lcc.secondary_code_id = ccs.secondary_code_id
      LEFT JOIN common_code cc ON ccs.primary_code_id = cc.code_id
      WHERE
        ccs.primary_code_id = ${category.codeId}
        AND cc.group_code_id = 15
        AND lcc.is_secondary = 1`;

      const secondaries = (await queryObject(
        subCategorySql,
        1,
        true
      )) as CommonCodeSecondary[];

      category.commonCodeSecondaries = secondaries;
      categoryList.push(category);
    }

    return categoryList;
  }

  /**
   *
   * @param categoryId
   */
  async deleteCategory(categoryId: number): Promise<boolean> {
    const deleteSecondCategoreis = `DELETE FROM common_code_secondary WHERE primary_code_id = ${categoryId}`;
    await query(deleteSecondCategoreis);
    const deleteCategoryQuery = `DELETE FROM common_code WHERE code_id = ${categoryId}`;
    await query(deleteCategoryQuery);

    return true;
  }

  async deleteLecture(lectureId: number): Promise<boolean> {
    const deleteLectureTakeQuery = `DELETE FROM lecture_take WHERE lecture_take.lecture_id = ${lectureId}`;
    await query(deleteLectureTakeQuery);

    const deleteLectureQuery = `DELETE FROM lecture WHERE lecture.lecture_id = ${lectureId}`;
    await query(deleteLectureQuery);

    return true;
  }

  async deleteLectureCourse(lectureCourseId: number): Promise<boolean> {
    const lectures = await query(
      `SELECT * FROM lecture l WHERE l.course_id = ${lectureCourseId}`,
      0,
      true
    );

    if (lectures.length > 0) {
      const lectureIds = lectures.map((lecture) => lecture.lecture_id);

      const deleteLectureTakeQuery = `DELETE FROM lecture_take WHERE lecture_take.lecture_id IN (${lectureIds.join(
        ","
      )})`;
      await query(deleteLectureTakeQuery);
      const deleteLectureQuery = `DELETE FROM lecture WHERE lecture.lecture_id IN (${lectureIds.join(
        ","
      )})`;
      await query(deleteLectureQuery);
    }

    const deleteLectureCourse = `DELETE FROM lecture_course WHERE lecture_course.course_id = ${lectureCourseId}`;
    await query(deleteLectureCourse);

    return true;
  }

  /**
   * 카테고리/게시판관리 > 구인공고
   *
   * @property applicationCount 지원자 수
   */
  async fetchRecruits(
    page = 1,
    take = 20,
    whereClauseCallback?: (tableName: string) => string
  ): Promise<[(JobOpening & { applicationCount: number })[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const sql = `SELECT jo.*, COUNT(DISTINCT ja.resume_id) AS applicationCount
    FROM job_opening jo
    LEFT JOIN job_action ja ON ja.job_id = jo.job_id
    ${whereClauseCallback ? whereClauseCallback("jo") : ""}
    GROUP BY jo.job_id
    ORDER BY jo.job_id desc
    LIMIT ${take}
    OFFSET ${skip};`;

    const result = (await queryObject(sql)) as (JobOpening & {
      applicationCount: number;
    })[];

    const countSql = `SELECT COUNT(jo.job_id) AS count FROM job_opening jo LIMIT 1`;

    const [{ count }] = await query(countSql);

    const lastPage = getPages(take, count);

    return [result, lastPage];
  }

  /**
   * 카테고리/게시판관리 > 구인공고
   */
  async fetchBoardClinicMember(memberId: string): Promise<Member> {
    const clinicMembers = await query(`SELECT *
    FROM job_opening jo
    LEFT JOIN clinic_member cm ON cm.member_id = jo.member_id
    WHERE cm.member_id = '${memberId}' LIMIT 1`);

    return clinicMembers[0];
  }

  /**
   * 카테고리/게시판관리 > 인재정보
   *
   * @property offer_count 지원자 수
   */
  async fetchResumeList(
    page = 1,
    take = 20
  ): Promise<[(Resume & { offerCount: number })[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const sql = `SELECT r.*, rg.member_id,COUNT(ja.job_id) AS offer_count
    FROM resume r
    LEFT JOIN job_action ja ON ja.resume_id = r.resume_id
    LEFT JOIN resume_group rg ON rg.group_id = r.group_id
    WHERE ja.action_type = 1
    GROUP BY r.resume_id
    ORDER BY r.resume_id desc
    LIMIT ${take}
    OFFSET ${skip};`;

    const result = (await queryObject(sql)) as (Resume & {
      offerCount: number;
    })[];
    console.log(result)
    const countSql = `select count(*) as count from (SELECT r.*, rg.member_id,COUNT(ja.job_id) AS offer_count
    FROM resume r
    LEFT JOIN job_action ja ON ja.resume_id = r.resume_id
    LEFT JOIN resume_group rg ON rg.group_id = r.group_id
    WHERE ja.action_type = 1
    GROUP BY r.resume_id) a`;

    const countResult = await query(countSql, 0, true);

    const count = countResult[0].count;

    const lastPage = getPages(take, count);

    return [result, lastPage];
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육
   *
   * @property students_count 수강자 수
   */
  async fetchLegalLectureList(
    page = 1,
    take = 20
  ): Promise<[(Lecture & { students_count: number })[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const dutyLectures =
      await query(`SELECT l.*, COUNT(m.member_id) as 'students_count'
        FROM lecture l
        LEFT JOIN lecture_course lc ON lc.course_id = l.course_id
        LEFT JOIN lecture_take lt ON lt.lecture_id = l.lecture_id
        LEFT JOIN member m ON m.member_id = lt.member_id
        WHERE lc.lecture_type = 1
        GROUP BY l.lecture_id
        LIMIT ${take}
        OFFSET ${skip};`);

    const dutyLecturesCount = await query(
      `SELECT COUNT(l.lecture_id) AS count FROM lecture l LIMIT 1`
    );

    const lastPage = getPages(take, dutyLecturesCount);

    return [dutyLectures, lastPage];
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육 > 코스 목록 (강좌)
   * 카테고리/게시판관리 > 치과인강좌 > 코스 목록 (강좌)
   * 카테고리/게시판관리 > 구강보건교육 > 코스 목록 (강좌)
   * 카테고리/게시판관리 > 기타교육 > 코스 목록 (강좌)
   *
   * @property LectureCourse.lecture_type 교육 유형 - 1: 법정 의무 교육, 2: 치과인 강좌, 3: 구강 보건 교육, 4: 기타 교육)
   *
   * @example 1차 카테고리 id 78, 2차 카테고리 id 가 9 인 강의
   * @example await entityService.fecthLectureCourses(1, 100, 78, 9)
   */
  async fecthLectureCourses(
    lectureTypeCode: number,
    page = 1,
    take = 20,
    firstCategoryCode?: number,
    secondCategoryCode?: number
  ): Promise<[(LectureCourse & { finish_count: number })[], number]> {
    const firstCategoryWhereClauseCallback = (_tableName) => {
      if (firstCategoryCode) {
        return `AND ${_tableName}.common_code_id = ${firstCategoryCode}`;
      } else {
        return "";
      }
    };
    const secondCategoryWhereClauseCallback = (_tableName) => {
      if (firstCategoryCode && secondCategoryCode) {
        return `AND ${_tableName}.common_code_secondary_id = ${secondCategoryCode}`;
      } else {
        return "";
      }
    };

    const _page = page - 1;
    const skip = _page * take;

    const courses =
      await query(`SELECT lc.*, COUNT(lt.complete_date) AS 'finish_count'
      FROM lecture_course lc
      LEFT JOIN lecture l ON l.course_id = lc.course_id
      LEFT JOIN lecture_take lt ON lt.lecture_id = l.lecture_id
      WHERE lc.lecture_type = ${lectureTypeCode}
      ${firstCategoryWhereClauseCallback("lc")}
      ${secondCategoryWhereClauseCallback("lc")}
      GROUP BY lc.course_id
      ORDER BY lc.priority ASC
      LIMIT ${take}
      OFFSET ${skip};`);
    const countSql = `SELECT COUNT(*) AS count FROM lecture_course lc WHERE lc.lecture_type = ${lectureTypeCode}
      ${firstCategoryWhereClauseCallback("lc")}
      ${secondCategoryWhereClauseCallback("lc")}
      LIMIT 1`;

    const [{ count }] = await query(countSql);

    const lastPage = getPages(take, count);

    return [courses, lastPage];
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육 > 강의 목록
   * 카테고리/게시판관리 > 치과인강좌 > 강의 목록
   * 카테고리/게시판관리 > 구강보건교육 > 강의 목록
   * 카테고리/게시판관리 > 기타교육 > 강의 목록
   *
   * @property LectureCourse.lecture_type 교육 유형 - 1: 법정 의무 교육, 2: 치과인 강좌, 3: 구강 보건 교육, 4: 기타 교육)
   */
  async lecturesInCourse(
    courseId: number,
    page = 1,
    take = 20
  ): Promise<[(Lecture & { students_count: number })[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const lecturesInCourse =
      await query(`SELECT l.*, COUNT(lt.member_id) as 'students_count'
        FROM lecture l
        LEFT JOIN lecture_course lc ON l.course_id = lc.course_id
        LEFT JOIN lecture_take lt ON lt.lecture_id = l.lecture_id
        LEFT JOIN member m ON m.member_id = lt.member_id
        WHERE lc.course_id = ${courseId}
        GROUP BY l.lecture_id
        LIMIT ${take}
        OFFSET ${skip};`);

    return lecturesInCourse;
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육 > 수강완료 목록
   *
   * @property
   */
  async lecturesListView(
      memberId: string,
      page = 1,
      take = 20
  ): Promise<[(Lecture & { students_count: number })[], number]> {
    const _page = page - 1;
    const skip = _page * take;

    const lecturesListView =
        await query(`SELECT tbl1.member_id, tbl2.name, tbl1.complete_date 
        FROM lecture_take AS tbl1 
        LEFT JOIN lecture AS tbl2 USING(lecture_id)
        WHERE tbl1.member_id = ${memberId}
          AND tbl1.complete_date IS NOT null 
          AND tbl2.lecture_type='법정의무교육' 
        ORDER BY complete_date DESC 
        LIMIT ${take}
        OFFSET ${skip};`);

    return lecturesListView;
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육 > 수강자 목록
   * 카테고리/게시판관리 > 치과인강좌 > 수강자 목록
   * 카테고리/게시판관리 > 구강보건교육 > 수강자 목록
   * 카테고리/게시판관리 > 기타교육 > 수강자 목록
   *
   * @see {LectureStudent}
   */
  async fetchLectureCourseStudentList(
    lectureCourseId: number
  ): Promise<LectureStudent[]> {
    const sql = `SELECT
        m.name,
        m.phone_number,
        lc.title AS title,
        cm.clinic_name,
        lt.start_date AS start_date,
        lt.complete_date AS complete_date
      FROM
        member m
      LEFT JOIN lecture_take lt ON lt.member_id = m.member_id
      LEFT JOIN lecture l ON l.lecture_id = lt.lecture_id
      LEFT JOIN lecture_course lc ON lc.course_id = l.course_id
      LEFT JOIN clinic_employee ce ON ce.member_id = m.member_id
      LEFT JOIN clinic_member cm ON cm.member_id = ce.employee_id
      WHERE lc.course_id = ${lectureCourseId}
      GROUP BY
        m.member_id;`;

    const studentList = (await queryObject(sql, 0, true)) as LectureStudent[];

    console.log('studentList:', studentList[0]);
    return studentList;
  }

  /**
   * 카테고리/게시판관리 > 법정의무교육 > 강의 > 수강자 목록
   * 카테고리/게시판관리 > 치과인강좌 > 강의 > 수강자 목록
   * 카테고리/게시판관리 > 구강보건교육 > 강의 > 수강자 목록
   * 카테고리/게시판관리 > 기타교육 > 강의 > 수강자 목록
   *
   * @see {LectureStudent}
   */
  async fetchLecturesStudentList(lectureId: number, _30IndaysLimit = false, startDt:string | null = null, endDt:string | null = null): Promise<LectureStudent[]> {
    const sql = `SELECT
        m.name,
        m.phone_number,
        lc.title AS title,
        l.name AS lectureName,
        cm.clinic_name,
        lt.start_date AS start_date,
        lt.complete_date AS complete_date
      FROM
        member m
      LEFT JOIN lecture_take lt ON lt.member_id = m.member_id
      LEFT JOIN lecture l ON l.lecture_id = lt.lecture_id
      LEFT JOIN lecture_course lc ON lc.course_id = l.course_id
      LEFT JOIN clinic_employee ce ON ce.employee_id = m.member_id
      LEFT JOIN clinic_member cm ON ce.member_id = cm.member_id
      WHERE l.lecture_id = ${lectureId}
       ${ _30IndaysLimit && startDt == null ? 'AND lt.start_date BETWEEN CURDATE() - INTERVAL 30 DAY AND NOW()' : '' }
      ${ !_30IndaysLimit && startDt != null && endDt != null ? "AND lt.start_date BETWEEN '" +startDt+ "' AND '" +endDt+ "' " : ''}
      GROUP BY
        m.member_id;`;

    const studentList = (await queryObject(sql, 0, true)) as LectureStudent[];

    return studentList;
  }

  /**
   * 카테고리/게시판관리 > 자료실
   */
  async fetchMaterials(page, take): Promise<[Reference[], number]> {
    const refersAndCount = await queryAndCountWithClass(Reference, page, take);
    return refersAndCount;
  }

  /**
   * 통계관리 > 일간현황
   * => fetchLandingPageDataByDate 사용
   */

  /**
   * 통계관리 > 방문분석
   * 통계관리 > 방문분석 > 순 방문자수 => _fetchVisitors
   * 통계관리 > 방문분석 > 재방문율 => _fetchVisitors
   *
   * @example const _5edaysAgo = new Date(new Date().setDate(21));
   *          return await this.fetchUniqueVisitors(_5edaysAgo, new Date())
   *
   */
  async fetchUniqueVisitors(
    startDate: Date,
    endDate: Date
  ): Promise<{ date: Date; visitorCount: number }[]> {
    const queryString = `SELECT SUM(mlc.login_count) AS visitCount, mlc.proc_date
    FROM member_login_count mlc
    WHERE mlc.proc_date BETWEEN '${toYYYYMMDD(startDate)}' AND '${toYYYYMMDD(
      endDate
    )}'
GROUP BY DATE_FORMAT(mlc.proc_date, '%Y-%m-%d')
ORDER BY mlc.proc_date DESC LIMIT 10000000`;

    const result = await query(queryString, 1, true);
    return result;
  }

  /**
   * 통계관리 > 방문분석 > 평균 방문횟수
   */
  async fetchAverageVisitCount(
    startDate: Date,
    endDate: Date
  ): Promise<{ date: Date; visitCount: number }[]> {
    const queryString = `SELECT FLOOR(AVG(mlc.login_count)) AS visitCount, mlc.proc_date
    FROM member_login_count mlc
    WHERE mlc.proc_date BETWEEN '${toYYYYMMDD(startDate)}' AND '${toYYYYMMDD(
      endDate
    )}'
GROUP BY DATE_FORMAT(mlc.proc_date, '%Y-%m')
ORDER BY mlc.proc_date DESC LIMIT 10000000`;

    const result = await query(queryString, 1, true);
    return result;
  }

  /**
   * 통계관리 > 회원분석 > 회원현황 > 병원/개인회원
   *
   * member - 개인 회원
   * hospital - 병원 회원
   *
   * @example fetchAnalysisForMembers(startDate, endDate, 'member')
   * @example fetchAnalysisForMembers(startDate, endDate, 'hospital')
   */
  async fetchAnalysisForMembers(
    startDate = new Date(),
    endDate = new Date(),
    memberType: "hospital" | "member" = "member"
  ): Promise<MemberCountings[]> {
    const tablename = memberType === "member" ? "member" : "clinic_member";

    const queryString = `SELECT COUNT(m.member_id) AS newbieNumbers, DATE_FORMAT(m.reg_date, '%Y-%m-%d') as date
    FROM ${tablename} m
    WHERE m.reg_date BETWEEN '${toYYYYMMDD(startDate)}' AND '${toYYYYMMDD(
      endDate
    )}'
    GROUP BY DATE_FORMAT(m.reg_date, '%Y-%m-%d')
    ORDER BY m.reg_date DESC`;
    const result = await query(queryString, 0, true);

    return result;
  }

  /**
   * 통계관리 > 회원분석 > 방문분석 > 법정의무교육 이수율 > 직종별
   *
   * targetDate 로부터 하루 전까지의 이수율을 구한다
   */
  async fetchDutyEducationFinishRatioByOccupations(targetDate: Date): Promise<
    { occupation: string; finishPercentage: number }[]
  > {

    const oneDayAgo = new Date(targetDate);
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    const queryString = `SELECT * FROM duty_lecture_statistics_by_occupation WHERE created_at BETWEEN "${toYYYYMMDD(oneDayAgo)}" AND "${toYYYYMMDD(targetDate)}"`;

    const result = await query(queryString, 1, true);

    return result;
  }

  /**
   * 통계관리 > 회원분석 > 법정의무교육 이수율
   *
   * targetDate 로부터 endDate 전까지의 이수율을 구한다
   */
  async fetchDutyEducationFinishRatioByCourse(startDate: Date, endDate: Date): Promise<LectureFinishRatio[]> {

    const queryString = `SELECT SUM(ratio) / FOUND_ROWS() AS ratio
    FROM duty_lecture_statistics
    WHERE "${toYYYYMMDD(startDate)}" AND "${toYYYYMMDD(endDate)}" + INTERVAL 1 DAY
    GROUP BY created_at`;

    const result = await query(queryString, 1, true) as LectureFinishRatio[];

    return result;
  }

  /**
   * 통계관리 > 순위
   */
  async fetchRanks(): Promise<Rankers> {

    // 게시글 조회 순위 (채용 공고)
    const jobOpeningQuery = `SELECT jo.clinic_name AS ranker, jo.title AS ranker_detail, jo.view_count AS view_count FROM job_opening jo ORDER BY jo.view_count DESC`;
    const jobOpenings = await queryObject(jobOpeningQuery, 0, true) as Rank[];

    // 인재정보
    const resumeQuery = `SELECT r.name AS ranker, r.title as ranker_detail, r.view_count AS view_count FROM resume r ORDER BY r.view_count DESC`
    const resumes = await queryObject(resumeQuery, 0, true) as Rank[];

    // 온라인 교육 재생시간 순
    const videoPlayTimeRankQuery = `SELECT lc.title AS ranker, l.name AS ranker_detail, SUM(lt.view_time) AS view_count FROM lecture_take lt LEFT JOIN lecture l ON l.lecture_id = lt.lecture_id LEFT JOIN lecture_course lc ON lc.course_id = l.course_id ORDER BY SUM(lt.view_time) DESC`
    const videoPlayTimeRank = await queryObject(videoPlayTimeRankQuery, 0, true) as Rank[];

    const lectureCourseRankQuery = `SELECT lc.title AS ranker, SUM(lt.view_time) AS view_count FROM lecture_course lc LEFT JOIN lecture l ON l.course_id = lc.course_id LEFT JOIN lecture_take lt ON l.lecture_id = lt.lecture_id WHERE lt.view_time > 0 GROUP BY lc.course_id ORDER BY SUM(lt.view_time) DESC`
    const lectureCourseRank = await queryObject(lectureCourseRankQuery, 0, true) as Rank[];

    const daysAgo = 7;
    const newVideoPlayRankQuery = `SELECT lc.title, l.name, SUM(lt.view_time) FROM lecture_take lt LEFT JOIN lecture l ON l.lecture_id = lt.lecture_id LEFT JOIN lecture_course lc ON lc.course_id = l.course_id WHERE lt.start_date BETWEEN NOW() - INTERVAL ${daysAgo} DAY AND NOW() GROUP BY l.lecture_id ORDER BY SUM(lt.view_time) DESC`
    const newVideoPlayRank = await queryObject(newVideoPlayRankQuery, 0, true) as Rank[];

    return {
      boardViewMemberRankers: [],
      boardViewClinicMemberRankers: [],
      boardViewRecruitRankBoard: jobOpenings,
      boardViewHumanResourecRankBoard: resumes,
      boardViewVideoRankBoard: videoPlayTimeRank,
      boardViewVideoViewCountRank: lectureCourseRank,
      videoPlayTimeRank: lectureCourseRank,
      videoReplayRank: [],
      videoNewplayRank: newVideoPlayRank
    }
  }

  /**
   * 배너관리
   */
  async fetchBanners(): Promise<Banner[]> {
    const [banners] = await queryAndCountWithClass(Banner, 1, 1000000);
    return banners;
  }

  /**
   * 실습치과찾기 > 실습치과공고
   */
  async fetchInternRecruitList(
    page = 1,
    take = 20
  ): Promise<(JobOpening & { applicationCount: number })[]> {
    const _page = page - 1;
    const skip = _page * take;

    const queryString = `SELECT jo.*, COUNT(DISTINCT ja.resume_id) AS application_count, ja.*
    FROM job_opening jo
    LEFT JOIN job_action ja ON ja.job_id = jo.job_id
    WHERE
      jo.intern_flag = 1
    GROUP BY jo.job_id
    LIMIT ${take} OFFSET ${skip}`;

    const countSql = `SELECT COUNT(jo.job_id) AS count
    FROM job_opening jo
    LEFT JOIN job_action ja ON ja.job_id = jo.job_id
    WHERE
      jo.intern_flag = 1
    GROUP BY jo.job_id`;
    const result = await queryObject(queryString);

    const [{ count }] = await query(countSql, 0, true);
    const lastPage = getPages(take, count);

    return [result, lastPage];
  }

  /**
  * 실습치과찾기 > 실습치과지원센터 > 메세지 조회
  * fetchQnaMessages 을 사용하면 됩니다.
  */

  // ________
  async __fetchHospitalMemberStudentsListByLectureId(
    lectureId: number
  ): Promise<ClinicMember[]> {
    const [clinicMembers] = await queryAndCountWithClass(
      ClinicMember,
      1,
      1000000,
      (tableName) =>
        `LEFT JOIN lecture_take lt ON lt.member_id = ${tableName}.member_id WHERE lt.lecture_id = ${lectureId}`
    );

    return clinicMembers;
  }

  async __fetchFirstDentInLectureCategory(): Promise<[CommonCode[], number]> {
    return await queryAndCountWithClass(
      CommonCode,
      1,
      1000000,
      (tableName) =>
        `LEFT JOIN lecture_course lc ON lc.common_code_id = ${tableName}.code_id WHERE lc.common_code_id IS NOT NULL`
    );
  }

  async __fetchSecondDentInLectureCategory(): Promise<
    [CommonCodeSecondary[], number]
  > {
    return await queryAndCountWithClass(
      CommonCodeSecondary,
      1,
      1000000,
      (tableName) =>
        `LEFT JOIN lecture_course lc ON lc.common_code_secondary_id = ${tableName}.secondary_code_id WHERE lc.common_code_secondary_id IS NOT NULL`
    );
  }

  downloadJsonArrayToCSV(
    fileTitle: string,
    lectureStudents: LectureStudent[]
  ): void {
    if (!fileTitle || lectureStudents.length < 1) {
      alert("수강자가 없어 다운로드 받을 수 없습니다.");
      return;
    }

    const sortedMappedLectureStudents = lectureStudents
      .sort((a,b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
      .map(student => {
        student.startDate = YYYYMMDDHHmm(student.startDate) as any;
        return student;
      });


    const fields = this.getObjectPaths(sortedMappedLectureStudents[0]);
    const json2csvParser = new Parser<LectureStudent>({ fields, withBOM: true });

    const parsedMoredenProgressCSV = json2csvParser.parse(sortedMappedLectureStudents);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_firstLine, ...rest ] = parsedMoredenProgressCSV.split('\n');

    const KoreanFields = fields.map((_key) =>
      convertEnglishToKoreanForExcelExport(_key as keyof LectureStudent)
    );
    const finalResult = KoreanFields + '\n' + rest.join('\n');
    const link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(finalResult));
    link.setAttribute("download", fileTitle + '.csv');
    document.body.appendChild(link);

    link.click();
  }

  downloadChangeLogJsonArrayToCSV(
    fileTitle: string,
    list: unknown
  ): void {

    // 모든 필드를 포함한 fields 배열
    const fields = [
      "id",
      "lectureId",
      "courseId",
      "seq",
      "name",
      "creator",
      "description",
      "videoId",
      "playTime",
      "createdDate",
      "materialDownloadLink",
      "materialDownloadFilename",
      "imageFilename",
      "visibleFlag",
      "provisions",
      "updateDate",
      "updatedUser"
    ];

    // JSON 데이터를 CSV 문자열로 변환
    const json2csvParser = new Parser({ fields });
    // @ts-ignore
    const csv = json2csvParser.parse(list);

    // CSV 파일 생성 및 다운로드
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.setAttribute("download", fileTitle + '.csv');
    document.body.appendChild(link);

    link.click();
  }

  /**
   * get object path with recursion strategy
   */
  getObjectPaths<T>(obj: T): string[] {
    const paths = Object.entries(obj as any).reduce<string[]>((acc, [key, value]) => {
      value = value ?? '';
      if (typeof value === typeof {}) {
        const fields = this.getObjectPaths(value);
        return acc.concat(fields.map((field) => `${key}.${field}`));
      } else {
        return acc.concat(key);
      }
    }, []);
    return paths;
  }
  /*
  * 수정 이력 출력
  * */
  async fetchChageLectureList(id) {
    const sqlString = `SELECT * FROM lecture_change_log WHERE lecture_id = ${id}`
    return await queryObject(sqlString, 0, true);
  }
}
