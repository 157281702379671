import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { LectureTake } from "./LectureTake";
import { LectureCourse } from "./LectureCourse";

@Index("idx_lecture_composite_1", ["courseId", "seq"], { unique: true })
@Index("fk_education_lecture_course_id", ["courseId"], {})
@Entity("lecture", { schema: "dentin" })
export class Lecture {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "lecture_id",
    comment: "강좌 ID",
  })
  lectureId: string;

  @Column("bigint", { name: "course_id", comment: "강좌 ID" })
  courseId: string;

  @Column("tinyint", { name: "seq", comment: "강의 순번" })
  seq: number;

  @Column("varchar", { name: "name", comment: "강의명", length: 128 })
  name: string;

  @Column("varchar", { name: "creator", comment: "제작자", length: 64 })
  creator: string;

  @Column("text", { name: "description", nullable: true, comment: "설명" })
  description: string | null;

  @Column("varchar", {
    name: "video_id",
    comment: "동영상 ID (유튜브 영상 ID)",
    length: 256,
  })
  videoId: string;

  @Column("int", { name: "play_time", comment: "동영상 재생 시간" })
  playTime: number;

  @Column("datetime", {
    name: "created_date",
    nullable: true,
    default: () => "CURRENT_TIMESTAMP",
  })
  createdDate: Date | null;

  @Column("varchar", {
    name: "material_download_link",
    nullable: true,
    length: 2048,
  })
  materialDownloadLink: string | null;

  @Column("varchar", {
    name: "material_download_filename",
    nullable: true,
    length: 2048,
  })
  materialDownloadFilename: string | null;

  @Column("varchar", {
    name: "image_filename",
    nullable: true,
    comment: "image 파일명",
    length: 256,
  })
  imageFilename: string | null;

  @Column("text", { name: "provisions", nullable: true, comment: "법적 근거" })
  provisions: string | null;
  @Column("varchar", { name: "visible_flag", nullable: true, comment: "노출" })
  visibleFlag: string | null;

  @OneToMany(() => LectureTake, (lectureTake) => lectureTake.lecture)
  lectureTakes: LectureTake[];

  @ManyToOne(() => LectureCourse, (lectureCourse) => lectureCourse.lectures, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "course_id", referencedColumnName: "courseId" }])
  course: LectureCourse;
}
