import {
  Column,
  Entity,
  Index,
  OneToMany,
  PrimaryGeneratedColumn,
} from "typeorm";
import { CommonCode } from "./CommonCode";

@Index("idx_common_group_code_group_name", ["groupName"], { unique: true })
@Entity("common_group_code", { schema: "dentin" })
export class CommonGroupCode {
  @PrimaryGeneratedColumn({
    type: "bigint",
    name: "group_code_id",
    comment: "그룹 코드 ID",
  })
  groupCodeId: string;

  @Column("varchar", {
    name: "group_name",
    unique: true,
    comment: "그룹명",
    length: 128,
  })
  groupName: string;

  @Column("varchar", {
    name: "description",
    nullable: true,
    comment: "그룹 설명",
    length: 256,
  })
  description: string | null;

  @OneToMany(() => CommonCode, (commonCode) => commonCode.groupCode)
  commonCodes: CommonCode[];
}
