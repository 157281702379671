import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { Member } from "./Member";

@Index("fk_member_work_area_member_id", ["memberId"], {})
@Entity("member_work_area", { schema: "dentin" })
export class MemberWorkArea {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("tinyint", {
    primary: true,
    name: "seq",
    comment: "희망 순번 (1순위, 2순위)",
  })
  seq: number;

  @Column("varchar", { name: "city", comment: "도시명", length: 64 })
  city: string;

  @Column("varchar", { name: "district", comment: "지역(구)명", length: 64 })
  district: string;

  @Column("varchar", {
    name: "place",
    nullable: true,
    comment: "장소명",
    length: 64,
  })
  place: string | null;

  @Column("point", { name: "location", comment: "좌표 정보" })
  location: string;

  @ManyToOne(() => Member, (member) => member.memberWorkAreas, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: Member;
}
