import ServiceKeyTable from "../../components/info/ServiceKeyTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { EntityService } from "../../services/entity.service";
import {ServiceKey} from "../../entities/entities/ServiceKey";
import { issueServiceKey } from "../../services/auth/auth.service";

const ServiceKeyPage = () => {
    const navigate = useNavigate();
    const [serviceKeyPageData, setServiceKeyPageData] = useState<[ServiceKey[], number] | null>(
        null
    );
    const [checkItems, setCheckItems] = useState<string[]>([]);
    const entityService = new EntityService();
    const location = useLocation();
    const param = new URLSearchParams(location.search);
    const page = Number(param.get("page")) || 1;
    const data = location?.state?.item;
    const searchDevId = data?.devId;
    const adminId = sessionStorage.getItem("adminId");

    useEffect(() => {
        (async () => {
            const res = await entityService.fetchServiceKeyUsers(page, 20, searchDevId);
            setServiceKeyPageData(res);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, navigate]);

    const onPageClick = (data) => {
        const selected = data.selected + 1;
        navigate(`/info/developer?page=${selected}`);
    };

    const handleButtonClick = async () => {
        if (serviceKeyPageData && serviceKeyPageData[0].length > 0) {
            if (window.confirm("인증키를 재발급하시겠습니까?")) {
                // 재발급 로직 실행
                const issueToken = await issueServiceKey(searchDevId, adminId ?? "admin", true);
                window.location.reload();
            }
        } else {
            if (window.confirm("인증키를 발급하시겠습니까?")) {
                // 발급 로직 실행
                const issueToken = await issueServiceKey(searchDevId, adminId ?? "admin", false);
                window.location.reload();
            }
        }
    };

    return (
        <div className="inner page">
            <div className="flex items-end justify-between">
                <div className="page-tit">
                    <span className="text-[#999]">개발자 계정 목록 〉</span> 인증키 발급현황
                </div>
                <div className="space-x-2">
                    <button className="blue" onClick={handleButtonClick}>
                        {serviceKeyPageData && serviceKeyPageData[0].length > 0 ? "인증키 재발급" : "인증키 발급"}
                    </button>
                </div>
            </div>
            {serviceKeyPageData && (
                <ServiceKeyTable
                    serviceKeyPageData={serviceKeyPageData}
                    onPageClick={onPageClick}
                    page={page}
                    checkItems={checkItems}
                    setCheckItems={setCheckItems}
                />
            )}
        </div>
    );
};

export default ServiceKeyPage;
