import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { JobOpening } from "./JobOpening";
import { Resume } from "./Resume";

@Index("resume_id", ["resumeId"], {})
@Entity("job_action", { schema: "dentin" })
export class JobAction {
  @Column("bigint", { primary: true, name: "job_id", comment: "채용 공고 ID" })
  jobId: string;

  @Column("bigint", { primary: true, name: "resume_id", comment: "이력서 ID" })
  resumeId: string;

  @Column("tinyint", {
    primary: true,
    name: "action_type",
    comment: "1: OFFER: 면접 제안, 2: APPLY: 면접 지원",
  })
  actionType: number;

  @Column("varchar", {
    name: "action",
    nullable: true,
    comment: "액션. PASS: 합격, FAIL:불합격, REJECT: 거절",
    length: 64,
  })
  action: string | null;

  @Column("text", { name: "memo", nullable: true, comment: "메모" })
  memo: string | null;

  @Column("bit", { name: "read_flag", comment: "읽음 여부" })
  readFlag: boolean;

  @Column("datetime", { name: "reg_date", comment: "등록 일시" })
  regDate: Date;

  @Column("datetime", { name: "upd_date", comment: "수정 일시" })
  updDate: Date;

  @ManyToOne(() => JobOpening, (jobOpening) => jobOpening.jobActions, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "job_id", referencedColumnName: "jobId" }])
  job: JobOpening;

  @ManyToOne(() => Resume, (resume) => resume.jobActions, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "resume_id", referencedColumnName: "resumeId" }])
  resume: Resume;
}
