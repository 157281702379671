import { useState } from "react";
import CheckboxInput from "./CheckboxInput";
import { EntityService } from "../../services/entity.service";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
const xlsxFile = require("read-excel-file/node");

const TextAreaForm = ({ title }: { title: string; message?: boolean }) => {
  const [msg, setMsg] = useState<string>("");
  const entityService = new EntityService();
  const [occupation, setOccupation] = useState<string[]>([]);
  const [file, setFile] = useState<any>();
  const navigate = useNavigate();

  const submit = () => {
    if(!msg.length) {
      alert("쪽지 내용을 입력해주세요")
      return;
    }

    if (!occupation.filter(o => o !== "개인회원").length) {
      alert("발송대상을 선택해주세요")
      return;
    };

    if (window.confirm("쪽지를 발송하시겠습니까?")){

      if (occupation.includes("병원회원")) {
        entityService
          .sendAdminChatMessagesForClinicMember(msg)
          .then(() => navigate(-1))
          .catch((err) => console.log(err));
      }

      if (occupation.includes("개인회원")) {
        entityService
          .sendAdminChatMessagesForMember(msg, occupation.filter(o => o!=="개인회원"))
          .then(() => navigate(-1))
          .catch((err) => console.log(err));
      }

      if (occupation.includes("excel")) {
        readXlsxFile(file)
          .then((sheets) => {
          entityService
            .sendAdminChatMessagesForExcel(msg, sheets)
            .then(() => navigate(-1))
            .catch((err) => console.log(err));
        })
          .catch((err) => console.log(err));
      }

    }
  };

  return (
    <div className="inner px-4">
      <div className="page-tit">{title}</div>
      <div className="mt-10">
        <textarea
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          className="shadow-custom w-full h-36 rounded-sm px-4 py-2"
        />
      </div>
      <div className="mt-10">
        <CheckboxInput
          title="발송대상 선택"
          state={occupation}
          setState={setOccupation}
          setFile={setFile}
        />
      </div>

      <div className="flex items-center justify-end space-x-2 mt-4">
        <button onClick={() => navigate(-1)}>취소</button>
        <button className="dark" onClick={submit}>
          발송
        </button>
      </div>
    </div>
  );
};

export default TextAreaForm;
