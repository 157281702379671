import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { Reference } from "../../entities/entities/Reference";
import { EntityService } from "../../services/entity.service";

const DataTable = ({ data, onPageClick, page }) => {
  const entityService = new EntityService();
  const dataArr = data && data[0];
  const navigate = useNavigate();

  const deleteClick = async (id) => {
    if (window.confirm("삭제하시겠습니까?") ) {
    try {
      await entityService.delete(Reference, id);
      alert("삭제가 완료되었습니다.");
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert('에러가 발생했습니다.');
    }
  }
  };
  return (
    <div className="table">
      <div className="row header">
        <div className="w-9/12 ml-4">자료명</div>
        <div className="w-1/12">조회수</div>
        <div className="w-1/12 justify-center">수정</div>
        <div className="w-1/12 justify-center">삭제</div>
      </div>
      {dataArr?.map((item) => (
        <div className="row" key={item.referenceId}>
          <div className="w-9/12 ml-4">{item.title}</div>
          <div className="w-1/12">{item.viewCount}</div>
          <div className="w-1/12 justify-center">
            <button
              className="dark"
              onClick={() => navigate("/board/datawrite", { state: item })}
            >
              수정
            </button>
          </div>
          <div className="w-1/12 justify-center">
            <button onClick={() => deleteClick(item.referenceId)}>삭제</button>
          </div>
        </div>
      ))}

      <div className="paging">
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={data && data[1]}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
          forcePage={page - 1}
        />
      </div>
    </div>
  );
};

export default DataTable;
