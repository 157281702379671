import dayjs from "dayjs";
import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router-dom";
import RecieveChat from "../../components/chat/RecieveChat";
import SendChat from "../../components/chat/SendChat";
import { AdminChatMessage } from "../../entities/entities/AdminChatMessage";
import {
  AdminChatMessageWithName,
  EntityService,
} from "../../services/entity.service";
import { debounce, groupBy } from "lodash";
import { cls } from "../../utils/class";
import { useInterval } from "usehooks-ts";

const QnaPage = () => {
  const navigate = useNavigate();
  const [qnaData, setQnaData] = useState<
    [AdminChatMessageWithName[], number] | null
  >();
  const [message, setMessage] = useState<string>("");
  const [detailData, setDetailData] = useState<
    [AdminChatMessageWithName[], number] | null
  >();
  const [boxClick, setBoxClick] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [mid, setMid] = useState<string>("");
  const entityService = new EntityService();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const page = Number(param.get("page")) || 1;
  const typeMessage = param.get("type") || "";
  const userId = param.get("user_id") || "";
  const userType = param.get("user_type") || "";
  const userName = param.get("user_name") || "";
  const state = qnaData && qnaData[0];

  const allMessageFetch = async () => {
    const res = await entityService._fetchMessageGroup(page, 20);
    setQnaData(res);
    const p = new URLSearchParams();
    p.append("user_id", userId);
    p.append("user_type", userType);
    p.append("user_name", userName);
    p.append("type", "all");
    navigate(`/communication/qna?${p}`);
  };

  const unReadMessage = async () => {
    const res = await entityService._fetchMessageGroup(page, 20, {
      unread: true,
    });
    setQnaData(res);
    const p = new URLSearchParams();
    p.append("user_id", userId);
    p.append("user_type", userType);
    p.append("user_name", userName);
    p.append("type", "unread");
    navigate(`/communication/qna?${p}`);
  };

  useEffect(() => {
    if (typeMessage === "unread") {
      unReadMessage();
      return;
    } else {
      allMessageFetch();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, navigate, typeMessage]);

  useInterval(
    () => {
      if (typeMessage === "unread") {
        unReadMessage();
        return;
      } else {
        allMessageFetch();
        return;
      }
    },
    30000 //null 일 경우 정지
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    navigate(`/communication/qna?page=${selected}`);
  };
  const [time, setTime] = useState<number>(0);

  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     setTime((cur) => cur + 1);
  //   }, 30000);
  //   return () => clearInterval(countdown);
  // }, [time]);

  useEffect(() => {
    (async () => {
      const res = await entityService.fetchQnaMessagesDetail(page, 20, mid);
      setDetailData(res);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, time]);

  const reloadMessage = async () => {
    if (userId) {
      const res = await entityService.fetchQnaMessagesDetail(page, 20, userId);
      setDetailData(res);
    }
  };

  useEffect(() => {
    reloadMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userType, userName]);

  const openChat = (memberId) => {
    setMid(memberId);
    (async () => {
      const res = await entityService.fetchQnaMessagesDetail(
        page,
        20,
        memberId
      );
      setDetailData(res);
    })();
  };

  const groupedMessages = useMemo(
    () =>
      groupBy(detailData && detailData[0], (message) =>
        dayjs(message.regDate).format("YYYY년 MM월 DD일")
      ),
    [detailData]
  );

  const submitMessage = async () => {
    const chatData = detailData && detailData[0][0];

    setMessage("");
    const param = {
      fromAdmin: true,
      message: message,
      regDate: new Date(),
      memberId: chatData?.memberId ?? null,
      clinicMemberId: chatData?.clinicMemberId ?? null,
      memberType: chatData?.memberType,
    };
    const newParam = {
      fromAdmin: true,
      message: message,
      regDate: new Date(),
      memberId: userType === "1" ? userId : null,
      clinicMemberId: userType === "2" ? userId : null,
      memberType: chatData?.memberType,
    };
    const res = await entityService.create(
      AdminChatMessage,
      chatData ? param : newParam
    );
    const memberId = chatData?.memberId || chatData?.clinicMemberId;
    if ((res && memberId) || (res && userId)) {
      const resp = await entityService.fetchQnaMessagesDetail(
        page,
        20,
        memberId || userId
      );
      setDetailData(resp);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(e.target.value);
  };

  const debounceSearch = useMemo(
    () =>
      debounce((query) => {
        setSearchText(query);
        entityService
          ._fetchMessageGroup(page, 20, { searchToken: query })
          .then((res) => setQnaData(res))
          .catch((err) => console.log(err));
      }, 200),
    [query, page]
  );
  const scrollRef = useRef(null);

  useEffect(() => {
    const element = (scrollRef?.current as any)?.lastChild as HTMLElement;
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [detailData]);

  useEffect(() => {
    (async () => {
      if (search === false) {
        await allMessageFetch();
      }
    })();
  }, [search]);

  return (
    <div className="inner page">
      <div className="mb-5 page-tit">실시간 Q&A</div>
      <div className="w-[1102px] m-auto h-[700px] border-[1px] border-[#E7E7E7] flex items-center justify-between">
        <div className="w-[253px] h-full border-r-[1px] border-[#E7E7E7] bg-[#FAFAFA]">
          {search ? (
            <div className="bg-white space-y-2 border-b-[1px] border-[#E7E7E7]">
              <div className="flex items-center justify-between px-3 py-2">
                <div className="text-[15px] text-[#333]">닉네임, 메모 검색</div>
                <div
                  className="text-[#333] font-normal text-lg cursor-pointer"
                  onClick={() => setSearch(false)}
                >
                  x
                </div>
              </div>
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                className="w-full h-10 px-3"
                onChange={onChange}
              />
            </div>
          ) : (
            <div className="flex items-center justify-between p-4 bg-white border-b border-[#E7E7E7]">
              <div className="flex items-center space-x-2">
                <div
                  className="text-[#333] font-semibold text-base cursor-pointer"
                  onClick={() => setBoxClick(!boxClick)}
                >
                  전체메세지
                </div>
                <div>
                  {boxClick ? (
                    <i className="ml-2 ii-chevron-up"></i>
                  ) : (
                    <i className="ii-chevron-down"></i>
                  )}
                </div>
              </div>

              <div onClick={() => setSearch(true)}>
                <i className="cursor-pointer ii-search"></i>
              </div>
            </div>
          )}

          {boxClick && (
            <div className="border-b border-[#E7E7E7] mb-1">
              <div
                className="flex items-center justify-between p-4 pb-2 bg-white cursor-pointer"
                onClick={allMessageFetch}
              >
                <div className="font-semibold text-[#333] text-base">
                  전체메시지
                </div>
                {typeMessage === "all" && <i className="ii-check"></i>}
              </div>
              <div
                className="flex items-center justify-between p-4 pt-2 bg-white cursor-pointer"
                onClick={unReadMessage}
              >
                <div className="font-semibold text-[#333] text-base">
                  안읽은 메세지
                </div>
                {typeMessage === "unread" && <i className="ii-check"></i>}
              </div>
            </div>
          )}

          <div
            className={cls(
              boxClick ? "h-[535px]" : "h-[620px]",
              "overflow-y-auto"
            )}
          >
            {state?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="p-4 mb-1 bg-white cursor-pointer"
                  onClick={() => openChat(item.memberId || item.clinicMemberId)}
                >
                  <div className="flex items-center justify-between">
                    <div
                      className={cls(
                        item.memberId === mid ? "text-blue-500" : "text-[#333]",
                        "font-semibold text-base"
                      )}
                    >
                      {item.name}
                    </div>

                    <div className="text-[#666] font-medium text-xs">
                      <p>{item.memberId || item.clinicMemberId}</p>
                      <p>{dayjs(item.regDate).format("YY.MM.DD HH:mm")}</p>
                    </div>
                  </div>
                  <div className="ellipsis text-[#666] font-medium text-[13px] mt-2">
                    {item.message}
                  </div>
                </div>
              );
            })}
            {qnaData && (
              <div className="chat-paging">
                <ReactPaginate
                  previousLabel="←"
                  previousLinkClassName="before"
                  nextLabel="→"
                  nextLinkClassName="next"
                  breakLabel=""
                  pageCount={qnaData[1]}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  pageLinkClassName="num"
                  activeClassName=""
                  activeLinkClassName="current"
                  onPageChange={onPageClick}
                  forcePage={page - 1}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="w-[849px] h-[600px] py-6 relative pb-10 overflow-y-auto">
            <div className="px-4">
              {detailData && detailData[0] ? (
                <div>
                  {detailData && detailData[0].length > 0 ? (
                    <div>
                      {Object.keys(groupedMessages).map((date, i) => {
                        return (
                          <div key={i} id="scroll" ref={scrollRef}>
                            <div className="relative">
                              <div className="bg-[#CFCFCF] w-full h-[1px] mb-10" />
                              <div className="w-48 h-10 absolute -top-2 right-80 bg-white text-center font-medium text-[#6C6C6C] text-base">
                                {date}
                              </div>
                            </div>

                            {groupedMessages[date]?.map((item) => {
                              if (item.fromAdmin) {
                                return (
                                  <div key={item.id}>
                                    <SendChat
                                      message={item}
                                      reload={reloadMessage}
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={item.id}>
                                    <RecieveChat message={item} />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="text-center mt-60 text-main">
                      채팅 내역이 없습니다. 채팅을 시작해 보세요.
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center mt-60">
                  채팅 리스트를 선택해주세요.
                </div>
              )}
            </div>
          </div>
          <div>
            <div
              className="border-[1px] border-[#E7E7E7] w-full h-[97px] bg-white top-[542px] p-7"
              onSubmit={submitMessage}
            >
              <div className="flex items-center justify-between">
                <textarea
                  className="h-10 text-input"
                  placeholder="메세지를 입력하세요."
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ width: "690px" }}
                  value={message}
                  onKeyDown={(e) => {
                    const isEnter = e.key === "Enter";
                    if (isEnter && !e.shiftKey) {
                      e.preventDefault();
                      submitMessage();
                    }
                  }}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    submitMessage();
                  }}
                >
                  전송
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QnaPage;
