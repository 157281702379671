import { Column, Entity, Index, JoinColumn, ManyToOne } from "typeorm";
import { ClinicMember } from "./ClinicMember";
import { Policy } from "./Policy";

@Index("fk_clinic_member_policy_agree_member_id", ["memberId"], {})
@Index("fk_clinic_member_policy_agree_policy_id", ["policyId"], {})
@Entity("clinic_member_policy_agree", { schema: "dentin" })
export class ClinicMemberPolicyAgree {
  @Column("varchar", {
    primary: true,
    name: "member_id",
    comment: "병원 회원 ID",
    length: 32,
  })
  memberId: string;

  @Column("bigint", {
    primary: true,
    name: "policy_id",
    comment: "정책 정보 고유 ID",
  })
  policyId: string;

  @Column("bit", { name: "agree", comment: "동의 여부" })
  agree: boolean;

  @ManyToOne(
    () => ClinicMember,
    (clinicMember) => clinicMember.clinicMemberPolicyAgrees,
    { onDelete: "RESTRICT", onUpdate: "RESTRICT" }
  )
  @JoinColumn([{ name: "member_id", referencedColumnName: "memberId" }])
  member: ClinicMember;

  @ManyToOne(() => Policy, (policy) => policy.clinicMemberPolicyAgrees, {
    onDelete: "RESTRICT",
    onUpdate: "RESTRICT",
  })
  @JoinColumn([{ name: "policy_id", referencedColumnName: "policyId" }])
  policy: Policy;
}
